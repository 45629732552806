$susy: (
  "columns": susy-repeat(16),
  "gutters": 32px,
  "spread": "narrow",
  "container-spread": "narrow",
);

body {
  box-sizing: border-box;
  
  * {
    box-sizing: inherit;
  }
  &._has-modal {
    overflow: hidden;
  }
}

//
// 100% width page blocks
// _With_ outer gutter
//

%full-width-outer-gutter,
.page-header,
.footer__newsletter-constrainer,
.footer__social {
  width: span(16);
  max-width: 100%;
  padding: 0 $size-m;
  @include breakpoint($desktop) {
    padding: 0;
  }
}

//
// Full width content
//

.full-width {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
}

//
// 100% width page blocks
// With _no_ gutter
// 

.cms-page-view .page-main [data-content-type="row"][data-appearance="full-width"],
.page-wrapper,
.page-footer,
.footer.content,
.plp-header {
  width: 100%;
  max-width: 100%;
  padding: 0;
}

//
// Contrained page blocks
//

%constrainer,
.header.content,
.breadcrumbs,
.catalog-product-view .page-main,
.catalog-product-view .product-tabs__wrapper,
.catalog-product-view .product-tabs__wrapper--tab div[class*="section--"],
.customer-account-create .column.main,
.checkout-cart-index .column.main,
.logout-page-container {
  max-width: $innerMaxWidth;
  margin: 0 auto;
}

.footer-bottom-menu,
.copyright-container,
.footer__wrapper {
  max-width: $outerMaxWidth;
  margin: 0 auto;
}

.page-wrapper {
  padding-top: 120px; // height of sticky header

  @include breakpoint($desktop) {
    padding-top: 152px;
  }
}

// PDP specific
.catalog-product-view .page-wrapper {
  padding-top: 100px; // height of sticky header

  @include breakpoint($desktop) {
    padding-top: 152px;
  }
}

.ie11 {
  .pagebuilder-column-group,
  figure {
    min-height: 1px; // seems a bit hacky but its a known flexbug with images inside flex items on IE11
  }
}

/* Slick Slider */
.wrapper__carousel-image {
  .slick-track {
    display: flex;
    justify-content: space-between;
  }
  .slick-slide {
    margin-right: 1%;
  }
  .ct-view-hd_images_slider {
      max-width: 300px;
      @include breakpoint($tablet) {
        max-width: 400px;
      }
      @include breakpoint($desktop) {
        max-width: 500px;
      }
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
  }
}

// Background Section Colors
.background-grey {
  background-color: $grey-lighter;
}
.background-salmon {
  background-color: rgba(233,202,189,.39);
}