.training--wrapper {

  &__book {
    display: flex;
    flex-flow: column wrap;


    @include breakpoint($desktop) {
      flex-flow: row nowrap;
    }

    img {
      width: 100%;
      height: auto;

      @include breakpoint($desktop) {
        width: 50%;
      }
    }

    .booking--wrapper {
      background-color: $brand-secondary;
      padding: $size-2xl $size-m;
      width: 100%;

      @include breakpoint($desktop) {
        padding: 0;

        .booking--form {
          max-width: 80%;
          padding: 98px 0 0 91px;
        }
      }
    }

    h1 {
      color: $white;
      font-family: $fontHeading;
      text-transform: capitalize;
      @include fontSize(40px);
      letter-spacing: 0;
      line-height: $size-6xl;
      text-align: center;
      margin: 0 0 $size-m 0;
    }

    p {
      color: $white;
      font-family: $fontBody;
      @include fontSize($size-m);
      font-weight: 300;
      letter-spacing: 0;
      line-height: $size-xl;
      text-align: center;
      margin: 0;
    }

    .wdform-field {
      position: relative;
    }

    .wdform-element-section.course--options {
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 43px;
        right: 8px;
        background: url('../images/icons/icon-chevron-down-dark.svg') no-repeat;
        background-size: 30px 20px;
        width: 30px;
        height: 20px;
      }
    }

    select {
      border: 1px solid $border-lighter;
      border-radius: 2px;
      background-color: $white;
      width: 100%;
      padding: 11px $size-m;
      text-transform: uppercase;
      color: $brand-primary;
      margin: $size-2xl 0;
    }

    button {
      border-radius: 2px;
      background-color: $brand-senary;
      width: 100%;
      padding: 15px;
      text-align: center;
      color: $white;
      text-transform: uppercase;

      &:hover {
        outline: 0;
        border: 0;
        font-size: 14px;
        letter-spacing: 1px;
      }
    }
  }

  &__locations {

    @include breakpoint(max-width $desktop) {
      margin: 32px 16px 64px;
    }

    @include breakpoint($desktop) {
      max-width: 868px;
      margin: $size-7xl auto;
      text-align: center;
    }

    h1 {
      margin-bottom: 32px;
      color: $brand-primary;
      font-family: $fontHeading;
      text-transform: capitalize;

      @include breakpoint($desktop) {
        margin-bottom: 64px;
      }
    }

    .training--groups {
      background-color: $brand-vpale;
      padding: $size-xl;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @include breakpoint($desktop) {
        flex-wrap: nowrap;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        width: 50%;

        &:first-of-type {
          border-right: 1px solid $border-light;
        }

        &:nth-child(3) {

          @include breakpoint(max-width $desktop) {
            margin-top: 24px;
            width: 100%;
            padding-top: 24px;
            border-top: 1px solid $border-light;
          }

          @include breakpoint($desktop) {
            border-left: 1px solid $border-light;
          }
        }

        @include breakpoint($desktop) {
          width: 33%;
        }

        li {
          color: $brand-primary;
          @include fontSize(14px);
          font-weight: 500;
          letter-spacing: 1px;
          line-height: $size-l;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: $size-m;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

// Overrides for generic template styles
.page-id-10460 {
  .container {
    width: 100%!important;
    padding: 0;
    margin: -54px 0 0 0;

    @include breakpoint($desktop) {
      margin: -24px 0 0 0;
    }
  }

  .centred > h1 {
    display: none;
  }

  .fm-form-container {
    display: block!important;

    form {
      background: transparent;
      padding: 0;
    }

    .fm-hide,
    .wdform-label-section,
    .wd-hidden {
      display: none;
    }
  }
}


.readmore {
  display: block;
  display: -webkit-box;
  max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 auto;
  @include breakpoint($desktop) {
    -webkit-line-clamp: unset;
  }
}

.readmore.expanded {
  height: auto;
    -webkit-line-clamp: 9999;
}

.readmore-link.action {
  font-weight: 500 !important;
  text-transform: none;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-bottom: 0 !important;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  @include breakpoint($desktop) {
    display: none;
  }
}

#desc1.readmore.readmore-link {
  margin-bottom: 0;
}

section.row.template__header {
  text-align: left;

  &.bg-colour {
    padding: 6% 4% 6% 4%;
    @include breakpoint($tablet) {
      padding-top: 30px;
      padding-bottom: 36px;
    }
  }

  h1 {
    font-size: $size-2xl;
    font-weight: $fontBolder;
    @include breakpoint($desktop) {
      font-size: $size-4xl;
    }
  }

}

section.row.template__header.editor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include breakpoint($desktop) {
    flex-direction: row;
    padding: 8% 10% 0 10%;
  }


  .header__col-text-editor {
    flex-basis: 50%;
    padding: 4% 4% 0 4%;

    @include breakpoint($desktop) {
      padding-top: 0;
    }

    .readmore {
      @include breakpoint($tablet) {
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      &.expanded {
        @include breakpoint($tablet) {
          -webkit-line-clamp: 9999;
        }
      }
    }

    .readmore-link.action {
      @include breakpoint($tablet) {
        display: block;
        text-align: left;
      }
    }

  }

  h1 {
    font-size: $size-l;
    font-weight: $fontBoldest;
    @include breakpoint($desktop) {
      font-size: $size-4xl;
    }
  }

  h3 {
    font-size: $size-m;
    font-weight: $fontBolder;
    @include breakpoint($desktop) {
      font-size: 20px;
    }
  }

  p {
    line-height: 1.4;
  }

}

section.row.template__info.cta.two-col {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  margin: 0 auto -18px auto;

  .ct-view-profile_image_copy {
    flex-basis: 48%;
    text-align: left;

    @include breakpoint($desktop) {
      flex-basis: 31.333%;
      margin-right: 2%;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    img {
      margin-bottom: 15px;
    }

    h3 {
      font-size: $size-l;
      font-weight: $fontBolder;
      margin-bottom: 2px;
    }

    p {
      font-weight: $fontReg !important;
    }

    .cta__wrap-desc p:first-of-type {
      min-height: 80px;
      margin-bottom: 20px;
      @include breakpoint($tablet) {
        min-height: 60px;
      }
    }

    .btn.btn--primary {
      width: 100%;
      font-size: $size-m;
      font-weight: $fontBolder;
      text-decoration: none;
      padding: 13px 0;
      &:hover {
        color: $white !important;
      }
    }

  }

}

section.row.template__carousel.products {
  border-bottom: 0;
  padding: 0 4% 2% 4% !important;
  @include breakpoint($desktop) {
    padding: 0 10% 0 10% !important;
  }

  .page-title {
    display: none;
  }

  h2 {
    font-size: $size-2xl;
    font-weight: $fontBolder;
    margin-bottom: 0;
    @include breakpoint($desktop) {
      font-size: $size-4xl;
    }
  }

  .products-grid.grid {
    padding: 0;

    .product-items {
      margin-left: 0;
      @include breakpoint($desktop) {
        display: flex;
        justify-content: center;
      }

      &.slick-slider .slick-slide {
        vertical-align: top;
        margin-left: 3px;
        margin-right: 3px;
        @include breakpoint($desktop) {
          margin-left: 12px;
          margin-right: 12px;
        }
      }

      .product-item {
        max-width: unset;

        .product-item-name {
          min-height: 54px;
          margin-bottom: 4px;
          @include breakpoint($desktop) {
            min-height: 110px;
            line-height: 1.2;
          }
        }

        .product-item-description {

          p {
            min-height: 60px;
            font-weight: 300 !important;
            text-align: left;
            margin-bottom: 0;
            @include breakpoint($desktop) {
              min-height: 80px;
              line-height: 1.2;
            }
          }

          ul {
            display: none;
          }

        }

        .action.primary {
          width: 100%;
        }

        .price-box {
          @include breakpoint($desktop) {
            margin-bottom: 30px;
          }
        }

      }

    }

  }

  //Slick slider
  .slick-dots {
    display: flex !important;
    height: 5px;
    background-color: #f2f2f2;
    bottom: -10px;

    li {
      height: 5px;
      width: 50%;
      cursor: pointer;
      margin: 0;

      &.slick-active {
        background-color: #EACABF;
      }

      button:before {
        display: none;
      }

    }

  }

}

//Trainers pages styling
.trainers-header {
  padding-top: 20px;
  margin-bottom: 14px;

  .header__col-wrapper {
    @include breakpoint($desktop) {
      max-width: 48%;
    }
  }

}

.block.widget.ct-view-profile_image_copy_section {
  h2 {
    font-size: $size-2xl;
    font-weight: $fontBolder;
    text-align: center;
    @include breakpoint($desktop) {
      font-size: $size-4xl;
    }
  }
  &:last-of-type {
    margin-bottom: 38px;
  }
}

  //Product Slider Widget
  ol.product-items.widget-product-grid {

    .product-options {
      display: none;
    }

    .product-item .product-item-photo {
      margin-bottom: 30px;
    }

  }

.trainers-products {
  margin-bottom: 6%;

  //Product Slider Widget
  ol.product-items.widget-product-grid {

    .price-box,
    .price-box p.minimal-price {
      font-weight: 600 !important;
      line-height: 22px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

  }

}