.customer-account-login,
.customer-account-forgotpassword,
.customer-account-logoutsuccess {

  .page-main {
    @include breakpoint($desktop) {
      max-width: 568px;
      margin: 0 auto;
    }
  }

  .page-title {
    @include fancyHeading;
    font-weight: bold;
    margin: 0 0 $size-2xl;
    padding: 0 $size-m;
    text-align: center;
  }

  .block-title {
    margin-bottom: $size-m;
  }

  .main {

  .block-customer-login,
  .block-new-customer,
  .form.password.forget {
    padding: $size-xl $size-4xl $size-xl;
    background: $grey-lighter;
    margin-bottom: $size-2xl;

    .field.note {
      margin-bottom: $size-m;
    }
  }

  .form-login {
    padding: 0;
    background-color: inherit;
    .field.note {
      margin-bottom: $size-m;
    }
  }

  button,
  a.action.create {
    width: 100%;
    margin-top: 0;
    background: unset;

    &:hover {
      background: $black;
      color: $white;
    }
  }

  div.primary {
    margin-top: $size-m;
  }

  .secondary {
    margin-top: $size-2xl;
    display: flex;
    justify-content: center;
  }

  a.action.remind {
    background: $brand-primary;
    color: $black;
    font-size: $size-s;
    line-height: $size-l;
    letter-spacing: 1px;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: $fontReg;
  }

  .input-text {
    border: 0;

    &::placeholder {
      text-transform: unset;
      letter-spacing: unset;
    }
  }

  .options {
    text-align: right;

    a {
      color: $black;
    }
  }


    .discover-more {
      padding-bottom: $size-m;
      
      @include breakpoint($desktop) {
        background: $grey-lighter;
      }
      
      &__content {
        padding-left: $size-4xl;
        padding-right: $size-4xl;
      }
    }
    
    .btn {
      background: unset;
    }
  }
}

.customer-account-logoutsuccess {
  p {
    text-align: center;
  }
}

/*Adyen Checkout Flow*/
.nexus_precheckoutlogin-index-index{
  .page-main{
    margin-top: 50px;
    max-width: 1168px;
    margin: 0 auto;

    .page.messages {
      margin: 0px;
    }

    .block-customer-login,
    .block-customer-createaccount,
    .block-customer-guestcheckout{     
      margin: 0px 20px 24px 20px;
      .block-title{
        padding-top: 24px;
        margin-bottom: 18px;

        #block-customer-login-heading{
          font-size: 22px;
          font-family: "brandon-grotesque",sans-serif;
          font-weight: 600;
        }
      }      

      .block-content{
        max-width: 645px;
        
        form{                             
          border: 1px solid #EDEDED;

          .mage-error, #password-error{
            margin-bottom: 16px;
            color: red;
            font-size: 14px;
          }
          .password-box{
            position: relative;
          }
          .password-toggle-icon{
            max-width: 36px;
            position: absolute;
            right: 10px;
            top: 8px;
            cursor: pointer;
          }
          .password-toggle-icon.hidden{
            display: none;
          }
          .input-text{
            border: 1px solid #B9B8BA;
            height: 45px;
            margin-bottom: 5px;
            font-size: 18px;
          }
          .input-text.mage-error{
            margin-bottom: 5px;
          }         
          input::placeholder {
            font-size: 16px;
            color: #7D7C7E;
            font-weight: 300;
          }
          .field.note{
            margin-bottom: 10px;
          }         
          .remind-password{
            margin-top: 8px;
          }
          input[type='radio']{
            margin-bottom: 0px;
            width: 24px;
            height: 24px;            
            border: 2px solid #B9B8BA;
            vertical-align: middle;
          }
          input[type='radio']:checked {
            background: #E9CABD;
            border: 3px solid #fff;
          }

          .field-note{
            color:#141418;
            font-size: 16px;
            font-weight: 400;
          }

          .option-label{
            margin-bottom: 2px;
            cursor: pointer;

            label{
              cursor: pointer;
              margin-bottom: 2px;
            }
            
            .option-text{
              margin-left: 10px;
              font-family: "brandon-grotesque",sans-serif;
              font-size: 18px;              
              font-weight: 600;
              color: #000;
              vertical-align: middle;
            }
          }

          .option-info{            
            font-family: "brandon-grotesque",sans-serif;
            font-size: 16px;
            color: #141418;
          }         

          label span{
            text-transform: none;
            color: #000;
            font-size: 18px;
            font-weight: 600;
          }

          .actions-toolbar{
             .action.login.primary{
              width: 100%;
              background-color: #000;
              color: #fff;
              font-weight: 500;
             }
             .action.login.primary:hover{
              background-color: #fff;
              color: #000;
             }
          }

                    
          .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields {display: none;}

          .guestcheckout-form-fields{
            .input-text{
              margin-bottom: 10px;
            }
          }
          .createaccount-form-fields{
            .offers {
              margin-top: 24px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }           
          }          
        }

        .login-form-fields .field a {
          color: #141418;
          text-decoration: underline;
          font-weight: 400;
        }

      }     
           
    } 
    
    .block-customer-login.active,
    .block-customer-createaccount.active,
    .block-customer-guestcheckout.active{
      .block-content{        
        form{
          background: #E9CABD4D;      
          border: 1px solid #E9CABD;
           
          .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields {
            display: block;            
          }                    
        }            
      }
    }
  } 
}

@media only screen and (min-width: 320px) {
  .nexus_precheckoutlogin-index-index{
    .page-main{

      .block-customer-login,
      .block-customer-createaccount,
      .block-customer-guestcheckout {     
        .block-content{
          form{
            padding: 10px 5px 5px;

            .field.note{
              padding-left: 5px;
            }

            .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields{
              padding-left: 5px;
              padding-right: 5px;
              margin-top: 15px;
            }      

            .option-info{
              margin-left: 38px;             
              font-weight: 400;
            }         

            .createaccount-form-fields{              
              .offers-icon{
                max-width: 70px;
              }
            }        
            .fieldset.guestcheckout{
              .option-info.mobile{
                display: block;
              }
            }  
            .fieldset.guestcheckout{
              .option-info.desktop{
                display: none;
              }
            }  
          }
        }
      }

      .block-customer-login.active,
      .block-customer-createaccount.active,
      .block-customer-guestcheckout.active {
        .block-content{
          form{
            padding: 10px 5px 28px;

            .field.note{
              padding-left: 5px;
            }

            .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields{
              padding-left: 5px;
              padding-right: 5px;
              margin-top: 15px;
            }      

            .option-info{
              margin-left: 38px;             
              font-weight: 400;
            }      

            .fieldset.guestcheckout{
              .option-info.mobile{
                display: none;
              }
            }  
            .fieldset.guestcheckout{
              .option-info.desktop{
                display: block;
              }
            }  
          }
        }
      }

    }
  }
}

@media only screen and (min-width: 1024px) {
  .nexus_precheckoutlogin-index-index{
    .page-main{
      
      .block-customer-login,
      .block-customer-createaccount,
      .block-customer-guestcheckout {     
        .block-content{
          form{
            padding: 10px 15px 10px;
           
            .field.note{
              padding-left: 0px;
            }

            .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields{
              padding-left: 10px;
              padding-right: 5px;
              margin-top: 15px;
            }    

            .option-info{
              margin-left: 36px;          
              font-weight: 400;   
            }      

            .createaccount-form-fields{              
              .offers-icon{
                max-width: 100px;
              }
            }     
            .fieldset.guestcheckout{
              .option-info.mobile{
                display: none;
              }
            }  
            .fieldset.guestcheckout{
              .option-info.desktop{
                display: block;
              }
            }  
          }
        }
      }

      .block-customer-login.active,
      .block-customer-createaccount.active,
      .block-customer-guestcheckout.active {
        .block-content{
          form{
            padding: 15px 15px 28px;

            .field.note{
              padding-left: 0px;
            }

            .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields{
              padding-left: 12px;
              padding-right: 5px;
              margin-top: 15px;
            }    

            .option-info{
              margin-left: 36px;     
              font-weight: 400;        
            }      
          }
        }
      }

    }
  }
}

/*Adyen Checkout Flow*/

/* @media only screen and (min-width: 320px) {
  .active{
    .block-content{
      form{
        padding: 24px 24px 24px;
        
        .login-form-fields, .createaccount-form-fields,.guestcheckout-form-fields {              
          padding-left: 0px;
          padding-right: 0px;              
        }                    
      }            
    }
  }
} */