.catalog-category-view,
.search-index-index {
  .plp-header__block-container--text,
  .plp-header__block--categories,
  .columns {
    max-width: $outerMaxWidth;
    margin: 0 auto;
  }
  .loading-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    .loader {
      width: 100%;
      height: 100vh;
      
      img {
        top: 26px;
        right: 0;
        position: absolute;
      }
    }
  }

  .page-title {
    padding: 0 16px;
    @include breakpoint($desktop) {
      padding: 0;
    }
  }
  .breadcrumbs {
    max-width: 100%;
    .items {
      padding-top: 0;
    }
  }
  .toolbar-amount {
    text-align: right;
    padding-right: 16px;
    margin-top: 20px;
    @include breakpoint($desktop) {
      margin-top: -66px;
    }
  }
  .toolbar-products {
    display: none;
  }
}

.breadcrumbs .items .item::after {
  top: 2px;
}