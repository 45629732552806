//
// add mixins in here that can be used globally
//

// Convert the px values in the design to rems
@mixin fontSize ($pix) {
  $remVal: $pix / 16px;
  
  font-size: $pix;
  font-size: $remVal + rem;
}

// SVG Icon reset mixin
@mixin icon-svg-reset {
  content: "";
  width: $size-xl;
  height: $size-xl;
  display: inline-block;
  vertical-align: middle;
}

@mixin modalListItem {
  @include fontSize(16px);
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $brand-primary;
  padding: $size-l $size-2xl;
  display: block;
  font-weight: $fontBold;
  position: relative;
  box-shadow: inset 0 -1px 0 0 $grey-lighter;
}

@mixin mainHeading {
  color: $black;
  font-family: $fontBody;
  font-size: $size-xl;
  font-weight: $fontBold;
  letter-spacing: 2px;
  line-height: $size-2xl;
}

@mixin subHeading {
  color: $black;
  font-family: $fontBody;
  text-transform: uppercase;
  font-size: $size-s;
  font-weight: $fontBold;
  letter-spacing: 1px;
  line-height: 20px;
}

@mixin largeHeading {
  color: $black;
  font-family: $fontBody;
  font-size: $size-xl;
  font-weight: $fontReg;
  letter-spacing: 2px;
  line-height: $size-2xl;
}

@mixin fancyHeading {
  color: $black;
  font-family: $fontHeading;
  font-size: $size-2xl;
  line-height: $size-4xl;

  @include breakpoint($desktop) {
    font-size: $size-4xl;
    line-height: $size-6xl;
  }
}

@mixin qtyBox {
  height: 34px!important;
  width: 34px;
  min-width: 34px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid $black;
  border-radius: 0 2px 2px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0;

  .icon--minus {
    background: url("../images/icons/icon-minus.svg");
    background-size: 12px 12px;
    height: 12px;
    width: 12px;
  }

  .icon--plus {
    background: url("../images/icons/icon-plus.svg");
    background-size: 12px 12px;
    height: 12px;
    width: 12px;
  }
}

@mixin wishlist {
  padding: 0;
  position: absolute;
  top: $size-m;
  right: $size-m;
  height: $size-m;
  width: $size-l;
}

@mixin modalClose {
  margin-right: 0;
  cursor: pointer;

  &[type=button] {
    line-height: 0;
    padding: 0;
    text-transform: unset;
    border: 0;
    background: transparent;
    margin-top: 20px;
    margin-right: 4px;
    height: 14px;
    &:hover {
      background: none;
      color: unset;
    }
  }

  &::before {
    content: "x";
    line-height: 0;
    font-size: 30px;
    font-weight: $fontBold;
    padding: 0 11px;
  }

  span {
    @extend %visually-hidden;
  }
}