// Generic styles for modals.

//
// START
// Do not remove or override this block
// JS will not remove the _has-modal class from the body if removed.
// Modals will then fail after first use.
//
// .modal-slide,
// .modal-popup {
//   visibility: hidden;
//   opacity: 0;
//   //transition: visibility 0s 1.2s, opacity 1.2s ease;
// }
// .modal-slide._show,
// .modal-popup._show {
//   visibility: visible;
//   opacity: 1;
//   //transition: opacity 1.2s ease;
// }

$modal-header: $brand-primary !default;

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
  opacity: 1;
}
//
// END
//

.modal-slide,
.modal-popup {
  width: 100%; // no !important here
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  transform: translateX(-100%);
  transform: translate3d(-100%, 0, 0);
  transition: transform 600ms ease;
  z-index: 1010;
  min-height: 100vh;
  min-height: -webkit-fill-available; // Fixes height issue for modals on iPhone
  height: 100%;
  background: $white;
  outline: none;

  @include breakpoint(500px) {
    max-width: 480px; // so its never wider than the mobile design
  }
  &.right {
    left: unset;
    right: 0;
    transform: translateX(100%);
    transform: translate3d(100%, 0, 0);
  }
}

.modal-slide._show,
.modal-popup._show {
  width: 90% !important; // needs !important here to override the inline style that is there on page load
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
  overflow: auto;
  visibility: visible !important; // needs !important here to override the inline style that is there on page load
  opacity: 1 !important; // needs !important here to override the inline style that is there on page load
  @include breakpoint($tablet) {
    width: 100% !important;
  }
}

.modal-slide._noshow,
.modal-popup._noshow {
  width: 0; // no !important here
  visibility: hidden;
  opacity: 0;
}

.modals-overlay {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-header {
  background: $modal-header;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  height: $size-7xl;

  .modal-title {
    @include fontSize(18px);
    font-weight: $fontBolder;
    display: inline-block;
    line-height: $size-7xl;
    height: $size-7xl;
    color: $black;
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 16px;

    &::before {
      content: "";
      display: inline-block;
      vertical-align: text-bottom;
      width: $size-xl;
      height: $size-xl;
      margin-right: $size-2xs;
      margin-left: -2px;
    }
  }
  
  .action-close {
    @include modalClose;
    align-self: flex-start;
    padding-top: 11px;
  }
  
}

.modal-content {
  // padding-left: $size-m;
  // padding-right: $size-m;
  padding: 0;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0 $size-xl;
  
  .action.secondary {
    margin-left: $size-m;
  }
}

.modal-popup.confirm {
  transform: translateX(100%);
  transform: translate3d(100%, 0, 0);
  right: 0;
  left: unset;

  &._show {
    transform: translateX(0%);
    transform: translate3d(0%, 0, 0);
    @include breakpoint($desktop) {
      min-height: auto;
    }
  }
  
  &+ .modals-overlay {
    z-index: 1195 !important; // needs important to override inline style added by js. And needs to be slightly less than the modal, but still higher than the other elements
  }
  
  .modal-header {
    background: unset;
    
    .action-close {
      margin-left: auto;
      height: $size-m;
    }
  }

  .modal-content {
    padding: $size-m;
  }
  
  .modal-footer {
    justify-content: space-around;
    
    @include breakpoint($desktop) {
      padding: 0 $size-2xl;
    }
  }
  
  .action-primary {
    order: 1;
    margin-right: $size-m;
    flex: 1 1 50%;
    font-weight: bold;
    letter-spacing: unset;
  }
  
  .action-secondary {
    order: 2;
    flex: 1 1 50%;
    font-weight: bold;
    letter-spacing: unset;
  }

  .action-close {
    background: unset;

    &::before {
      content: "x";
      font-size: 30px;
    }
  }
}