.product-main__wrapper {
  @include breakpoint($pdp-desktop) {
    display: flex;
    justify-content: space-between;
  }

  // Next four blocks are workarounds related to Amasty labels
  // where the only way to select elements is via partial strings.
  // This is due to Amasty is only assigning specific classes with
  // product sku, such as '.amlabel-position-bottom-left-4731-prod'

  div[class*="amlabel-position-bottom-left"] {
    @include breakpoint($pdp-desktop) {
      transform: translateX(95px);
    }
  }

  div[class*="amlabel-position-bottom-right"] {
    transform: translateX(15px) translateY(30px);

    @include breakpoint($pdp-desktop) {
      transform: translateX(35px) translateY(0);
    }
  }

  div[class*="amlabel-position-top-left"] {
    @include breakpoint($pdp-desktop) {
      transform: translateX(95px) translateY(0);
    }
  }

  div[class*="amlabel-position-top-right"] {
    transform: translateX(15px) translateY(-50px);

    @include breakpoint($pdp-desktop) {
      transform: translateX(35px) translateY(5px);
    }
  }
}

.product-info__wrapper {

  @include breakpoint(max-width $pdp-desktop) {
    display: flex;
    flex-direction: column;

    .product--main,
    .ambanners {
      order: -2;
    }

    .ambanners {
      padding: 0 24px;
    }

    .sets--product {
      order: -1;
    }
  }

  @include breakpoint($pdp-desktop) {
    flex-basis: span(7);
    display: inline-block;
    vertical-align: top;
  }

  h3 {
    font-size: 20px;
    line-height: 48px;
    color: $black;
  }

  p {
    color: $black;
  }

  .product-info-main {
    position: relative;
    .prices-tier {
      display: none;
    }
  }

  .pro--wrapper {
    .book-training {

      .primary {
        width: 100%;

        @include breakpoint(max-width 1024px) {
          padding-top: 21px;
          padding-bottom: 21px;
        }

        a {
          color: $black;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .trustpilot-widget {
    @include breakpoint($pdp-desktop) {
      margin-top: $size-2xs;
    }
  }

  .product--info {
    padding: 24px 24px 0;
    margin-top: 27.5px;
    border-top: 1px solid #ededed;

    @include breakpoint($pdp-desktop) {
      padding: 0;
      margin-top: 32px;
      border-top: 0;
    }

    &.what-is-it {
      border-top: none;
    }

    &.extended {
      @include breakpoint($pdp-desktop) {
        margin-top: 48px;
        border-top: 0;
        padding-top: 0;
      }
    }

    &__title {
      margin: 0 0 4px;
      font-size: 18px;
      line-height: 22px;
      color: $black;
      width: 100%;

      @include breakpoint($pdp-desktop) {
        font-size: 20px;
        margin: 0;
      }
    }

    p {
      color: $black;
      font-size: 16px;
      line-height: 22px;

      @include breakpoint($pdp-desktop) {
        font-size: 14px;
      }
    }
  }

  .facts--wrapper {
    margin-top: 20px;
    padding: 24px 24px 0;
    border-top: 1px solid #ededed;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include breakpoint($pdp-desktop) {
      margin-top: 48px;
      border-top: 0;
      padding: 0;
    }

    h3 {
      width: 100%;
      @include breakpoint(max-width $pdp-desktop) {
        margin-top: 0;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0;
      }

      @include breakpoint($pdp-desktop) {
        margin: 0 0 22px;
      }
    }

    &__entry {
      display: inline-block;
      width: auto;
      text-align: center;

      &:last-of-type,
      &:only-of-type {
        margin-right: 0;
      }

      img {
        width: 24px;
        height: 24px;

        @include breakpoint($pdp-desktop) {
          width: 30px;
          height: 30px;
        }
      }

      p {
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;

        @include breakpoint($pdp-desktop) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .sets--product {
    margin-top: 24px;
    padding: 24px 24px 0;

    @include breakpoint($pdp-desktop) {
      margin-top: 48px;
      padding: 0;
    }

    h3.title {
      @include breakpoint(max-width $pdp-desktop) {
        margin-top: 0;
        margin-bottom: 8px;
      }
    }

    &__block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 16px;

      &:last-of-type,
      &:only-of-type {
        margin-bottom: 0;
      }

      img {
        width: 42%;

        @include breakpoint($pdp-desktop) {
          width: 33%;
        }
      }

      .content {
        width: 66%;
        display: inline-block;
        vertical-align: top;
        padding: 0 16px;

        @include breakpoint($pdp-desktop) {
          padding: 0 24px;
        }
      }

      form {
        padding: 0;
      }

      h2 {
        margin: 0 0 6px;
        color: $black;
        font-size: 18px;

        @include breakpoint($pdp-desktop) {
          font-size: 22px;
        }
      }

      p {
        margin: 0;
        color: $black;
        font-size: 14px;

        @include breakpoint($pdp-desktop) {
          font-size: 16px;
        }
      }

      .price {
        margin-top: 6px;
        &-box {
          font-size: inherit;
        }
        &.special {
          color: #CB0000;
        }
      }

      .price-box,
      .special-bundle {
        flex-direction: column;
      }

      .special-bundle {
        .price-from {
          display: flex;
          flex-direction: column;
          .price-final_price {
            color: #CB0000;
          }
        }
        .price-to {
          display: none;
        }
      }

      .box-tocart {
        margin-top: 8px;

        @include breakpoint($pdp-desktop) {
          margin-top: 24px;
        }

        button {
          width: 100%;
          font-weight: 700;

          @include breakpoint($pdp-desktop) {
            max-width: 216px;
          }
        }
      }
    }
  }
}

.product--highlight {
  margin-top: 32px;
  background: $highlightMsg;
  padding: 12px;

  @include breakpoint(max-width $pdp-desktop) {
    margin: 24px 16px 0;
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 4px;
  }

  &__content {
    font-size: 12px;

    @include breakpoint($pdp-desktop) {
      font-size: 14px;
    }
  }

  p {
    color: $black;
  }
}

.product-modal__message {
  display: none;
}

.product-options-wrapper .attendee-permanent-notice {
  width: 100%;
}

.product.attribute.overview p {
  @include fontSize(16px);
  color: $black;
  line-height: 22px;
  padding: 0 $size-xl $size-xs;


  @include breakpoint($pdp-desktop) {
    padding: 0;
  }
}

.product-info-main {

  &-container {
    position: relative;
    .trustpilot-widget {
      position: absolute !important;
      top: 30px;
      left: 17px;
      width: 176px;
      z-index: 5;

      @include breakpoint($pdp-desktop) {
        left: -8px;
      }
      .page-product-bundle & {
        top: -150px;
        left: 10px;
        @include breakpoint($desktop) {
          left: -15px;
        }
      }
      .page-product-configurable & {
        top: 26px;
        @include breakpoint($desktop) {
          top: 19px;
        }
      }
    }
  }

  .product-info-price {
    margin-bottom: $size-2xl;
    color: $brand-primary;
    letter-spacing: 1px;
    font-size: $size-m;
    line-height: 20px;
    display: flex;
    justify-content: space-between;

    .price-container {
      display: flex;

      .price-label {
        order: 0;
        padding-right: $size-2xs;
      }

      .price-including-tax {
        order: 2;
        padding-left: $size-2xs;

        //temp fix
        &::before {
          content: "(";
        }

        &::after {
          content: " " attr(data-label) ")";
        }
      }

      .price-wrapper {
        font-weight: $fontBoldest;
        font-size: $size-m;
        line-height: 20px;
        order: 1;
        color: $black;

        @include breakpoint($pdp-desktop) {
          font-size: 20px;
          letter-spacing: 0;
        }
      }

      .price-label,
      .price-including-tax {
        color: $brand-primary;
        font-weight: $fontReg;

        @include breakpoint($pdp-desktop) {
          font-size: $size-s;
          line-height: $size-2xl;
          letter-spacing: 1px;
        }
      }
    }

    .old-price,
    .special-price {
      color: $black;
      display: inline-block;
    }

    .special-price {
      .price-final_price {
        span {
          color: #CB0000;
        }
      }
    }

    .old-price .price-wrapper {
      color: $black;
    }
  }

  .items {
    padding: $size-m;
    margin: 0 $size-m $size-2xl;

    @include breakpoint($pdp-desktop) {
      margin: 0 0 $size-2xl;
    }

    h4 {
      @include subHeading;
      margin: 0 0 $size-2xs;
    }

    li {
      list-style: none;
      color: $brand-primary;
      font-size: $size-m;
      line-height: $size-xl;

      strong {
        font-weight: $fontBold;
      }
    }
  }

  div.fieldset {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:focus {
      outline: none;
    }
  }

  .field.configurable {
    position: relative;
    width: 47%;
    display: inline-block;

    @include breakpoint($pdp-desktop) {
      width: 48%;
    }

    &:nth-child(even) {
      margin-left: $size-m;
    }

    &:only-of-type {
      width: 100%;
      margin: 0;
    }

    div.mage-error {
      display: none !important;
    }

    .control {
      &::after {
        content: "";
        background: url("../images/icons/icon-chevron-down-dark.svg");
        position: absolute;
        right: $size-2xs;
        top: $size-2xs;
        height: $size-xl;
        width: $size-xl;
        transition: all .6s;
      }

      &.disabledSelect {
        &::after {
          background: url("../images/icons/icon-chevron-down-inactive.svg");
        }
      }
    }

    select {
      @include subHeading;
      font-weight: $fontReg;

      &[disabled] {
        background: $grey-lightest;
        border: 1px solid $grey-lighter;
        color: $grey-light;
      }

      &.mage-error {
        border: 2px solid $brand-primary;
      }

      &:focus {
        outline-color: $brand-quaternary;
      }
    }
  }

  .product-add-form {
    padding: 0;

    form,
    .form {
      background: transparent;
      padding: 0;

      div.form-input {
        text-transform: uppercase;
        outline: none;
        cursor: pointer;
        background: transparent;

        &.disabled {
          background: $grey-lightest;
          color: $grey-light;
        }
      }
    }

    .product-options-bottom {
      position: relative;
      margin-top: 16px;

      @include breakpoint($pdp-desktop) {
        margin-top: 28px;
      }
    }

    .field.qty {
      width: auto;
      position: absolute;
      right: 24px;
      top: 0;

      @include breakpoint($pdp-desktop) {
        right: 0;
        top: 48px;
      }

      .control {
        display: flex;
      }

      .input-text {
        width: 34px;
        border-radius: 0;
        border-left: 0;
        border-right: 0;
        text-align: center;
        margin-bottom: 0;
        height: 34px; // declare height explicitly for benefit of iOS devices
        padding: 0;
        font-weight: $fontBolder;
      }

      .qty-box {
        @include qtyBox;
        background: $white;
        border-radius: 0;
      }

      #qty-error {
        color: $error;
        position: absolute;
        bottom: -24px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 14px;
        line-height: 18px;

        @include breakpoint($pdp-desktop) {
          bottom: -42px;
        }
      }
    }

    .field.qty.position {
      position: absolute;
      right: 24px;
      top: 0;
      width: auto;

      @include breakpoint($pdp-desktop) {
        right: 0;
      }
    }

    .box-tocart {      
      @include breakpoint(max-width $pdp-desktop) {
        padding: 0 24px;
      }
    }

    .actions {
      width: 100%;

      @include breakpoint($pdp-desktop) {
        width: 100%;
      }

      .action {
        border-radius: 0;
        width: 100%;
        background: $black;
        color: $white;

        &:hover {
          background: $black;

          span {
            color: $white;
          }
        }

        @include breakpoint($pdp-desktop) {
          height: $size-6xl;
        }

        &.fixed-tocart {
          position: fixed;
          bottom: -75px;
          left: 0;
          z-index: 99;
          transition: bottom .6s ease-in-out;
          padding: 21px 16px;
          border-left: 0;
          border-right: 0;
          border-bottom: 0;

          &.visible {
            bottom: 0;
          }

          @include breakpoint($pdp-desktop) {
            display: none;
          }
        }

        &--isTrained {
          display: none;
        }

        &--notTrained {
          display: none;
          line-height: 18px;
          padding-top: 21px;
          padding-bottom: 21px;

          @include breakpoint($pdp-desktop) {
            display: none !important; // we dont want this button to show on desktop
          }
        }

        span {
          font-size: $size-l;
          font-weight: $fontBoldest;
          letter-spacing: 1px;
          color: $white;
        }
        &:disabled {
          background-color: $grey-lighter;
          cursor: unset;
          &:hover {
            background-color: $grey-lighter;
            span {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.product-info-main-container--pro {

  .product-info-price,
  .prices-tier {
    display: none;
  }

  .prices-tier {

    .price-including-tax {
      display: none;
    }
  }
}

.pro--container {
  background: #ededed;
  padding: $size-xl;
  margin: $size-2xl 0 0;
  display: none;
  flex-direction: column;

  @include breakpoint($pdp-desktop) {
    margin: $size-2xl 0;
    text-align: center;
  }

  h4 {
    @include subHeading;
    margin: 0 0 $size-2xs;
    font-size: $size-l;
    text-transform: capitalize;
    font-weight: $fontBolder;

    @include breakpoint($pdp-desktop) {
      text-align: center;
    }
  }

  > p {
    margin-bottom: 12px;
  }

  p {
    color: $black;
    font-size: $size-m;
    font-weight: $fontBook;
    line-height: 22px;
  }

  .actions .primary {
    width: 100%;
    border-radius: 2px;
    background: transparent;

    &:hover {
      background: $black;
      color: $white;
    }

    span {
      text-align: center;
    }
  }

  button.plp-header__sign-in {
    background: $white;
    color: $black;
    padding: 4px;
    font-size: 18px;
    font-weight: $fontBolder;
    transition: all .4s ease-in-out;
    position: relative;

    @include breakpoint($pdp-desktop) {
      background: transparent;
    }

    &:hover {
      background: $black;

      span {
        color: $white;
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    &::before {
      display: none;
    }
  }

  .access--container {
    display: flex;
    flex-direction: column;
    margin-top: $size-xl;
    background: $white;
    padding: $size-m;

    @include breakpoint($pdp-desktop) {
      padding: $size-xl;
    }

    p {
      margin-bottom: $size-m;
    }

    a {
      text-align: center;
      color: $black;
      font-weight: $fontBolder;
      border-bottom: 3px solid $brand-primary;
      margin: 0 auto;
    }
  }
}

.catalog-product-view {
  .page-header {
    z-index: 997; // to beat Amasty product label values added dynamically via inline css

    &::after {

      @include breakpoint($pdp-desktop) {
        z-index: 997;
      }
    }
  }

  .my-account-link--loggedOut {
    @include breakpoint($pdp-desktop) {
      position: static;
    }
  }
}

.page-title-wrapper.product {
  margin-bottom: 8px;

  @include breakpoint($pdp-desktop) {
    padding: 0;
  }

  h1 {
    @include fontSize(30px);
    color: $black;
    line-height: $size-3xl;
    letter-spacing: 0;
    font-weight: $fontBoldest;
    margin-top: $size-m;
    margin-bottom: 0;

    @include breakpoint($pdp-desktop) {
      margin-top: 0;
      font-size: $size-4xl;
      line-height: $size-6xl;
    }
  }
}

.product.overview {
  color: $black;
  font-size: 16px;
}

.available--dates {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -9;
  margin: 0;
  border: 1px solid $black;
  max-height: 308px;
  overflow-y: scroll;
  overflow-x: hidden;
  opacity: 0;
  transition: opacity .4s ease;

  &::-webkit-scrollbar {
    width: 22px;
    background-color: $grey-lightest;
    border-left: 1px solid $black;
  }

  &::-webkit-scrollbar-button {
    background-color: $grey-lightest;
    color: $brand-primary;
    border-left: 1px solid $black;
  }

  &::-webkit-scrollbar-track {
    background-color: $grey-lightest;
    border-left: 1px solid $black;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $brand-primary;
    border-left: 1px solid $black;
  }

  &.active {
    opacity: 1;
    z-index: 9;
  }

  p,a {
    margin: 0;
    padding: 0;
    color: $black;
    font-size: 14px;
    letter-spacing: .69pt;
    width: auto;
    display: inline-block;
    font-weight: 400;

    &.spaces {
      color: #CB0000;
    }
  }

  &__heading {
    background: $brand-primary;
    padding: 10px 16px;
    box-sizing: border-box;

    p {
      font-weight: $fontBolder;
    }
  }

  &__entry {
    margin: 0;
    background: #fff;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    transition: background .4s ease;
    cursor: pointer;

    &.selected, &:hover {
      background: $brand-background;

      p:not(.spaces), a {
        color: $black;
      }
    }
  }
}

.course_inquiry_form .actions-toolbar .action.primary{
  background: $black;
  color: $white;;
}

// Specific product type styles

.page-product-grouped {
  .product-info-main .product-add-form .field.qty {
    position: static;
  }
}

.page-product-configurable {
  .product-info-main .product-add-form .field.qty {
    top: auto;
    bottom: 122px;
    right: 35px;

    @include breakpoint($pdp-desktop) {
      bottom: 137px;
    }

    @include breakpoint($desktop) {
      top: auto;
      bottom: 128px;
      right: 16px;
      left: auto;
    }
  }
}

.catalog-product-view:not(.page-product-configurable):not(.page-product-bundle):not(.product-book-training-online) {
  .product-info-main .product-info-price {
    margin-bottom: 38px;

    @include breakpoint($pdp-desktop) {
      margin-bottom: 64px;
    }
  }
}

.product-book-training-online  {
  .product.overview {
    @include breakpoint($pdp-desktop) {
      margin-bottom: 16px;
    }
  }
}

.modal-popup.booking-training-popup{
  overflow-x: hidden;
}
