.footer {

  &__newsletter {
    flex-basis: span(6);
    text-align: center;
    margin-top: $size-m;
    margin-bottom: $size-m;
    
    @include breakpoint($desktop) {
      margin-right: $size-xl;
      text-align: left;
    }

    h2 {
      margin: 0 0 $size-s 0;
    }
    
    form {
      background-color: transparent;
      padding: 0;
      display: flex;
      margin-bottom: $size-xl;

      .field {
        flex: 1;
      }

      input[type=email] {
        border-radius: 0;
        border: 0;
        margin-bottom: 0;
        height: 40px;
        &::placeholder {
          text-transform: unset;
          color: $grey-light;
        }
      }

      button {
        border: 0;
        background: $brand-primary;
        border-radius: 0;
        height: 100%;
        font-weight: bold;
      }
    }
  }

  &__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__tel {
    font-weight: bold;
    a {
      color: #fff;
    }
  }

  &__socials {
    img {
      width: 24px;
      margin-left: $size-s;
    }
  }

  &__newsletter-cta {
    @extend %tertiary-button;
    width: 182px; // from design
    margin: 0 auto;
    display: block;
  }
}

