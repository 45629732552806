body.show-nav {
  overflow: hidden;
}
.main-menu {
  z-index: 40; // to be higher than the pro plp sign in button that has to be higher than the amasty stuff
  background-color: $white;
  width: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  transform: translate3d(-100%, 0, 0);
  transition: 600ms transform ease 200ms;
  max-width: 85%;
  height: 100%;

  @include breakpoint($desktop) {
    max-width: 320px;
  }

  @at-root .show-nav & {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1100; // to trump amasty label at 995 and sidebar at 1000
    transition: 600ms transform ease 0s;
  }

  &__underlay {
    display: none;
    position: fixed;
    background: $black;
    opacity: 0.06;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
  }

  &.desktop-mainmenu-visible {
    height: $size-8xl;
    overflow: visible;
  }

  &.desktop-mainmenu-hidden {
    height: 0 !important; // need to override inline style added by JS... which is also needed for cross breakpoint width changes
    overflow: hidden;
  }

  &__topbar {
    padding: 21px;
    text-align: right;
    box-shadow: inset 0 -1px 0 0 $grey-lighter;
    .close-menu::before {
      width: 14px;
      height: 14px;
    }
    .main-menu__inner-list & {
      position: absolute;
      top: 0;
      right: 0;
      padding: 21px;
    }
  }

  &__list {
    padding: 0;
    list-style: none outside;
  }

  .menu-cms-block {
    padding: 0 30px;
  }

  .consumer-link {
    display: block;
    color: $black;
    padding-left: 32px;
    text-decoration: underline;
  }

  li {

    padding-left: $size-2xs;
    box-shadow: inset 0 -1px 0 0 $grey-lighter;
    flex-basis: 100%;

    &.account-login {
      background: $grey-lighter;
      display: flex;

      & img#login-icon {
        width: 11%;
        height: 10%;
        margin-top: 3px;
      }

      & a#account-link {
        width: 89%;
      }
    }


    a {
      @include fontSize(18px);
      display: flex;
      align-items: center;
      line-height: 20px;
      color: $black;
      padding: 13px $size-2xl 13px $size-xl;
      font-weight: $fontBolder;
      position: relative;
      &:hover {
        color: $black;
      }
      .menu-item__icon {
        width: 26px;
        margin-right: 10px;
        margin-top: -7px;
        margin-bottom: -7px;
      }

    }

    .main-menu__icon {
      display: block;
      position: absolute;
      right: 20px;
      top: 7px;
      height: $size-2xl;
      width: $size-2xl;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 20px;
    }

    .main-menu__icon--back {
      display: block;
      position: absolute;
      left: $size-l;
      top: 15px;
      height: $size-2xl;
      width: $size-2xl;
      background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
      background-size: 20px;
      transform: rotate(180deg);
    }

    &.main-menu__inner-item {

      a {
        @include fontSize(16px);
        font-weight: 400;
      }
      &--level1 {
        span {
          padding-left: 40px;
        }
      }
      &--level2 {
        .menu-item__icon {
          width: 64px;
          margin-right: 35px;
        }
        span {
          padding-left: 0;
        }
      }
      &.shop-all {
        box-shadow: none;
        a {
          font-weight: $fontBolder;
        }
      }
    }

    &.main-menu__inner-item--all {
      color: $black;

      a {
        @include fontSize(18px);
        color: $black;
        font-weight: $fontBolder;

        &:hover,
        &:visited,
        &:active,
        &:focus {
          color: $black;
        }
      }
    }

    &.main-menu__item {

      @at-root .show-nav & {

        .main-menu__inner-list--level1 {

          // @include breakpoint(max-width 1024px) {
            display: flex;
            flex-flow: row wrap;
            align-content: flex-start;
          // }
        }
      }
    }

    &.main-menu__item--pro {

      .main-menu__link {
        box-shadow: inset 0 -1px 0 0 $grey-lighter;
      }

      .main-menu__inner-item--all {
        color: $white;
        background-color: $brand-secondary;

        a {
          color: $white;

          &:hover,
          &:visited,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }
    }

    &.main-menu__item--shop {

      .main-menu__link {
        box-shadow: inset 0 -1px 0 0 $grey-lighter;
      }

      .main-menu__inner-item--all {
        color: $white;
        background-color: $brand-quaternary;

        a {
          color: $white;

          &:hover,
          &:visited,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }

      .main-menu__inner-item--cms_block {

        div {
          background: rgba(111, 75, 153, .8);

          &:hover {
            cursor: pointer;
          }
        }
      }
    }

    &.main-menu__item--treatments {

      .main-menu__inner-item--all {
        background-color: $brand-quaternary;
      }
    }

    &.main-menu__item--training {

      .main-menu__inner-item--all {
        // color: $white;
        background-color: $brand-secondary;
      }
    }

    &.main-menu__item--wishlist {
      background-color: $white;

      .main-menu__link::after {
        @include icon-svg-reset;
        background: url(../images/icons/icon-heart-outlined-purple.svg) 50% 50% no-repeat;
        background-size: 16px;
        position: absolute;
        top: $size-xs;
        right: 20px;
      }
    }

    &.country-swapper-menu {
      background-color: $white;

      .main-menu__icon {
        background: url(../images/icons/icon-globe-purple.svg) 50% 50% no-repeat;
        background-size: 20px;
      }

      .main-menu__inner-item--all {
        color: $white;
        background-color: $brand-primary;

        a {
          color: $white;
          font-weight: $fontBold !important;

          &::before {
            content: "";
            display: inline-block;
            vertical-align: bottom;
            width: 20px;
            height: 20px;
            background: url(../images/icons/icon-globe.svg) 50% 50% no-repeat;
            background-size: 20px;
            margin-right: $size-3xs;
            margin-left: -2px;
          }

          &:hover,
          &:visited,
          &:active,
          &:focus {
            color: $white;
          }
        }
      }
    }
  }

  .main-menu__inner-link--back {
    padding-left: 52px;
    background-color: $white;
    box-shadow: inset 0 -1px 0 0 $grey-lighter;
    margin-left: -8px;
    width: calc(100% + 8px);
    font-weight: $fontBold !important;
    cursor: pointer;
    padding-top: 21px;
    padding-bottom: 21px;
    span.title {
      @include fontSize(18px);
      padding-left: 0;
      font-weight: $fontBolder;
    }
  }

  .inner-list-header {
    cursor: unset;
    font-weight: $fontBolder;
    span.title {
      padding-left: 0;
    }
  }

  &__item--parent,
  &__inner-item--parent {

    a {

      // @include breakpoint($desktop) {
      //   color: $brand-primary;

      //   &:hover,
      //   &:visited,
      //   &:active,
      //   &:focus {
      //     color: $brand-primary;
      //   }
      // }
    }

    .main-menu__inner-list {
      display: none;
      padding: 0;
      list-style: none outside;
      width: 100%;
      background-color: $white;

      // @include breakpoint(max-width 1024px) {
        position: absolute;
        top: 0;
        right: 0;
        // padding: 0;
        // list-style: none outside;
        // width: 100%;
        min-height: 100%;
        // background-color: $white;
        transform: translateX(100%);
        transform: translate3d(100%, 0, 0);
        transition: transform 600ms ease;
      // }

      @at-root .show-nav & {
        display: block;
      }
    }

    .main-menu__inner-item--cms_block {
      position: relative;
      padding-left: 0;
      margin: $size-2xl $size-m 60px $size-m;

      p,
      a {
        // for the p tag that the WYSIWYG wraps around img tags
        margin-bottom: 0 !important;
        line-height: 0;
        padding: 0;
        color: $white;
      }

      h3 {
        @include fontSize(16px);
        line-height: 20px;
        text-transform: uppercase;
        margin: 0;
        color: $white;
        font-weight: $fontBold;
        text-align: center;

        a {
          text-transform: uppercase !important;
          line-height: 20px;
          font-weight: $fontBold;
        }
      }

      a {
        color: $white;
        text-transform: none;

        &:hover,
        &:visited {
          color: $white;
        }
      }

      div {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $size-m;
        background: rgba(66, 43, 102, .8);
        width: 100%;

        &:hover {
          cursor: pointer;
        }

        p {
          display:  none;
        }

        a {
          color: $white;
          text-transform: none;

          &:hover,
          &:visited {
            color: $white;
          }
        }
      }
    }

    li.main-menu__inner-item--sign-in {
      background: $brand-tertiary;
      color: $white;
      display: none;
      order: 9;

      a {
        color: $white;
        font-weight: $fontBold;

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 20px;
          top: $size-xs;
          height: $size-2xl;
          width: $size-2xl;
          background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
          background-size: $size-xl;
        }

        &:hover,
        &:visited,
        &:active,
        &:focus {
          color: $white;
        }
      }
    }

    // gets added to mobile menu only
    &.show-nav {

      > .main-menu__inner-list {
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
        z-index: 100; // to make sure it is above the parent menu

      }
    }

    // gets added to desktop menu only
    &.item-hovered {

      .main-menu__inner-list {
        display: block;
      }

      .main-menu__inner-list--level1 {
        display: flex;
      }

      > a {
        box-shadow: inset 0 -2px 0 0 $white !important;
      }
    }
  }

  &__screen {
    display: none;
    position: absolute;
    top: 152px;
    left: 0;
    width: 100%;
    height: 600px;
    background-color: $white;
  }
}

.menu-underlay {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
}

.menu-usp {
  height: 0;
  visibility: hidden;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  transform: translate3d(0%, 100%, 0);
  transition: 100ms transform ease 0s, 500ms visibility ease 500ms;
  max-width: 85%;
  height: 100%;
  z-index: 9999; // to trump amasty label at 995 and sidebar at 1000
  margin-left: 0;
  margin-right: 0;
  width: 100%;

  @include breakpoint($desktop) {
    max-width: 320px;
  }

  @at-root .show-nav & {
    visibility: visible;
    height: 100%;
    transform: translateY(0);
    transform: translate3d(0, 0, 0);
    overflow-x: hidden;
    overflow-y: auto;
    transition: 600ms transform ease 1s;
  }
}
