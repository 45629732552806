.checkout-index-index{

  .page-wrapper{
    background-color: #FCFCFC;
  }

  @media only screen and (min-width: 320px) {
    .page-wrapper{
      padding-top: 90px;
    }
  }
  @media only screen and (min-width: 1024px) {
    .page-wrapper{
      padding-top: 110px;
    }
  }
  .page-header::after,
  .authentication-wrapper {
    display: none;
  }
  .hide {
    display: none;
  }

  .loading-mask{
    width: 100%;
    position: absolute;
    text-align: center;
    display: none;
    @include breakpoint($desktop) {
      top: 100px;
    }
  }
  .loading-mask.block{
    display: none;
  }
  .opc-block-summary{
    .loading-mask{
      width: 250px;
      margin: auto;
      top: auto;
      text-align: left;
      position: relative;
    }
  }

  @media only screen and (min-width: 320px) {
    .opc-block-summary{
      .loading-mask{
        padding-left: 30%;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .opc-block-summary{
      .loading-mask{
        padding-left: 15%;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .opc-block-summary{
      .loading-mask{
        padding-left: 30%;
      }
    }
  }

  .checkout-container #checkout-loader{
    position: relative;
  }

  .header.content {
    padding-bottom: 16px;

    @include breakpoint($desktop) {
      padding-bottom: 24px;
    }

    .header__logo-container {
      margin: 0 auto;
    }
  }


  .page-main {
    @include breakpoint($desktop) {
      padding: 0 $size-2xl;
    }

    .columns {
      @include breakpoint($desktop) {
        max-width: 1376px;
        margin: 0 auto;
      }

      .main {

        @media only screen and (min-width: 320px) {
          .checkout-container{
            padding: 0px 16px;
          }
          .grand-total-holder-mobile{
            display: none;
            padding-bottom: 35px;
            border-bottom: 1px solid #ededed;
            margin-bottom: 20px;

            .title{
              float: left;
              span{
                color: #000;
                font-size: 18px;
                font-weight: 600;
              }
            }
            .amount{
              float: right;
              font-size: 18px;
              font-weight: 600;
              color: #141418;
            }
            .amount:after {
              content: "inc. VAT";
              font-size: 14px;
              font-weight: normal;
              white-space: pre;
              text-transform: none;
              color: #5B5A5D;
              margin-left: 3px;
            }
          }
          .mobileVisibleDiv{
            display: block;
          }

          .checkout-content-wrapper {
            .checkout-sidebar {
              display: flex;
              flex-flow: column;

              .opc-sidebar{
                order: 2;

                .opc-block-summary{

                  .items-in-cart{
                    display: none;
                  }

                  .table-totals{
                    order: 1;
                  }

                  .actions-toolbar-trigger{
                    order: 2;
                    margin: 10px 0px;

                    button{
                      width: 100%;
                      font-size: 14px;
                      font-weight: 500;
                      background: #000;
                      color: #fff;
                    }
                  }

                }
              }

              .opc-block-shipping-information{
                margin-bottom: 0px;
                border-bottom: 1px solid #000;
                border-top: 1px solid #f2f2f2;
                padding: 20px 32px 20px;
              }
            }
          }

          ul.opc-progress-bar {
            width: 100%;
          }
        }

        @media only screen and (min-width: 768px) {
          .grand-total-holder-mobile{
            display: none;
          }
          .mobileVisibleDiv{
            display: none;
          }

          .checkout-content-wrapper {
            .checkout-sidebar {
              display: block;

              .opc-sidebar{
                .opc-block-summary{

                  .items-in-cart{
                    display: none;
                  }

                  .table-totals{
                    order: 1;
                  }

                  .actions-toolbar-trigger{
                    order: 2;
                    margin: 10px 0px;

                    button{
                      width: 100%;
                      font-size: 14px;
                      font-weight: 500;
                      background: #000;
                      color: #fff;
                    }
                  }

                }
              }

              .opc-block-shipping-information{
                margin-bottom: 30px;
                border-top: 1px solid #000;
                border-bottom: 1px solid #f2f2f2;
                padding: 10px 32px 20px;
              }
            }
          }
        }

        @include breakpoint($desktop) {
          display: flex;
          justify-content: space-between;

          .checkout-container{
            margin: auto;
            width: 80%;
            padding: 0px 24px;
          }
          .grand-total-holder-mobile{
            display: none;
          }
          .mobileVisibleDiv{
            display: none;
          }
          .checkout-content-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .checkout-main {
              width: 63%;
            }

            .checkout-sidebar {
              display: block;
              width: 33%;

              .opc-sidebar{
                background: #EDEDED;
                border: 1px solid #F2F2F2;

                .opc-block-summary{

                  .items-in-cart{
                    display: none;
                  }

                  .table-totals{
                    order: 1;
                  }

                  .actions-toolbar-trigger{
                    order: 2;
                    margin: 10px 0px;

                    button{
                      width: 100%;
                      font-size: 14px;
                      font-weight: 500;
                      background: #000;
                      color: #fff;
                    }
                  }

                }
              }

              .opc-block-shipping-information{
                margin-bottom: 30px;
                border-top: 1px solid #000;
                border-bottom: 1px solid #f2f2f2;
                padding: 10px 32px 20px;
              }
            }
          }
        }

        .checkout-shipping-address form input,
        .checkout-shipping-address form select,
        .checkout-billing-address .billing-address-form form input,
        .checkout-billing-address .billing-address-form form select {
          border: 1px solid #B9B8BA;
          height: 45px;
          font-size: 17px;
        }

        .checkout-shipping-address form input::placeholder,
        .checkout-billing-address .billing-address-form form input::placeholder
        {
          font-size: 16px;
          color: #7D7C7E;
        }
        .form.form-login{
          padding-bottom: 10px;

          .actions-toolbar{
            .action.login.primary, .action.remind{
              font-size: 14px;
              font-weight: 600;
              background: #000;
              color: #fff;
            }
            .action.login.primary:hover, .action.remind:hover{
              color: #000;
              background: #fff;
              border: 1px solid #000;
            }
          }
        }

        .form.form-login + #co-shipping-form{
          padding-top: 0px;
        }

        .checkout-content-wrapper {
          .checkout-sidebar {
            .opc-sidebar{
              padding: 20px 32px 5px;

              .opc-block-summary{

                .title{
                  color: #000;
                  font-size: 19px;
                  font-weight: 600;
                  padding-bottom: 14px;
                }

                .table-totals{
                  padding-top: 0px;

                  .totals.discount .title{
                    font-size: 16px;
                    font-weight: 400;
                    color: #141418;
                    padding-bottom: 0px;
                  }
                }

                .mark{
                  color: #141418;
                  font-size: 17px;
                  font-weight: 600;
                }

                .table-totals tr th, .table-totals tr td{
                  padding-bottom: 10px;
                }

                .actions-toolbar-trigger{
                  .button.action.continue.primary:hover{
                    background: #fff;
                    color: #000;
                  }
                }

              }
            }
          }
        }

        .checkout-sidebar {
          margin-bottom: 64px;
        }
      }
    }
  }

  .opc-sidebar {

    @include breakpoint($desktop) {
      max-width: 418px;
    }

    .discount-code {
      display: none;
    }
  }

  .opc-block-summary {
    margin-top: 0;
  }

  .opc-estimated-wrapper {
    display: none;
  }

  #checkoutSteps {
    list-style: none outside;
    padding: 0 0 20px;
    @include breakpoint($mobile) {
      padding: 0 0 15px;
    }
  }

  .field.required label::after,
  .field.required .label::after,
  .field._required label::after,
  .field._required .label::after {
    content: "";
  }

  .message.message-error.error{
    padding: 10px;
    margin-bottom: 10px;
    color: #e02b27;
    background: #E9CABD4D;
  }
}


// Sales Print Order

.sales-order-print {

  &.page-print {
    .order-buttons {
      display: none;
    }
  }

  .page-wrapper {
    padding: 32px 0;
  }

  .page-main {
    padding: 0 16px;

    @include breakpoint($desktop) {
      padding: 0 24px;
    }
  }

  .order-date {
    margin-top: 16px;
  }

  .header__logo-container {
    background: $brand-primary;
    padding: 19px;
    text-align: center;
  }

  .block.newsletter {
    display: none;
  }

}

.action.primary.login-check{
  width: 100%;
}
/* ---- opc progress bar --- */
ul.opc-progress-bar {
  display: flex;
  justify-content: space-between;
  width: 80%;
  padding-left: 0px;
  margin: 10px auto;
}
ul.opc-progress-bar .opc-progress-bar-item.noClick{
  pointer-events: none;
}
ul.opc-progress-bar .opc-progress-bar-item:first-of-type {
  text-align: center;
}
@media only screen and (min-width: 1024px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type {
    text-align: center;
  }
}
/* ul.opc-progress-bar .opc-progress-bar-item:first-of-type._active > span:before {
  margin-left: -18px;
} */
/* @media only screen and (min-width: 1024px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type._active > span:before {
    margin-left: -19px;
  }
} */
ul.opc-progress-bar .opc-progress-bar-item:first-of-type._active > span:after {
  background-color: transparent !important;
  /*margin-left: -11px;*/
}
@media only screen and (min-width: 1024px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type._active > span:after {
    margin-left: -4px;
  }
}
@media only screen and (min-width: 320px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type:before {
    width: 65%;
    left: 45%;
  }
}
@media only screen and (min-width: 1024px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type:before {
    width: 90%;
    left: 25% !important;
  }
  ul.opc-progress-bar .opc-progress-bar-item:last-of-type:before {
    content: none;
  }
}
ul.opc-progress-bar .opc-progress-bar-item:first-of-type span:before {
  margin-left: -18px;
}
ul.opc-progress-bar .opc-progress-bar-item:first-of-type span {
  text-align: left;
}
@media only screen and (min-width: 768px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type span:before {
    margin-left: 0px;
  }
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type span {
    padding-left: 35px;
  }
}
@media only screen and (min-width: 1024px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type span:before {
    margin-left: -10px;
  }
  ul.opc-progress-bar .opc-progress-bar-item._complete:first-of-type span:before {
    margin-left: -12px;
  }

  ul.opc-progress-bar .opc-progress-bar-item:first-of-type span {
    padding-left: 0px;
  }
}
ul.opc-progress-bar .opc-progress-bar-item:first-of-type span:after {
  width: 20px;
}
@media only screen and (min-width: 1024px) {
  ul.opc-progress-bar .opc-progress-bar-item:first-of-type span:after {
    width: 22px;
  }
}
ul.opc-progress-bar li.opc-progress-bar-item._complete:first-of-type span:after {
  width: 25px;
}
.opc-progress-bar {
  margin: 0 0 20px;
  counter-reset: i;
  font-size: 0;
}
.opc-progress-bar .opc-progress-bar-item {
  width: 100% !important;
}
.opc-progress-bar-item {
  margin: 0 0 10px;
  display: inline-block;
  position: relative;
  text-align: center;
  vertical-align: top;
  width: 50%;
}
.opc-progress-bar-item._active:before {
  background: #B9B8BA !important;
}
.opc-progress-bar-item:first-child:before {
  border-radius: 6px 0 0 6px;
}
.opc-progress-bar-item:before {
  background: #B9B8BA;
  top: 15px;
  content: '';
  height: 7px;
  left: 70%;
  position: absolute;
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item:before {
    width: 90%;
    top: 19px;
    left: 24%;
  }
}
.opc-progress-bar-item:before {
  height: 1px !important;
  background: #B9B8BA;
}
.opc-progress-bar-item._active > span {
  font-weight: 600;
  color: #000;
}
.opc-progress-bar-item > span {
  padding-top: 45px;
  font-size: 12px;
  text-transform: inherit !important;
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item > span {
    font-size: 14px;
  }
}
.opc-progress-bar-item > span {
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
  font-weight: 400;
  color: #5B5A5D;
}
.opc-progress-bar-item._active > span:before {
  background: #E9CABD !important;
  border: none !important;
}

.opc-progress-bar-item > span:before,
.opc-progress-bar-item > span:after {
  height: 34px;
  margin-left: -16px;
  width: 34px;
  border-radius: 50%;
  content: '';
  left: 60%;
  position: absolute;
  top: 0;
  cursor: pointer;
}
@media only screen and (min-width: 320px) {
  opc-progress-bar-item > span:before,
  .opc-progress-bar-item > span:after {
    margin-left: -13px;
  }
}

@media only screen and (min-width: 320px) {
  .opc-progress-bar .opc-progress-bar-item:nth-child(2)::before{
    width: 70%;
    left: 70%;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span:before {
    margin-left: -8px;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span:after {
    margin-left: -2px;
  }
 .opc-progress-bar .opc-progress-bar-item._complete:nth-child(2) > span:after {
    margin-left: -4px;
    top: 6px;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(3)::before{
    width: 60%;
    left: 93%;
  }
}

@media only screen and (min-width: 768px) {
  .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span {
    text-align: left;
    padding-left: 40px;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span:after {
    margin-left: -2px;
  }
  .opc-progress-bar .opc-progress-bar-item._complete:nth-child(2) > span:after {
    margin-left: -4px;
    top: 4px;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(3)::before{
    width: 75%;
    left: 93%;
  }
}

@media only screen and (min-width: 1024px) {
  .opc-progress-bar .opc-progress-bar-item:nth-child(2)::before{
    width: 95%;
    left: 50%;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span:after {
    margin-left: -5px;
  }
  .opc-progress-bar .opc-progress-bar-item._complete:nth-child(2) > span:after {
    margin-left: -5px;
  }
  .opc-progress-bar .opc-progress-bar-item:nth-child(3)::before{
    width: 95%;
    left: 80%;
  }
}

.opc-progress-bar-item:first-of-type > span:before{
  left: 8%;
}
@media only screen and (min-width: 320px) {
  .opc-progress-bar-item:first-of-type > span:before{
    left: 19%;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item:first-of-type > span:before{
    left: 8%;
  }
}

.opc-progress-bar-item:first-of-type > span:after{
  left: 10%;
}
@media only screen and (min-width: 320px) {
  .opc-progress-bar-item:first-of-type > span:after{
    left: 14%;
    margin-left: 0px;
  }
  li.opc-progress-bar-item._complete > span:after{
    left: 5.5%;
    text-align: center;
    margin-left: 0px;
    top: 5px;
  }
}
@media only screen and (min-width: 768px) {
  .opc-progress-bar-item:first-of-type > span:after{
    left: 26%;
    margin-left: 0px;
  }

  li.opc-progress-bar-item._complete > span:after{
    left: 22%;
    top: 4px;
  }
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item:first-of-type > span:after{
    left: 10%;
  }
  li.opc-progress-bar-item._complete > span:after{
    left: 5%;
    text-align: center;
    top: 4px;
  }
}

@media only screen and (min-width: 320px) {
  /* .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span{
    margin-left: 0%;
    text-align: left;
  } */
}

@media only screen and (min-width: 1024px) {
  .opc-progress-bar .opc-progress-bar-item:nth-child(2) > span{
    margin-left: 18%;
    text-align: left;
    padding-left: 0px;
  }
}
.opc-progress-bar .opc-progress-bar-item:nth-child(2) > span:before,
.opc-progress-bar .opc-progress-bar-item:nth-child(2) > span:after{
  left: 33%;
  text-align: center;
}
.opc-progress-bar .opc-progress-bar-item:nth-child(3) > span{
  margin-left: 16%;
}
.opc-progress-bar .opc-progress-bar-item:nth-child(3) > span:before,
.opc-progress-bar .opc-progress-bar-item:nth-child(3) > span:after{
  left: 66%;
}
.opc-progress-bar-item:last-of-type > span{text-align: right;}
.opc-progress-bar-item:last-of-type > span:after{text-align: center;}
@media only screen and (min-width: 320px) {
  .opc-progress-bar-item:last-of-type > span:before,
  .opc-progress-bar-item:last-of-type > span:after{
    left: 80%;
  }
}

@media only screen and (min-width: 786px) {
  .opc-progress-bar-item:last-of-type > span{
    text-align: right;
    padding-left: 100px;
  }
  .opc-progress-bar-item:last-of-type > span:before,
  .opc-progress-bar-item:last-of-type > span:after{
    left: 90%;
  }
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item > span:before,
  .opc-progress-bar-item > span:after {
    width: 30px;
    height: 30px;
  }

  .opc-progress-bar-item:last-of-type > span:before,
  .opc-progress-bar-item:last-of-type > span:after{
    left: 90%;
  }
}
.opc-progress-bar-item._active > span:after {
  content: counter(i) !important;
  counter-increment: i;
  background-color: #E9CABD !important;
  top: 8px;
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item._active > span:after {
    top: 6px;
  }
}
li.opc-progress-bar-item._complete > span:before {
  background-color: #E9CABD !important;
  border: none;
}
.opc-progress-bar-item > span:before {
  border: 1px solid #141418;
}
li.opc-progress-bar-item._complete > span:after {
  background-image: url(../images/tick-hd.svg) !important;
  content: " " !important;
  position: absolute;
  width: 27px;
  height: 18px;
  background-color: #E9CABD !important;
  background-size: 24px !important;
  background-repeat: no-repeat !important;
}
.opc-progress-bar-item > span:after {
  background: #fff;
  height: 22px;
  margin-left: -11px;
  top: 6px;
  width: 22px;
  content: counter(i);
  counter-increment: i;
  color: #141418;
  font-weight: 600;
  font-size: 15px;
}
@media only screen and (min-width: 320px) {
  .opc-progress-bar-item > span:after {
    margin-left: -10px;
    top: 8px;
  }
  .return-cart-holder {
    margin-bottom: 22px;
  }
}
@media only screen and (min-width: 1024px) {
  .opc-progress-bar-item > span:after {
    margin-left: -12px;
    top: 6px;
  }
  .return-cart-holder {
    margin-bottom: 10px;
  }
}

li#opc-shipping_method {
  display: none !important;
}
.return-cart-holder {
  text-align: right;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 550;

  .checkout-return-cart{
    border-bottom: 2px solid #E9CABD;
    color: #141418;
  }
}
/* ---- opc progress bar --- */

/* ---- Shipping Address --- */
.checkout-shipping-address .step-content #customer-email-fieldset .field-tooltip.toggle{
  display: none;
}
.checkout-shipping-address .step-content #customer-email-fieldset .note {
  display: none !important;
}

/* .checkout-shipping-address .step-content .form-shipping-address {
  padding-top: 0px;
} */

.checkout-shipping-address form{
  background: #E9CABD4D;
  padding: 24px 32px;

  .field label,.field .label{
    font-size: 18px;
    color:#141418;
    font-weight: normal;
  }

  .input-text.mage-error{
    margin-bottom: 2px;
  }

  #customer-email-error{
    margin-bottom: 16px;
    font-size: 14px;
  }
}

.checkout-shipping-address .new-address-btn{
  background: #E9CABD;
  font-size: 14px;
  letter-spacing: 0.84px;
  padding: 12px 35px;
  margin-left: 20px;
}

#co-shipping-form{
  padding-bottom: 24px;
}

@media (min-width:320px) {
  .action-show-new-address{
    width: 100%;
  }
  #customer-email-fieldset .actions-toolbar {
    flex-direction: column;

    .primary{
      margin: 10px 0px;

      .action.login.primary{
        width: 100%;
      }
    }
    .secondary {
      margin: 0px;

      .action.remind {
        width: 100%;
      }
    }
  }
}
@media (min-width:1024px) {
  .action-show-new-address{
    width: auto;
  }
  #customer-email-fieldset .actions-toolbar {
    display: flex;
    margin-top: 24px;
    justify-content: space-evenly;
    flex-direction: row;

    .primary {
      margin-top: 0px;
      width: 100%;
      margin-right: 5px;
    }
    .secondary {
      width: 100%;
      margin-left: 5px;
    }
  }
}

.shipping-address-items .shipping-address-item.selected-item {
  width: 100%;
}

.checkout-container.customerLoggedIn #shipping-new-address-form{
  display: none;
}
#opc-new-shipping-address-new #shipping-new-address-form {
  padding: 24px;
}
#shipping-new-address-form .street {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
#shipping-new-address-form input {
  width: 100%;
  margin-bottom: 0px;
}
#shipping-new-address-form .control {
  margin-bottom: 16px;
}
#shipping-new-address-form .field-error, .mage-error {
 color: #e02b27;
 font-size: 14px;
}
fieldset.field.street.admin__control-fields.required label.label {
  display: none;
}
#shipping-new-address-form .search_manually{
  margin-bottom: 20px;
  text-decoration: underline;
  font-size: 14px;
  margin-top: -30px;
  cursor: pointer;
}

.billing-address-form .search_manually_billing {
  margin-bottom: 20px;
  text-decoration: underline;
  font-size: 14px;
  margin-top: -15px;
  cursor: pointer;
}
#shipping-new-address-form .field-tooltip.toggle {
  display: none;
  padding: 24px 24px 0;
  border: 1px solid #B9B8BA;
}
#shipping-new-address-form .search_finder {
  margin-bottom: 5px;
  text-decoration: underline;
  font-size: 12px;
}
#shipping-new-address-form .fieldset > .field:not(.choice) > .label {
  font-size: 11px;
}

.shipping-address-item {
  border: 1px solid #EDEDED;
}
.shipping-address-item.selected-item {
  border: 1px solid #E9CABD;
  background-color: #E9CABD4D;
}
#shipping-new-address-form .field.choice {
  display: none !important;
}
.action-show-new-address{
  background-color: #fff;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #707070;
  color: #141418;
}

.action-show-new-address .field.additional {
  display: none !important;
}
#shipping-new-address-form .action-save-address{
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #000;
  color: #fff;
}
#shipping-new-address-form .action-save-address:hover{
  background-color: #fff;
  color: #000;
}

.opc-block-shipping-information {
  padding: 10px 32px 20px;
  display: none;
  background: #EDEDED;
  border: 1px solid #F2F2F2;
  border-top: 1px solid #000;
  margin-bottom: 30px;
}
.opc-block-shipping-information .shipping-information .ship-to{
  border-top: 1px solid #000;
  padding-top: 10px;
}
.opc-block-shipping-information .shipping-information .ship-via{
  padding-bottom: 10px;
}
.opc-block-shipping-information .shipping-information .shipping-information-heading{
  color:#141418;
  font-size: 17px;
  font-weight: 600;
  padding: 5px 0px;
  letter-spacing: 0.98px;
}
.opc-block-shipping-information .shipping-information .shipping-information-title{
  display: none;
}
.opc-block-shipping-information .shipping-information .shipping-information-content{
  color: #5B5A5D;
  font-size: 14px;
  letter-spacing: 1.08px;
  line-height: 1.3;
  font-weight: 400;
}
.shipping-address-item {
  margin: 0 0 20px;
  padding: 20px 35px 20px 20px;
  transition: 0.3s border-color;
  width: 100%;
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: top;
  word-wrap: break-word;
  font-weight: 400;
  color: #000;
}
.shipping-address-item a {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
.shipping-address-item.selected-item a {
  color: #000;
  font-size: 18px;
  font-weight: 400;
}
.shipping-address-item .shipping-address-phone-title {
  color: #000;
  font-weight: 400;
  font-size: 18px;
}
.shipping-address-item span {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}

.shipping-address-item hr {
  margin: 10px 0 10px 0 !important;
  height: 1px;
  background-color: #B9B8BA;
}

.shipping-address-item .block-title{
  font-size: 22px;
  word-wrap: break-word;
  font-weight: 300 !important;
  color: #000;
  margin-bottom: 8px;
}
.shipping-address-item .block-title strong{
  line-height: 30px;
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
strong#checkout-delivery-image {
  background-image: url('../images/home.png');
  content: "";
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-size: 30px 30px;
  display: inline-block;
}
.action-select-shipping-item{
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #707070;
  color: #141418;
  padding: 8px 16px;
}
.action-select-shipping-item span{
  font-size: 14px;
  font-weight: 600;
}
.action-select-shipping-item:hover, .action-select-shipping-item span:hover{
  color: #fff;
}
#opc-new-shipping-address-new{
  margin: 15px 0px;
}
#opc-new-shipping-address-new .form-shipping-address{
  padding: 0px;
}

#opc-new-shipping-address-new div[name="shippingAddress.street.1"],
#opc-new-shipping-address-new div[name="shippingAddress.street.2"],
#opc-new-shipping-address-new div[name="shippingAddress.country_id"],
#opc-new-shipping-address-new div[name="shippingAddress.region_id"],
#opc-new-shipping-address-new div[name="shippingAddress.region"],
#opc-new-shipping-address-new div[name="shippingAddress.city"],
#opc-new-shipping-address-new div[name="shippingAddress.postcode"]{
  display: none;
}

#shipping-new-address-form div[name="shippingAddress.street.1"],
#shipping-new-address-form div[name="shippingAddress.street.2"],
#shipping-new-address-form div[name="shippingAddress.country_id"],
#shipping-new-address-form div[name="shippingAddress.region_id"],
#shipping-new-address-form div[name="shippingAddress.region"],
#shipping-new-address-form div[name="shippingAddress.city"],
#shipping-new-address-form div[name="shippingAddress.postcode"]{
  display: none;
}
/* ---- Shipping Address --- */

/* ---- Shipping Methods --- */
div#checkout-step-shipping_method tr.row.row-error{
  display: none;
}

div#checkout-step-shipping_method tr.row input[type="radio"] {
  display: none;
}
div#checkout-step-shipping_method tr.row p {
  margin: 0 0 5px 0;
  color: #141418;
  font-size: 22px;
  font-weight: 400;
}

.checkout-shipping-method form{
  padding: 0px 24px 24px;
}

@media only screen and (min-width: 320px) {
  .checkout-shipping-method form{
    padding: 0px;
  }
  div#checkout-step-shipping_method tr.row p {
    font-size: 20px;
  }
  div#checkout-step-shipping_method tr.row{
    padding: 15px 15px 25px;
  }

}


@media only screen and (min-width: 320px) {
  .checkout-shipping-method form{
    padding: 0px;
  }
  div#checkout-step-shipping_method tr.row p {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1024px) {
  div#checkout-step-shipping_method tr.row p {
    font-size: 22px;
  }
}
.table-checkout-shipping-method tbody td:first-child {
  display: none;
}

 .table-checkout-shipping-method .col-method {
display: none;
}
.checkout-shipping-method .step-title {
display: none;
}

.table-checkout-shipping-method p  {
margin-top: 5px;
margin-bottom: 0px;
color: #141418;
}

.table-checkout-shipping-method tbody td {
padding: 5px !important;
}

.table-checkout-shipping-method .desc {
  width: 100% !important;
  font-size: 22px;
}

.table-checkout-shipping-method .desc {
  @include breakpoint($smallMobile) {
    font-size: 18px;
    line-height: 1.3 !important;
  }
  //tablet
  @include breakpoint($mobile) {
    font-size: 18px;
    line-height: 1.3 !important;
  }
}

.table-checkout-shipping-method .col-price {
  width: 10% !important;
  vertical-align: text-top;
}
.table-checkout-shipping-method .col-price .price{
  font-weight: 550;
  color: #141418
}
.table-checkout-shipping-method th.col.col-carrier {
  display: none;
}

.table-checkout-shipping-method th.col.col-price {
  display: none;
}
.table-checkout-shipping-method th.col.col-carrier {
  display: none;
}

.table-checkout-shipping-method th.col.col-price {
display: none;
}
div#checkout-step-shipping_method tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
}
div#checkout-step-shipping_method tr.row {
  border: 1px solid #EDEDED;
  margin-bottom: 15px;
  cursor: pointer;
  @include breakpoint($mobile) {
    padding: 15px 15px 25px;
  }
  @include breakpoint($desktop) {
    padding: 15px 15px 45px;
  }
}

div#checkout-step-shipping_method tr.row.selected_shipping {
  border: 1px solid #E9CABD;
}
.selected_shipping {
  border: 1px solid #E9CABD;
  background-color: #E9CABD4D;
}
.table-checkout-shipping-method tbody td {
  border-top: none !important;
}
.table-checkout-shipping-method {
  width: 100% !important;
}
.opc-block-shipping-information.show {
  display: block;
}
.opc-block-shipping-information.show.hidden{
  display: none;
}
#shipping-method-buttons-container {display: none;}

/* ---- Shipping Methods --- */

/*Payment Methods*/
#co-payment-form .fieldset {
  display: -webkit-flex;
  -webkit-flex-direction: column;
  display: flex;
  flex-direction: column;
}

.checkout-billing-address {
   flex:1;
   order: 1;
}

.opc-payment {
  flex:1;
  order: 2;
  }

.opc-payment-additional {
  flex:1;
  order: 3;
}

.checkout-billing-address{
  border: 1px solid #EDEDED;
}

div.klarna-payments-method:has(#klarna_pay_now) {
  display: none;
}
.braintree-applepay-minicart.minicart, .googlepay-minicart-logo.minicart {
  display: none;
}
.items.payment-methods .payment-method {
  display: flex;
  justify-content: space-between;
  border: 1px solid #EDEDED;
  flex-direction: column;
  min-height: 50px;
  padding: 15px 20px 0 20px;
  margin-bottom: 20px;
}
.items.payment-methods .payment-method._active {
  border: 1px solid #E9CABD;
  background-color: #E9CABD4D;
}
.checkout-billing-address{
  margin-bottom: 20px;
}
.checkout-billing-address .title {
  color: #000;
  font-size: 22px;
  font-weight: 500;;
}
.checkout-billing-address .billing-address-details {
  font-size: 14px;
  padding: 0px 5px;
  max-width: 350px;
  color: #5B5A5D;
  line-height: 1.3;
}
.checkout-billing-address .billing-address-details a{
 color: #5B5A5D;
}
.checkout-billing-address .actions-toolbar .primary{
  display: flex;

  .action.action-update{
    margin-right: 2px;
  }

  .action.action-cancel{
    margin-left: 2px;
  }

  .action.action-update, .action.action-cancel{
    width: 100%;
    margin-right: 2px;
    background: #000;
    color: #fff;
    border: 1px solid #000;
    font-weight: 400;
  }

  .action.action-update:hover, .action.action-cancel:hover{
    color: #000;
    background: #fff;
    border: 1px solid #000;
  }
}

.shipping-information-content a{
  color: #5B5A5D;
  font-size: 14px;
}

.checkout-payment-method .form.payments{
  padding: 0px;
}

.checkout-payment-method .payment-group .step-title{
  display: none;
}

.checkout-payment-method .payment-method-title {
  display: flex;
  align-items: center;
  padding: 0px 0px 10px 0px !important;
}
.checkout-payment-method .payment-method-title label {
  color: #5B5A5D;
  font-size: 18px;
  margin-bottom: 4px;
  text-transform: none;
  display: table;
  cursor: pointer;
}

.checkout-payment-method .payment-methods .payment-method._active:before {
  content: 'Payment Method \A';
  white-space: pre;
  color: #000;
  font-size: 21px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-left: 4px;
  letter-spacing: 0.96px;
}

.payment-method + #hpp_actionModalWrapper {
  border-bottom:0px;
}
.payment-method._active .payment-method-title label img{
  display: none;
}
@media only screen and (min-width: 320px) {
  .payment-method .payment-method-title label img{
    display: inline;
    vertical-align: middle;
  }
}
.payment-method .payment-method-title label img{
  max-width: 35px;
  height: auto;
}
.payment-method .payment-method-title label span{
  display: inline;
  vertical-align: middle;
  padding-left: 5px;
}
.payment-method._active .payment-method-title span{
  padding-bottom: 0px;
}
.checkout-payment-method .payment-method-title .action-help {
  display: none;
}

#payment-method-braintree-googlepay .label span,
#paypal_express .label span {
  margin-bottom: 0px;
  float: right;
  padding-top: 6px;
  margin-left: 4px;
}
.StepTitle {
  font-size: 22px;
  color: #000;
  font-weight: 400;
  margin-bottom: 10px;
}
.payment-method-content form{
  padding: 0px;
  background-color: transparent;
  margin-top: 10px;
}

.adyen-checkout-form-instruction{
  margin-bottom: 8px;
  color: #5B5A5D;
  font-size: 14px;
}
.adyen-checkout__card__brands{
  float: right;
}
.adyen-checkout__input{
  border-radius: 0px;
  border: 1px solid #B9B8BA;
  height: 45px;
  font-size: 17px;
}
.adyen-checkout__label{
  margin-bottom: 0px;
}
.adyen-checkout__label__text {
  color: #141418;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 550;
}
.adyen-checkout__label--focused .adyen-checkout__label__text {
  color: #E9CABD;
  font-size: 14px;
}
.adyen-checkout__field .adyen-checkout__label .adyen-checkout__label__text::after {
  content: '*';
  font-size: 12px;
}

.adyen-checkout__input--focus,
.adyen-checkout__input--focus:hover,
.adyen-checkout__input:active,
.adyen-checkout__input:active:hover,
.adyen-checkout__input:focus,
.adyen-checkout__input:focus:hover {
  border: 1px solid #E9CABD;
  box-shadow: 0 0 0 1px #E9CABD;
}

#co-transparent-form-braintree fieldset {
  background-color: #E6DDEF;
}
.payment-method-content, .StepTitle {
  display: none;
}
.checkout-payment-method .payment-method-content {
  padding: 15px 0 25px 0px !important;
  border-top: 1.5px solid #B9B8BA;
}
.payment-method._active .payment-method-content, .payment-method._active .StepTitle {
  display: block;
}
.payment-method-content .actions-toolbar .action.primary.checkout{
  width: 100%;
  background-color: #000;
  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
}
.payment-method-content .actions-toolbar .action.primary.checkout:hover{
  width: 100%;
  background-color: #fff;
  color: #000;
}
.payment-method-content .actions-toolbar .action.primary.checkout:disabled{
  background-color: #141418;
  background: #F2F1F6;
  color: #000;
  cursor: default;
}
.payment-method-content button.adyen-checkout__applepay__button {
  -webkit-appearance: -apple-pay-button;
}
.payment-method-content .message-error {
  color: #e02b27;
  margin-bottom: 10px;
}
.items.payment-methods input[type="radio"] {
   -webkit-appearance: none;
   appearance: none;
   background-color: #fff;
   margin: 0px 10px 00px 0px;
   font: inherit;
   color: currentColor;
   width: 22px;
   height: 22px;
   border: 1px solid #CCCCCC;
   border-radius: 50%;
   transform: translateY(-0.075em);
   display: grid;
   place-content: center;
   cursor: pointer;
}
.items.payment-methods input[type="radio"]:before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #141418;
}
.items.payment-methods input[type="radio"]:checked:before {
  transform: scale(1);
  background-color: #E9CABD;
}

/* .billing-address-same-as-shipping-block.field.choice {
  display: flex;
  margin: 15px 0px 5px;
}

.billing-address-same-as-shipping-block .div-same {
  display: flex;
  flex: 1 1 0px;
}
.billing-address-same-as-shipping-block .div-new {
  display: flex;
  flex: 1 1 0px;
} */

.checkout-billing-address .billing-address-same-as-shipping-block label {
  display: inline;
  color: #141418;
  font-size: 18px;
  font-weight: normal;
  text-transform: none;
  background: transparent;
  cursor: pointer;
}

.billing-address-same-as-shipping-block input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0px 10px 00px 0px;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
.billing-address-same-as-shipping-block input[type="checkbox"]:before {
 content: "";
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: scale(0);
 transition: 120ms transform ease-in-out;
 box-shadow: inset 1em 1em var(--form-control-color);
 background-color: #141418;
}
.billing-address-same-as-shipping-block input[type="checkbox"]:checked:before {
 transform: scale(1);
 background-color: #E9CABD;
}

.field-select-billing select {
  border: 1px solid #B9B8BA;
  height: 45px;
  font-size: 17px;
}

.choice.field{
  display: flex;
}
.choice.field label{
  display: inline;
  font-size: 18px;
  font-weight: normal;
  color: #5B5A5D;
}
#billing-save-in-address-book-shared{
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0px 10px 00px 0px;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px;
  border: 1px solid #CCCCCC;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}
#billing-save-in-address-book-shared:before {
 content: "";
 width: 12px;
 height: 12px;
 border-radius: 50%;
 transform: scale(0);
 transition: 120ms transform ease-in-out;
 box-shadow: inset 1em 1em var(--form-control-color);
 background-color: #E9CABD;
}
#billing-save-in-address-book-shared:checked:before {
 transform: scale(1);
}

.billing-address-form .field-error{
  color: #e02b27;
  font-size: 14px;
  margin-top: -10px;
  margin-bottom: 12px;
}

.billing-address-details .action-edit-address {
  padding: 0px;
  border: 0px;
  margin: 0px 10px;
  font-size: 14px;
  background: transparent;
  color: #141418;
  text-transform: none;
}
.billing-address-details .action-edit-address:hover {
  color: #141418;
}

.checkout-billing-address .billing-address-form .choice.field input[type="checkbox"]{
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0px 10px 00px 0px;
  font: inherit;
  color: currentColor;
  width: 22px;
  height: 22px !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;

}

.checkout-billing-address .billing-address-form .choice.field input[type="checkbox"]:before{
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #E9CABD;
  transform: scale(1);
 }

 #billing-save-in-address-book-shared:checked:before {
  transform: scale(1);
 }

.checkout-billing-address .billing-address-form .choice.field label{
  background-color: transparent;
}

.checkout-billing-address .checkout-billing-address-title {
  margin-bottom: 15px;
}
.checkout-billing-address .checkout-billing-address-title span{
  color: #141418;
  font-size: 21px;
  font-weight: 600;
}
.checkout-billing-address .field-select-billing label {
  display: none;
}

form#co-payment-form br, form#co-payment-form hr {
  display: none;
}
.checkout-payment-method .payment-method-content .fieldset > .field {
  margin-bottom: 0px;
}
.checkout-payment-method .payment-method-content .fieldset > .field label span {
  font-size: 16px;
  color: #141418;
  font-weight: normal;
  text-transform: capitalize;
  font-weight: 550;
}
form#co-payment-form fieldset.fieldset legend.legend {
  display: none;
}
.credit-card-types.braintree-credit-card-types{
  text-align: right;
  margin: 0px;
}
.braintree-credit-card-types .item {
  display: inline;
}

.braintree-card-control{
  position: relative;
  background-color: #fff;
}
.braintree-credit-card-selected{
  position: absolute;
  z-index: 10;
  right: 10px;
}
#braintree_cc_number{
  content: "";
  width: 100% !important;
  height: 45px;
  margin-top: 5px;
  margin-right: 0px;
  border: 1px solid #B9B8BA;
  padding: 0px 10px;
}
#braintree_expirationDate{
  height: 45px;
  margin-top: 5px;
  margin-right: 0px;
  border: 1px solid #B9B8BA;
  padding: 0px 10px;
  background-color: #fff;
}
#braintree-hosted-field-expirationDate{
  height: 45px;
  margin-right: 0px;
  border: 1px solid #B9B8BA;
  padding: 0px 10px;
}
#braintree_cc_type_cvv_div{
  position: relative;
}
#braintree_cc_cid{
  height: 45px;
  margin-top: 5px;
  margin-right: 0px;
  border: 1px solid #B9B8BA;
  padding: 0px 10px;
  width: 170px;
  background-color: #fff;
}

/*CC Tooltip*/
.field-tooltip {
  cursor: pointer;
  position: absolute;
  left: 140px;
  top: 40px;
}
.field-tooltip._active {
  z-index: 100;
}
.field-tooltip._active .field-tooltip-content {
  display: block;
}
.field-tooltip._active .field-tooltip-action:before {
  color: #333333;
}
.field-tooltip .label {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-tooltip .field-tooltip-action {
  display: inline-block;
  text-decoration: none;
}
.field-tooltip .field-tooltip-action > span {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.field-tooltip .field-tooltip-action:before {
  content: ' ';
  background: url("../images/cc.png") no-repeat center center/cover;
  width: 45px;
  height: 45px;
  position: absolute;
  top: -12px;
  left: -22px;
}
.field-tooltip .field-tooltip-action:hover:before {
  color: #333333;
}
.field-tooltip .field-tooltip-action:before {
  padding-left: 1px;
}
._keyfocus .field-tooltip .field-tooltip-action:focus {
  z-index: 100;
}
._keyfocus .field-tooltip .field-tooltip-action:focus + .field-tooltip-content {
  display: block;
}
._keyfocus .field-tooltip .field-tooltip-action:focus:before {
  color: #333333;
}
.field-tooltip .field-tooltip-content {
  background: #f4f4f4;
  border: 1px solid #999999;
  border-radius: 1px;
  font-size: 14px;
  padding: 12px;
  width: 270px;
  display: none;
  left: 38px;
  position: absolute;
  text-transform: none;
  top: -9px;
  word-wrap: break-word;
  z-index: 2;
}
.field-tooltip .field-tooltip-content:before,
.field-tooltip .field-tooltip-content:after {
  border: 10px solid transparent;
  height: 0;
  width: 0;
  border-right-color: #f4f4f4;
  left: -21px;
  top: 12px;
  content: '';
  display: block;
  position: absolute;
  z-index: 3;
}
.field-tooltip .field-tooltip-content:before {
  border-right-color: #666666;
}
.field-tooltip .field-tooltip-content:after {
  border-right-color: #f4f4f4;
  width: 1px;
  z-index: 4;
}
@media only screen and (max-width: 768px) {
  .field-tooltip .field-tooltip-content {
    left: auto;
    right: -10px;
    top: 40px;
  }
  .field-tooltip .field-tooltip-content::before,
  .field-tooltip .field-tooltip-content::after {
    border: 10px solid transparent;
    height: 0;
    left: auto;
    margin-top: -21px;
    right: 10px;
    top: 0;
    width: 0;
  }
  .field-tooltip .field-tooltip-content::before {
    border-bottom-color: #999999;
  }
  .field-tooltip .field-tooltip-content::after {
    border-bottom-color: #f4f4f4;
    top: 1px;
  }
}
.hosted-error span {
  height: 0px !important;
  display: inline-flex;
}
.payment-method-braintree .hosted-control.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) ~ .hosted-error {
  height: auto;
  margin-top: 7px;
  margin-bottom: 10px;
  opacity: 1;
}
.payment-method-braintree .hosted-error {
  clear: both;
  color: #e02b27;
  font-size: 14px;
  height: 0;
  opacity: 0;
  margin: 0px;
}

.opc-payment-additional.discount-code {
  display: none;
}
/*Payment Methods*/

.checkout-copyright {
  padding: $size-m;
  text-align: center;
  border-top: 1px solid $black;
}
