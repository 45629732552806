.page-product-grouped {
  .product-wrapper {
    .grouped {
      border-top: 1.5px solid #EDEDED;
      border-bottom: 1.5px solid #EDEDED;

      @include breakpoint($desktop) {
        border-top: 0;
        border-bottom: 0;
      }
    }

    .product.item {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1.5px solid #ededed;

      @include breakpoint($desktop) {
        &:first-of-type {
          padding: 0 16px 16px;
        }
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    .product-item-name {
      font-size: 18px;
      color: $black;
      font-weight: 600;
      display: flex;
      align-items: center;
      .swatch {
        width: 29px;
        height: 29px;
        min-width: 29px;
        border-radius: 50%;
        margin-right: 16px;
      }
    }

    .product-item-info {
      display: flex;
      align-items: center;
      z-index: 10!important;

      .field.qty {
        @include breakpoint(max-width $desktop) {
          position: static;
          right: auto;
          left: auto;
          top: auto;
        }
      }
    }

    .price-box {
      @include fontSize(16px);
      margin-right: 21px;
      flex-wrap: wrap;

      > span {
        width: 100%;
        text-align: right;
      }
    }

    .out-of-stock {
      padding: 0 10px;
    }

    p,
    span {
      color: $black;
    }

    .special-price {
      span {
        color: #CB0000;
      }
    }

    .old-price {
      margin: 0;
      span {
        font-size: 16px;
      }
    }
  }
}