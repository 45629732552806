body.account {

  /* ==========================================================================
   # Account Section - Layout
   ========================================================================== */
   .columns {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    color: $black;
    
    @include breakpoint($desktop) {
      flex-direction: row;
      max-width: $innerMaxWidth;
      margin: 0 auto;
    }
  }

  .column.main {
    flex: 1 1 auto;
    order: 2;

    .block,
    form[class^="form"] {
      background: $grey-lighter;
      margin-bottom: $size-l;
      padding: $size-m;

      @include breakpoint($desktop) {
        .action {
          margin-top: $size-m;
        }
      }
      > .action {
        width: 100%;
        background-color: transparent;
        @include breakpoint($desktop) {
          width: auto;
        }
      }
    }
    .action {
      &:hover {
        color: $white;
      }
    }
  
    a,
    a:active,
    a:focus,
    a:hover,
    a:visited {
      color: $black
    }
  }

  .sidebar.sidebar-main {
    flex: 1 1 auto;
    order: 1;
    @include breakpoint($desktop) {
      flex: 0 0 310px;
      margin-right: $size-xl;
    }
  }


  /* ==========================================================================
   # Account Section - General Styling
   ========================================================================== */
  .page-title {
    display: none;
    @include breakpoint($desktop) {
      display: block;
      font-family: $fontHeading;
      color: $black;
      max-width: $innerMaxWidth;
      margin: auto;
    }

    span {
      letter-spacing: 0;
    }
  }

  .page-main {
    padding: $size-m;

    @include breakpoint($desktop) {
      padding: 0 $size-m;
    }

    @include breakpoint($desktop-mid) {
      padding: 0;
    }
  }
  
  .customer-account-icon.sign-out-icon {
    margin-bottom: $size-xl;

    @include breakpoint($desktop) {
      margin-bottom: $size-7xl;
    }
  }

  .customer-account-icon.affiliate-account-program-icon {
    border-top: 1px solid $grey-lighter;
  }

  .message.info.empty {
    border: 1px solid $black;
    background-color: $white;
    color: $black;
  }

  hr {
    background-color: $black;
  }

  .customer-dob {
    .ui-datepicker-trigger {
      display: none;
    }
  }

  /* ==========================================================================
   # Account Section - Block/Box Styling
   ========================================================================== */
  .block-title {
    margin-bottom: $size-m;
    font-size: $size-xl;

    strong {
      font-weight: bold;
      text-transform: lowercase;
      display: inline-block;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  .box {
    padding: $size-m $size-m 0 $size-m;
    background: $white;
    padding-bottom: $size-m;
    position: relative;

    .box-title {
      display: block;
      margin-bottom: $size-m;
      font-size: $size-l;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }

    .box-actions {
      position: absolute;
      top: $size-m;
      right: $size-m;
    }

    a.edit,
    a.delete {
      color: $black;
      font-weight: bold;
      border-bottom: solid $black;
      text-transform: uppercase;
    }
  }

  /* ==========================================================================
   # Account Section - Form Styling
   ========================================================================== */
  form {
    .legend {
      font-weight: bold;
      font-size: $size-xl;
    }

    .label {
      font-size: $size-l;
    }
  }

  .table.my-account-box {
    width: 100%;

    th {
      text-align: left;
      font-weight: normal;
    }

    td {
      text-align: right;
    }
  }
  

  /* ==========================================================================
   # Account Section - Dropdown Content
   ========================================================================== */
  .dropdown {
    &-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $size-s;
      border: 1px solid $black;
      cursor: pointer;

      &::after {
        background-image: url("../images/icons/icon-chevron-down-dark.svg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        content: "";
        width: 24px;
        height: 24px;
        display: inline-block;
      }

      &.open::after {
        transform: rotate(180deg);
      }

      .legend {
        font-size: $size-l;
        font-weight: 500;
      }
    }

    &-content {
      display: none;
      padding: $size-s;
      border: 1px solid $black;
      border-top: unset;
      .checkbox {
        display: none;
      }
    }
  }

  /* ==========================================================================
   # Account Section - Pager
   ========================================================================== */
  .pager {
    display: flex;
    justify-content: space-between;

    .toolbar-amount {
      margin: 0;
    }

    .limiter {
      text-align: right;

      .limiter-options {
        margin: 0;
        text-align-last: right;

        option {
          direction: rtl;
        }
      }
    }
  }

  /* ==========================================================================
   # Account Section - Icons
   ========================================================================== */
  .account-nav-content {
    .customer-account-icon {
      font-weight: bold;
    }

    .customer-account-icon::before {
      content: "";
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: $size-m;
      margin-left: 0;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    .my-account-icon::before {
      background-image: url("../images/icons/my-account/icon-my-account.svg");
    }

    .my-orders-icon::before {
      background-image: url("../images/icons/my-account/icon-my-orders.svg");
    }

    .my-downloadables-icon::before {
      background-image: url("../images/icons/my-account/icon-my-downloads.svg");
    }

    .wishlist-icon::before {
      background-image: url("../images/icons/icon-wishlist-outline.svg");
    }

    .address-book-icon::before {
      background-image: url("../images/icons/my-account/icon-address-book.svg");
    }

    .account-info-icon::before {
      background-image: url("../images/icons/my-account/icon-account-info.svg");
    }

    .marketing-prefs-icon::before {
      background-image: url("../images/icons/my-account/icon-marketing-prefs.svg");
    }

    .marketing-prefs-icon::before {
      background-image: url("../images/icons/my-account/icon-marketing-prefs.svg");
    }

    .sign-out-icon::before {
      background-image: url("../images/icons/my-account/icon-sign-out.svg");
    }

    .affiliate-account-program-icon::before {
      background-image: url("../images/icons/my-account/icon-my-virtual-account.svg");
    }

    .affiliate-account-transaction-icon::before {
      background-image: url("../images/icons/my-account/icon-my-earnings.svg");
    }

    .affiliate-account-share-icon::before {
      background-image: url("../images/icons/my-account/icon-discount-code.svg");
    }

    .affiliate-account-traffic-icon::before {
      background-image: url("../images/icons/my-account/icon-traffic-sources.svg");
    }

    .affiliate-account-refer-icon::before {
      background-image: url("../images/icons/my-account/icon-refer-new-clients.svg");
    }

    .affiliate-account-withdrawal-icon::before {
      background-image: url("../images/icons/my-account/icon-my-withdrawls.svg");
    }

    .affiliate-account-setting-icon::before {
      background-image: url("../images/icons/my-account/icon-my-settings.svg");
    }


    .nav.item.current {
      strong {
        font-weight: bold;

        &::before {
          content: "";
          width: 24px;
          height: 24px;
          display: inline-block;
          margin-right: $size-m;
          margin-left: 0;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
      }
    }
  }

  &.customer-account-index {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-account.svg");
        }
      }
    }
  }

  &.sales-order-history {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-orders.svg");
        }
      }
    }
  }

  &.downloadable-customer-products {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-downloads.svg");
        }
      }
    }
  }

  &.wishlist-index-index {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/icon-wishlist-outline.svg");
        }
      }
    }
  }

  &.customer-address-form,
  &.customer-address-index {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-address-book.svg");
        }
      }
    }
  }

  &.newsletter-manage-index {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-marketing-prefs.svg");
        }
      }
    }
  }

  &.customer-account-edit {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-account-info.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-program {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-virtual-account.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-transaction {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-earnings.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-promo {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-discount-code.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-traffic {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-traffic-sources.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-refer {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-refer-new-clients.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-withdrawal {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-withdrawls.svg");
        }
      }
    }
  }

  &.amasty_affiliate-account-setting {
    .account-nav-content {
      .nav.item.current {
        strong::before {
          background-image: url("../images/icons/my-account/icon-my-settings.svg");
        }
      }
    }
  }

  /* ==========================================================================
   # My Orders Section
   ========================================================================== */

  &.sales-order-view {
    .column.main {
      position: relative;
      padding-top: $size-6xl;
    }

    .items.order-links {
      position: absolute;
      right: 0;
      list-style-type: none;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      padding: 11px 16px;
      border: 2px solid #000;
      display: inline-block;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      transition: all 0.4s ease-in-out;
      margin: 10px 10px 0 0;

      .actions {
        margin-top: $size-s;
        margin-right: $size-m;
      }

      .action.order {
        display: none;
      }

      .action.print {
        background: unset;

        &:hover {
          color: $black;
        }
      }
    }

    .return--button {
      position: absolute;
      top: 0;

      .back-link {
        border: unset;
        background: unset;
        text-transform: lowercase;
        font-size: 20px;

        &::before {
          content: "<";
        }

        &:hover {
          color: $black;
        }
      }
    }

    // TODO: Find a better way of handling icon issues
    .nav.item.current strong:before {
      margin-left: unset;
      margin-right: unset;
      width: unset;
      height: unset;
    }
  }

  .order-title {
    font-size: $size-xl;
    font-weight: bold;
    margin-top: $size-2xs;
    margin-bottom: $size-xl;
  }

  .order-info,
  .address-item {
    position: relative;
    background-color: $white;
    margin-bottom: $size-m;
    padding: $size-m;

    &__row {
      margin-bottom: $size-2xs;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .order-details {
    position: relative;
    margin-bottom: $size-s;

    .id {
      font-weight: bold;
      display: block;
      border-bottom: 2px solid $black;
      padding-bottom: $size-m;
      margin-bottom: $size-m;
    }

    .shipping,
    .total,
    .status,
    .date {
      margin-bottom: $size-xs;

      .order-label {
        font-weight: bold;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      .view {
        margin-bottom: 10px;
      }
    }

    @include breakpoint($desktop) {
      .actions {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  .order-details-items {
    margin-bottom: 0 !important; //! THIS NEEDS REWORKING
    padding-bottom: 0 !important; //! THIS NEEDS REWORKING

    .order-view {
      padding-bottom: 0;

      .table-order-items {
        padding-bottom: $size-s;
        border-bottom: 1px solid $grey-lighter;
      }
    }

  }

  &.sales-order-invoice {

    .column.main {
      position: relative;
      padding-top: 48px;

      .actions-toolbar {
        position: absolute;
        &.order-actions-toolbar {
          display: none;
        }
        &.invoice {
          top: 0;
          left: 0;
          .action.back {
            border: unset;
            background: unset;
            text-transform: lowercase;
            font-size: 20px;
            &:before {
              content: "<";
            }
          }
        }
      }

      .order-details-items.invoice {
        background: #EDEDED;
        margin-bottom: 18px;
        padding: 16px;
  
        .invoice-content-wrapper {
          background-color: #fff;
          padding: 16px;

          .order-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 35px;
          }
  
          .table-order-items {
            width: 100%;
  
            .table-caption {
              display: none;
            }
            thead th,
            tfoot th {
              text-align: left;
            }
            tfoot {
              .cell-spacer {
                display: block;
                padding-top: 5px;
              }
            }
  
          }
  
        }
  
      }

    }

  }

  .block-order-details-view {
    margin-top: 0 !important; //! THIS NEEDS REWORKING
    padding-top: 0 !important; //! THIS NEEDS REWORKING
  }

  .order-items {
    border-top: 2px solid $black;
    padding-top: $size-s;

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--toggle {
      height: $size-l;
      width: $size-l;
      background-image: url(../images/icons/icon-plus.svg);

      &.open {
        background-image: url(../images/icons/icon-minus.svg);
      }
    }

    &--content {
      display: none;
      margin-top: $size-xs;
    }

    &--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $size-xs 0;
      border-top: 1px solid $grey-lighter;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .order-view {
    background-color: $white;
    padding: $size-m;

    .order-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $size-l;
      font-weight: bold;
      border-bottom: 2px solid $black;
      padding-bottom: $size-s;
    }

    .order-content-view {
      margin-bottom: $size-s;
    }

    .order-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid $grey-lighter;
      padding: $size-xs 0;
      font-weight: bold;
    }
  }

  /* ==========================================================================
   # Address Book Section
   ========================================================================== */
  .block-dashboard-addresses,
  .block-addresses-default {
    .block-content {
      display: flex;
      flex-direction: column;

      @include breakpoint($desktop) {
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .box {
      flex: 1 1 auto;
      margin-bottom: $size-m;

      @include breakpoint($desktop) {
        margin-right: $size-m;
        margin-bottom: 0;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  .form-address-edit {
    .search-container {
      input {
        font-size: 16px;
        border: 1px solid #000;
        border-radius: 2px;
        margin-bottom: 16px;
        width: 100%;
        padding: 10px 16px;
        -webkit-appearance: none;
        background-color: #fff;
        color: #000;
        font-weight: 300;
        outline: none;
      }

      #cp_button_id {
        margin-top: 0;
      }
    }

    .address-details {
      @include breakpoint($desktop) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }

      #address-search {
        display: block;
      }

      .field {
        display: none;
        flex: 1 1 50%;

        &.street {
          flex: 1 1 100%;
        }

        @include breakpoint($desktop) {
          .street-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .initial {
              flex: 1 1 50%;
              padding-right: $size-2xs;
            }

            .nested {
              flex: 1 1 50%;
              padding-left: $size-2xs;
            }
          }

          &.city,
          &.zip {
            padding-right: $size-2xs;
          }

          &.region,
          &.country {
            padding-left: $size-2xs;
          }
        }
      }
    }

    #edit-address-manually {
      margin-top: $size-m;
      margin-bottom: $size-xl;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  /* ==========================================================================
   # Wishlist Section
   ========================================================================== */
   .column.main form.form-wishlist-items {
    background-color: unset;
    padding: unset;
  }

  .products-grid.wishlist {

    padding: 0;

    .product-items {
      display: grid;
      grid-template-columns: 1fr 1fr;

      @include breakpoint($desktop) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .product-item {
      margin-right: $size-m;
      width: 100%;
      padding: 0 0.25rem;

      @include breakpoint($desktop) {
        padding: 0 1rem;
      }
  }
    
    .comment-box {
      display: none;
    }

    .box-tocart {

      .qty {
        display: none;
      }
    }

    .product-item-actions {

      .edit {
        display: none;
      }

      .wishlist-remove {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        height: 16px;
        width: 18px;
        color: $white;
        background: url(../images/icons/icon-wishlist-fill.svg) 0 0/18px 18px no-repeat;

        span {
          display: none;
        }
      }
    }
  }

  .form-wishlist-items {
    .actions-toolbar {

      .primary,
      .secondary {
        display: none;
      }
    }
  }

  .wishlist-toolbar {

    .pager {

      .limiter {
        display: flex;
        align-items: center;

        .limiter-options {
          width: 3rem;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }

  /* ==========================================================================
   # Account Information Section
   ========================================================================== */
  .fieldset.email,
  .fieldset.password {
    background-color: $white;
    margin-bottom: $size-m;
  }

  /* ==========================================================================
   # Affiliates Section
   ========================================================================== */

  .affiliate-programs {
    margin-bottom: $size-xl;

    &__item {
      font-size: $size-l;
      margin-bottom: $size-m;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__row {
      margin-bottom: $size-2xs;

      &:last-of-type {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
      }
    }
  }

  .affiliate-balance-info {
    margin-bottom: $size-m;
    padding: $size-m;
    
    &--filled-bg {
      background-color: $white;
    }

    .affiliate-price-field {
      margin-bottom: $size-xs;

      &:last-of-type {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
      }
    }
  }

  .block .referral-link {
    margin-bottom: $size-m;

    .control {
      text-align: right;

      .affiliate_button {
        margin-top: 0;
        border: unset;
        background: unset;
        text-transform: unset;
        font-weight: unset;
        text-decoration: underline;
        
        &:hover {
          color: $black;
        }
      }
    }
  }

  .banner-info {
    font-size: $size-l;
  }

  .traffic-sources {
    font-size: $size-l;

    &__description {
      margin-bottom: $size-m;
    }

    &__item {
      margin-bottom: $size-m;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &__row {
      margin-bottom: $size-2xs;

      &:last-of-type {
        margin-bottom: 0;
      }

      strong {
        font-weight: bold;
      }
    }
  }

  .referrer-icons {
    text-align: center;
  }

  .withdrawal-form {
    padding: 0;
    
    .fieldset {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      
      .field {
        flex: 1 1 auto;
        @include breakpoint($desktop) {
          margin-right: $size-m;
        }
      }
    }

    .input-text {
      margin-bottom: 0;
    }

    .actions-toolbar {

      .primary {
        margin-top: 0;
      }

      .action.save {
        padding: 9px $size-m;
        background-color: unset;

        &:hover {
          background-color: $black;
        }
      }
    }
  }
  .magento-newsletter {
    display: none;
  }
}
