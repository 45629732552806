.plp-header {
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  padding: 0 16px;
  margin-bottom: 48px;
  @include breakpoint($desktop) {
    margin: 48px 0;
    padding: 0;
  }

  &__block {
    width: 100%;
    flex-basis: 100%;
    position: relative;

    &--text {
      margin-bottom: 48px;
      @include breakpoint($desktop) {
        display: flex;
        justify-content: flex-start;
      }
      .plp-header__block-container--text {
        width: 100%;
        position: relative;
      }
    }

    &--image {
      position: relative;
      display: flex;
      flex-flow: column nowrap;

      @include breakpoint($desktop) {
        width: calc((811 / 1440) * 100%);
        flex-basis: calc((811 / 1440) * 100%); // 811 and 1440 taken from designs to get an exact percentage as it doesn't follow the grid
      }

      .category-image {
        height: 100%;
        width: 100%;
      }

      .image {
        display: block;
        height: 100%;
        width: 100%;
        min-height: 208px; // for mobile
      }
    }
  }

  .page-title {
    @include fancyHeading;
    margin: 0 0 $size-m;
    font-weight: $fontBolder;

    @include breakpoint($desktop) {
      @include fontSize(40px);
      line-height: $size-6xl;
    }
  }

  .category-description {
    @include fontSize(16px);
    line-height: $size-xl;
    margin-bottom: $size-xl;

    @include breakpoint($desktop) {
      margin-bottom: $size-2xl;
    }
  }

  .pro-login {
    @include fontSize(19px);
    text-transform: uppercase;
    font-weight: $fontBolder;
    margin-top: 16px;
    padding: 17px;
    background: $grey-lighter;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    @include breakpoint($desktop) {
      @include fontSize(23px);
      position: absolute;
      top: 0;
      right: 0;
      margin-top: 0;
      left: unset;
      bottom: unset;
      width: unset;
    }
    img {
      width: 28px;
      margin-right: 10px;
    }
  }

  .category-cms {
    display: flex;
    margin-top: 32px;
    .content,
    .image {
      @include fontSize(20px);
      font-weight: $fontBolder;
      background: $grey-lighter;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      @include breakpoint($desktop) {
        @include fontSize(30px);
      }
    }
    .content {
      padding: 14px 0;
      @include breakpoint($desktop) {
        padding: 0;
      }
    }
    img,
    .image {
      display: none;
      @include breakpoint($desktop) {
        display: flex;
      }
    }
  }

  .sub-category {
    flex: 1;
    &__list {
      display: grid;
      padding: 0;
      grid-template-columns: repeat(99,1fr);
      grid-gap: 11px;
      list-style: none;
      white-space: nowrap;
      overflow: scroll;
      @include breakpoint($desktop) {
        grid-template-columns: repeat(6,1fr);
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .item {
      @include fontSize(14px);
      background: $grey-lighter;
      padding: 9px 10px;
      font-weight: $fontBolder;
      text-align: center;
      transition: all .3s;
      @include breakpoint($desktop) {
        @include fontSize(21px);
      }
      .link {
        color: $black;
        text-transform: uppercase;
        display: inline-block;
        width: 100%;
        transition: all .3s;
      }
    }
    &.active,
    &:hover {
      .item {
        background-color: $black;
        .link {
          color: $white;
        }
      }
    }
  }

  &__cta-container {
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    @include breakpoint($mobile) {
      padding: 0 $size-xl;
    }

    @include breakpoint($desktop) {
      padding: 0;
    }
  }

  &__cta {
    @extend %ghost-button;
    flex: 1;
    margin-right: $size-2xs;
    max-width: 180px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }

  &__second-heading {
    font-family: $fontHeading;
    font-size: $size-xl;
    line-height: $size-2xl;
    color: $white;
    font-weight: 400;
    margin: 0;
    position: absolute;
    left: $size-xl;
    bottom: $size-xl;

    @include breakpoint($desktop) {
      left: $size-2xl;
      bottom: $size-2xl;
      max-width: 643px; // taken from designs...
    }
  }

  &__sign-in {
    @include fontSize(14px);
    line-height: $size-l;
    padding: $size-2xs 0;
    background: $brand-tertiary;
    color: $white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 30; // increase due to Amasty Label
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: none;
    flex-flow: row nowrap;
    justify-content: center;

    @include breakpoint($desktop) {
      position: absolute;
      padding: $size-xs 0;
    }

    &:hover {
      cursor: pointer;
    }

    &::before {
      content: "";
      display: block;
      height: $size-2xl;
      width: $size-2xl;
      background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
      background-size: $size-xl;
    }

    span {
      line-height: $size-2xl;
    }
  }


  &--shop {

    .category-description {
      margin-bottom: 0;
    }
  }
}
