// Default button styles.
// Override where needed in components

button,
.btn,
.action {
  font-weight: bold;
  font-size: $size-m;
  line-height: $size-l;
  background-color: $white;
  color: $black;
  padding: 11px $size-m;
  border: 2px solid $black;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: all .4s ease-in-out;
  
  &:hover {
    background-color: $black;
    color: $white;
  }
  
  &:disabled {
    background-color: $grey-lightest;
    color: $black;
  }
  
  &:focus {
    outline: 0;
  }
}

.view-product {
  width: 100%;
  @include breakpoint($desktop) {
    margin-top: 31px;
  }
  &:hover {
    color: $white;
  }
}

%secondary-button {
  background-color: $brand-senary;

  &:hover {
    background-color: $brand-quaternary;
  }
}

%tertiary-button {
  background-color: $brand-quinary;

  &:hover {
    background-color: $brand-tertiary;
  }
}

%ghost-button {
  background-color: transparent;
  font-weight: $fontReg;
  box-shadow: inset 1px 0 0 0 $white, inset 0 1px 0 0 $white, inset -1px 0 0 0 $white, inset 0 -1px 0 0 $white;

  &:hover {
    background-color: transparent;
  }
}

%ghost-button-inverted {
  background-color: $white;
  font-weight: $fontReg;
  box-shadow: inset 1px 0 0 0 $grey-lighter, inset 0 1px 0 0 $grey-lighter, inset -1px 0 0 0 $grey-lighter, inset 0 -1px 0 0 $grey-lighter;
  color: $grey-light;

  &:hover {
    background-color: transparent;
  }
}

%link-style-button {
  @include fontSize(14px);
  line-height: $size-l;
  color: #2F1A45;
  letter-spacing: 1px;
  text-decoration: underline;
  text-transform: uppercase;
}
