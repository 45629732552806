.account-menu-header {
  display: none;
}

.account-nav {
  font-size: $size-m;
  line-height: $size-l;
  color: $black;
  
  .account-nav-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid $black;
    padding: $size-m 12px;
    font-size: $size-m;
    line-height: 20px;
    font-weight: bolder;

    .content {
      flex: 1 1 auto;
      padding: 0 $size-m;
      font-weight: bold;
    }

    .prefix,
    .suffix {
      flex: 0 0 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .suffix {
      background-image: url("../images/icons/icon-chevron-down-dark.svg");
    }

    &.active {
      border-bottom: 1px solid $grey-light;

      .suffix {
        transform: rotate(180deg);
      }
    }

    @include breakpoint($desktop) {
      display: none;
    }
  }

  .account-nav-content {
    height: 0;
    transition: height .3s ease-in-out;
    overflow: hidden;
    margin-bottom: $size-m;
    @include breakpoint($desktop) {
      height: auto;
    }
    &.active {
      border-bottom: 1px solid $grey-lighter;
      height: auto;
    }
    .nav.items {
      padding-left: 0;
      margin-bottom: 0;
    }
  }
  .nav.item {
    list-style: none;
    a,
    strong {
      display: flex;
      align-items: center;
    }
    a {
      padding: 15px $size-xs;
      border: 1px solid $grey-lighter;
      border-top-width: 0;
      color: $black;
      @include breakpoint($desktop) {
        border-width: 1px 0 0 0;
        padding-left: $size-xs;
      }
    }
    a.signout-icon {
      border-bottom-width: 1px;
      @include breakpoint($desktop) {
        border-bottom-width: 0;
      }
    }
    &.current {
      padding: 15px $size-xs;
      border: 1px solid $grey-lighter;
      border-top-width: 0;
      background-color: $grey-lighter;
      
      @include breakpoint($desktop) {
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 1px;
        border-bottom-width: 0;
      }

      a {
        padding: 0;
        border: 0;
      }
    }
    &:first-child {
      a,
      strong {
        border-top-width: 0;
        @include breakpoint($desktop) {
          border-top-width: 1px;
        }
      }
    }
  }
}

div[id="account-menu-mobile"] .block .title {
  &::after {
    height: $size-xl;
    width: $size-xl;
    content: " ";
    background: url("../images/icons/chevron-up.svg") no-repeat;
    background-size: $size-xl $size-xl;
    float: right;
    transition: all .3s;
    transform: rotate(180deg);
    justify-self: right;
    align-self: center;
  }
  strong {
    align-self: center;
    font-weight: $fontReg;
  }
}

div[id="account-menu-mobile"] .block .title.active::after {
  transform: rotate(0deg);
}

.icon-wrapper::before {
  margin-right: 8px;
  content: " ";
  height: $size-xl;
  width: $size-xl;
  display: block;
  background-size: $size-xl $size-xl;
}

.address-book-icon::before {
  background-image: url(../images/icons/icon-address-book.svg);
}

.my-account-icon::before {
  background-image: url(../images/icons/icon-account-purple.svg);
}

.account-information-icon::before {
  background-image: url(../images/icons/icon-account-information.svg);
}

.my-orders-icon::before {
  background-image: url(../images/icons/icon-orders.svg);
}

.marketing-preferences-icon::before,
.contact-preferences-icon:before {
  background-image: url(../images/icons/icon-contact.svg);
}

.wish-list-icon::before,
.wishlist-icon::before {
  background-image: url(../images/icons/icon-wishlist.svg);
  height: $size-l;
  width: $size-l;
  background-size: $size-l;
  margin-left: 3px;
  margin-right: $size-xs;
}

.signout-icon::before {
  background-image: url(../images/icons/icon-signout.svg);
}

.popup-authentication {
  .modal-header {
    .modal-title {
      font-weight: bold;
      letter-spacing: unset;
      text-transform: uppercase;
      
      &::before {
        background: url(../images/icons/icon-account-black.svg) 50% 50% no-repeat;
        background-size: cover;
      }
    }

    .action-close {
      height: $size-s;
      background: unset;

      &:hover {
        color: $black;
      }
    }
  }
  
  .block-authentication {
    margin: $size-m 0;
    display: flex;
    flex-flow: column nowrap;
    padding: 0 12px;
  }

  .block-customer-login,
  .block-new-customer {
    background: $grey-lighter;
    padding-bottom: 24px;
    form {
      background: transparent;
    }
  }
  
  .block-new-customer {
    order: 2;
    margin-top: $size-m;
    border-top: 1px solid $grey-lighter;
    padding-top: $size-xl;
    
    .block-content {
      padding: 0 $size-xl;
      margin: 0;
      
      p {
        margin-bottom: 0;
      }
    }
  }
  
  .block-customer-login {
    order: 1;
    padding-top: $size-xl;
    .block-content {
      padding: 0 $size-xl;
      margin: 0;
      form {
        padding: 0;
      }

      .actions-toolbar {
        & > .secondary {
          text-align: right;
        }
      }
    }
  }

  .cms-block {
    order: 3;
    margin-top: 44px;
    img {
      width: 100%;
    }
    .action {
      width: 100%;
    }
  }
  
  .forgotten-password {
    @include fontSize(14px);
    line-height: $size-l;
    text-decoration: underline;
    margin-top: -8px;
    color: $black;
  }
  
  .action-login,
  .action-register {
    width: 100%;
    background: $grey-lighter;
    font-weight: bold;
    span {
      color: $black;
    }

    &:hover {
      background: $black;
      span {
        color: $white;
      }
    }
  }
  
  .block-title {
    padding-left: $size-xl;
    margin-bottom: $size-s;
    
    strong {
      @include fontSize(18px);
      font-weight: $fontBolder;
      line-height: 20px;
      color: $black;
    }
  }

  input {
    height: 40px;
    line-height: 40px;
    border: none;

    &::placeholder {
      line-height: 40px;
      vertical-align: middle;
      text-transform: unset;
    }
  }

  .mage-error {
    color: $error;
    font-size: $size-s;
    margin-bottom: $size-2xs;
  }

  [data-ui-id="checkout-cart-validationmessages-message-error"] {
    background: $error;
    color: $white;
    padding: 8px 0;
    text-align: center;
    display: block;
    width: calc(100% - 48px);
    margin-left: 24px;
  }

  overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
}

.modal-account-menu-header {
  .modal-header {
    .modal-title {
      font-weight: bold;
      text-transform: uppercase;
      
      &::before {
        background: url(../images/icons/icon-account-black.svg) 50% 50% no-repeat;
        background-size: cover;
      }
    }
  }

  // .modal-content {
  //   padding-left: 0;
  //   padding-right: 0;
  // }

  .account-menu-header__list {
    list-style: none outside;
    padding-left: 0;
    
    a {
      @include modalListItem;
      background: $white;
      color: $black;
      text-transform: unset;
      outline: none;
    }
  }
}

.discover-more {
  
  &__image {
    width: 100%;
  }

  &__content {
    padding: 0 $size-m;
    
    .btn {
      width: 100%;
      font-weight: bold;

      &:hover {
        background: $black;
        color: $white;
      }

      a {
        color: $black;
        &:hover {
          color: $white;
        }
      }
    }
  }
}