@mixin bundleText {
  color: $black;
  font-size: $size-s;
  line-height: $size-s;
  font-weight: $fontBolder;
  text-transform: capitalize;

  @include breakpoint($desktop) {
    font-size: 20px;
    line-height: 28px;
  }
}

.page-product-bundle {
  .product-add-form form {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 45px;

    .block-bundle-summary {
      order: 0;
      padding: 0 $size-xl;

      @include breakpoint($desktop) {
        padding: 0;
      }

      .block-summary:not(.empty) {
        margin-bottom: $size-xl;

        @include breakpoint($desktop) {
          margin-bottom: $size-2xl;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 15px;

        li {
          color: $black;
          font-size: $size-s;
          line-height: 22px;

          @include breakpoint($desktop) {
            font-size: 16px;
            line-height: 24px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          > strong {
            display: none;
          }
        }
      }

    }

    fieldset .field {
      margin-bottom: $size-xl;

      &:last-of-type {
        margin-bottom: 0;
      }
      &.single {
        display: none;
      }
    }

    .bundle-options-wrapper {
      order: 1;

      .fieldset-bundle-options {
        width: 94%;
        @include breakpoint($desktop) {
          width: 75%;
        }
        .price-notice {
          display: none;
        }
      }

      .product-options-wrapper {
        background: transparent;
        padding: 0;

        label {
            @include bundleText;

            &::after {
              display: none;
            }
          }
        }

        .field.qty {
          display: none;
        }

        select {
          width: 94%;
          @include breakpoint($desktop) {
            width: 75%;
          }
        }
      }

      button[title="Buy Now"] {
        display: none;
      }
    }

    .add-to-cart {
      order: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: 0;
      padding: 0 $size-xl;

      @include breakpoint($desktop) {
        margin-top: $size-2xl;
        padding: 0;
      }

      .field.qty {
        order: 2;

        @include breakpoint(max-width $tablet) {
          top: unset;
          bottom: 125px;
        }

        .control {
          display: flex;
          margin-top: $size-s;

          @include breakpoint($desktop) {
            margin-top: 0;
          }
        }

        .qty-box,
        .input-text {
          @include qtyBox;
          padding: 0;
          border-radius: 0;
        }

        .input-text {
          border-left: 0;
          border-right: 0;
        }
    }

    .price-box {
      order: 1;
      flex: 1;
      @include bundleText;
      .price-to {
        display: none;
      }
    }

    .box-tocart {
      order: 3;
      width: 100%;
      margin-top: $size-4xl;
      margin-bottom: 15px;
      padding: 0;

      @include breakpoint($desktop) {
        margin-top: $size-2xl;
      }

      button {
        width: 100%;
        font-size: $size-l;
        font-weight: $fontBolder;
      }
    }
    .pay-later-container {
      order: 3;
      width: 100%;
    }
  }

  .price-from {
    margin-bottom: 0;
    display: flex;
  }

  .special-bundle {
    .price-from {
      >.price-final_price span {
        color: #CB0000;
      }
    }
  }

  .price-configured_price,
  .bundle-actions,
  .product-options-wrapper p.required,
  .bundle-options-wrapper fieldset.fieldset-bundle-options .field.option.required .nested.options-list {
    display: none;
  }
}