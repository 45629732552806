//
//  COOKIE NOTICE
//

.message.cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: $grey-lighter;
  padding: 24px;
  z-index: 999;
  text-align: center;

  p, a {
    color: $black;
    letter-spacing: unset;
  }
  
  a {
    text-decoration: underline;
  }
  
  button {
    background: unset;
    font-weight: bold;
    letter-spacing: unset;

    &:hover {
      background: $black;
      color: $white;
    }
  }

  .close-cookie-notice {
    position: absolute;
    top: -6px;
    right: 0;
    padding: $size-m;
    font-weight: bold;
    cursor: pointer;
  }
}