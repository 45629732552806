//
// Found on Cart, Checkout
//

.block.discount {
  padding: 0 $size-m;
  margin: $size-xl 0;

  @include breakpoint($desktop) {
    padding: 0;
  }

  .title {
    position: relative;
    margin-bottom: $size-s;

    &:focus {
      outline: none;
    }

    strong {
      color: $black;
      font-family: $fontBody;
      font-size: $size-m;
      font-weight: bold;
    }
  }

  &.active {
    .title {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  form {
    background: $white;
    padding: 0;

    .fieldset {
      display: flex;
      justify-content: space-between;
    }

    .actions-toolbar {
      display: inline-block;
      margin-left: $size-2xs;

      .primary {
        margin-top: 0;
        width: 100%;
        height: $size-4xl;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
      }

      span {
        font-weight: bold;
      }
    }

    .applied {
      .actions-toolbar {
        width: 100px;
      }
    }

    .field {
      display: inline-block;
      width: 100%;

      input {
        margin-bottom: 0;
        color: $black;
        font-size: $size-s;
        letter-spacing: 1px;
        height: $size-4xl;
        border-radius: 0;
        border-width: 2px;


        &::placeholder {
          color: $black;
          text-transform: initial;
          font-size: $size-s;
        }
      }
    }
  }
}
