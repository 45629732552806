.customer-account-create {
  .page-title-wrapper {
    max-width: $innerMaxWidth;
    margin: 0 auto;
    padding: 0 23px;
    @include breakpoint($desktop) {
      padding: 0;
    }
  }
  .column.main {
    padding: 0 23px;
    @include breakpoint($desktop) {
      padding: 0;
    }
  }
  .form-create-account {
    background: $grey-lighter;
    padding: 16px;
    @include breakpoint($desktop) {
      padding: 58px 80px;
    }
  }
  .intro {
    @include fontSize(18px);
    margin-bottom: 20px;
  }
  .fieldset {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid $black;
    margin-bottom: 26px;
    padding-bottom: 5px;
    &.account {
      border-bottom: 0;
    }
    .field {
      flex-basis: span(16);
      position: relative;
      @include breakpoint($desktop) {
        flex-basis: 50%;
        padding: 5px;
      }
      .label {
        display: none;
        position: absolute;
        top: -18px;
        left: 5px;
        @include breakpoint($desktop) {
          top: -12px;
        }
      }
      input,
      select {
        margin-bottom: 24px;
        @include breakpoint($desktop) {
          margin-bottom: 16px;
        }
      }
      &.search-container {
        order: 5;
        .action {
          display: none !important;
        }
      }
      &.country {
        order: 6;
      }
      &.country,
      &.zip,
      &.street2,
      &.city,
      &.region {
        display: none;
      }
    }
    .manual-link {
      order: 7;
      width: 100%;
      text-decoration: underline;
      margin-bottom: 10px;
      cursor: pointer;
    }
    .customer-dob {
      button {
        display: none;
      }
    }
    .heading {
      width: 100%;
      font-weight: $fontBolder;
      padding-left: 5px;
      margin-bottom: 16px;
    }
  }
  .actions-toolbar {
    .secondary {
      display: none;
    }
  }
  .ui-datepicker {
    display: none !important; // override JS
  }
}