.cms-no-route {

  .page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .page-title-wrapper,
  .column.main {
    padding: 0 $size-m;
    color: $black;
    @include breakpoint($desktop) {
      text-align: center;
    }

    p {
      margin-bottom: $size-6xl
    }
  }

  .page-title-wrapper {

    .page-title {
      font-size: 40px;
    }
  }

  .home-link {
    text-align: center;
  }

  .btn.primary {
    color: $black;
    margin-bottom: 1rem;
    font-size: $size-l;
    font-weight: bold;
    letter-spacing: unset;
    width: 100%;
    max-width: 260px;

    &:hover {
      color: $white;
    }
  }

  .prev-link {
    margin-bottom: 1rem;
    text-align: center;

    a {
      color: $black;
      border-bottom: 2px solid $brand-primary;
      font-size: $size-s;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}