.booking-training-popup {

  .modal-header {
    background: transparent;
    padding: 22px 16px;
    height: auto;
    position: relative;

    .action-close:before {
      position: absolute;
      right: 6px;
      top: 20px;
    }

    .modal-title {
      font-size: 24px;
      line-height: 20px;
      font-weight: $fontBolder;
      text-transform: none;
      height: auto;

      &:before {
        display: none;
      }
    }

    .action-close {
      height: 22px;
      width: 22px;
      margin: 0;
    }
  }

  .modal-title::before {
    background: url(../images/icons/icon-info.svg) 50% 50% no-repeat;
    background-size: 16px;
  }
  
  &.modal-popup._show {
    
    .product-options-wrapper {

      a.terms-link {
        color: $black;
      }

      .attendee-permanent-notice {
        display: block!important;
      }

      .attendee-name-title {
        display: none;
      }
      
      .field {
        display: block;
        width: 100%;

        &.select-input {
          margin-bottom: 16px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        input::placeholder {
          text-transform: none;
        }

        label {
          text-transform: none;
          font-size: 14px;
          line-height: 22px;
          font-weight: $fontBolder;
        }

        label:after {
          display: none;
        }

        &.select-input {

          label {
            @include fontSize(14px);
            line-height: 22px;
            text-transform: none;
            font-weight: $fontReg;
            letter-spacing: 0;
          }
        }

        &[data-title*="Terms"] {
          margin-top: 32px;
          margin-bottom: 24px;

          label {
            background-color: $white !important;
            font-weight: $fontBold;
          }

          .mage-error {
            margin-top: 0;
          }
        }

        .checkbox {
          margin-bottom: 0;
        }
      }

      h2 {
        font-size: 20px;
        line-height: 48px;
        color: $black;
        text-transform: none;
        font-weight: 600;
        letter-spacing: 0;
        margin: 0;
      }

      p {
        font-size: 16px;
        color: $black;
        line-height: 22px;
        margin-bottom: 24px;
      }

      .attendee-permanent-notice {
        margin-top: 16px;

        p {
          font-size: 14px;
          line-height: 22px;
          margin-bottom: 0;
        }
      }

      .mage-error {
        @include fontSize(12px);
        line-height: 16px;
        color: $error;
        margin: -8px 0 16px;
      }
    }

    .product-options-bottom {
      
      div.actions {
        position: static;
      }
    }

    // I think these should look different if in fact we actually need two
    #booking-available-notice-please-phone,
    #booking-not-available {
      background: $brand-primary;
      padding: 24px;
      color: $black;
      width: 100%;
      margin: 0 0 16px;
    }

    .product-info-price {
      display: none;
    }

    .field.qty { 
      display: none;
    }

    .modal-content {
      padding: 0 16px;

      .product-add-form {
        padding: 0;
      }

      .product-options-wrapper {
        background: transparent;
        padding: 0;
      }
    }
  }

  .box-tocart {
    padding: 0;
  }

  .actions {
    max-width: 100% !important;
    margin-bottom: 32px;

    .fixed-tocart {
      display: none;
    }
  }

  .training-add-to-cart {
    width: 100%;
    background-color: $brand-senary !important;
    height: 40px !important;
    
    &.disabled {
      background-color: $grey-lightest !important;
      color: $black !important;
    }
  }
  
  .product-not-available-message {
    background: #8c69b1;
    color: #fff;
    font-size: $size-l;
    padding: 20px;
  }
  .trustpilot-widget {
    display: none;
  }
}
