.product-items {
  .product-options {
    @include breakpoint($desktop) {
      min-height: 44px;
    }
  }
}
.swatch-attribute-options {
  display: flex;
  flex-wrap: wrap;
  .swatch-option {
    cursor: pointer;
    box-sizing: border-box;
    &.color {
      width: 20px;
      height: 20px;
      border-radius: 25px;
      margin-right: 5px;
      margin-bottom: $size-xs;
      transition: all .5s;
      @include breakpoint($desktop) {
        width: $size-2xl;
        height: $size-2xl;
        margin-right: $size-xs;
        margin-bottom: $size-xl;
      }
      &.selected {
        background-image: url("../images/icons/icon-swatch-check.svg") !important; // has to be due to swatch-renderer
        background-size: 14px 14px !important; // has to be due to swatch-renderer
      }

      &.disabled {
        background-image: url("../images/icons/icon-swatch-oos.svg") !important; // has to be due to swatch-renderer
        background-size: 14px 14px !important; // has to be due to swatch-renderer
      }
    }
    &:hover {
      box-shadow: inset 0px 0px 8px 1px $white;
    }
  }
  .filter-options & {
    justify-content: space-evenly;
    margin-top: 15px;
    .swatch-option-link-layered {
      flex: 1;
      text-align: center;
      font-size: 14px;
      font-weight: $fontReg;
      text-transform: none;
      color: $black;
      .swatch-option {
        margin: 0 auto;
      }
    }
  }
}
.swatch-input {
  display: none;
}
.group-swatch {
  display: inline-flex;
}
.group-swatch .swatch {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  margin-bottom: 24px;
  cursor: pointer;
}