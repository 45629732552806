.catalog-product-view {

  .columns {
    @include breakpoint($desktop $desktop-mid) {
      padding: 0 $size-m;
    }
  }

  .page-title-wrapper,
  .product-info-price {
    padding: 0 $size-xl;

    @include breakpoint($desktop) {
      padding: 0;
    }
  }

  .fotorama__nav-wrap {
    max-width: 100px;
    .fotorama__nav--thumbs .fotorama__nav__frame {
      width: 100px !important;
    }
  }

  .fotorama__stage {
    min-height: 427px;
    @include breakpoint(375px) {
      min-height: 500px;
    }
    @include breakpoint($mobile) {
      min-height: 567px;
    }
    @include breakpoint($desktop) {
      min-height: 560px;
      max-height: 615px;
    }

    @include breakpoint($desktop) {
      left: 103px !important;
      padding-left: $size-2xs;
      .fotorama__stage__shaft.fotorama__grab {
        max-width: 519px !important;
      }
    }
  }

  // Commenting out for now as it was having a negative impact on the new product images
  // I can't see any downsides in removing it, but we may spot some once there are more new product images
  //
  // .fotorama__stage__frame .fotorama__img {
  //   max-height: none !important;
  // }

  .price-container h4 {
    color: $brand-primary;
  }

  .fotorama--fullscreen {
    .amasty-label-container {
      display: none !important;
    }
  }

  &.fotorama__fullscreen {

    .fotorama__stage {
      height: 100vh;
      max-height: 1408px; // to match the height of product_page_image_large set in view.xml
    }
  }
  
  .terms-link {
    color: $black;
    font-size: 14px;
    line-height: 22px;
    font-weight: $fontBold;
    vertical-align: top;
    a {
      color: $black;
      text-decoration: underline;
    }
  }

  .product-options-wrapper {
    background: #EDEDED;
    padding: 12px;
    margin: 0 24px;

    @include breakpoint($desktop) {
      padding: 16px;
      margin: 0;
    }

    .super-attribute-select {
      display: none;
    }

    .swatch-opt {
      width: 100%;
    }

    .swatch-attribute {
      margin-bottom: 16px;

      @include breakpoint($desktop) {
        margin-bottom: 24px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .swatch-attribute-options {
      @include breakpoint($desktop) {
        max-width: 80%;
      }
    }

    .swatch-select-container {
      width: calc(100% - 130px);
      select {
        background-color: $white;
      }
    }

    .swatch-attribute.color .swatch-input {
      display: none;
    }

    .swatch-attribute-label {
      display: block;
      margin-bottom: $size-2xs;
      font-size: 16px;
      color: $black;
      font-weight: $fontBolder;

      @include breakpoint($desktop) {
        font-size: 20px;
      }
    }

    .swatch-attribute-selected-option {
      display: block;
      font-size: 14px;
      margin-bottom: $size-xs;
      color: $black;

      @include breakpoint($desktop) {
        font-size: 16px;
      }
    }

    .swatch-input,
    .swatch-option {
      cursor: pointer;
    }

    .swatch-option.color {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      margin-right: 12px;
      display: inline-block;

      @include breakpoint($desktop) {
        margin-bottom: 0;
      }

      &.selected {
        background-image: url("../images/icons/icon-swatch-check.svg") !important; // has to be due to swatch-renderer
        background-size: 14px 14px !important; // has to be due to swatch-renderer
      }

      &.disabled {
        background-image: url("../images/icons/icon-swatch-oos.svg") !important; // has to be due to swatch-renderer
        background-size: 14px 14px !important; // has to be due to swatch-renderer
      }

      &:last-of-type {
        margin-right: 0;
      }
    }

    select {
      margin-bottom: 0;   
      background: url("../images/icons/icon-chevron-down-dark.svg") no-repeat;
      background-position: right 1.2em top 50%,0 0;
      background-size: 25px 25px;
    }
  }

  .mobile-only {
    @include breakpoint($desktop) {
      display: none;
    }
  }

  .terms-and-conditions-wrapper{
    margin-bottom: 15px;
    label{
      line-height: 18px;
    }
  }
}

.product-simple-product {
  .product-info-main .product-add-form .field.qty {
    position: absolute;
    right: 24px;
    top: 0;
    width: auto;

    @include breakpoint($desktop) {
      right: 0;
      top: 48px;
    }
  }
}

.price-box .price-including-tax, .product-info-main .product-info-price .price-container .price-including-tax, .product-item-details .price-container .price-including-tax {  display: none;}
.block-minicart .block-content .subtotal span.price-including-tax {display:none;}
.product-book-training-online .product-info-main .product-info-price .price-container .price-including-tax {  display: block;}
.price-box .price-container [data-label="Incl. tax"]::before{
	content:  '';  
}
@media (min-width:320px)  {
.trianing-item .product-item-inner .actions-primary .view-product { font-size: 14px;}
}
@media (min-width:1025px)  {
.trianing-item .product-item-inner .actions-primary .view-product {font-size: 16px;}
}
