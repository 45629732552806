.kit-list-wrapper {
  flex-flow: row wrap;
  display: flex;
  margin-top: $size-xl;

   @include breakpoint($desktop) {
     justify-content: space-between;
     margin-top: 55px;
   }
  
  .kit-item {
    width: 100%;
    flex-basis: 100%;
    margin-bottom: $size-m;
    
    @include breakpoint($desktop) {
      width: span(8);
      flex-basis: span(8);
      margin-bottom: 0;
    }

    &:nth-child(n+3) {
      @include breakpoint($desktop) {
        margin-top: $size-xl;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .kit-header {
    padding: $size-2xs 0;

    h5 {
      @include fontSize(19px);
      line-height: $size-2xl;
      letter-spacing: 2px;
      color: $white;
      text-transform: uppercase;
      margin: 0;
      text-align: center;
      font-weight: 300;
    }
  }

  .kit-body {
    background-color: $white;
  }

  .common-params {
    margin-top: 0;
    padding: $size-2xs $size-m 0;

    .key-value-pair {
      display: flex;
      flex-flow: row nowrap;
      color: $brand-primary;
      border-bottom: 1px solid $grey-lighter;
      
      &:last-child {
        margin-bottom: $size-2xs;
        border-bottom: 0;
      }

      &--kit_course_cost,
      &--kit_value_of_products {
        font-weight: 500;
      }
    }

    span {
      @include fontSize(14px);
      line-height: $size-l;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 7px 0;
    }

    .key {
      width: 60%;
      flex-basis: 60%;
      flex-grow: 1;
    }

    .value {
      text-align: right;
      padding-left: 24px;
    }
  }

  .view-kit-list {
    width: calc(100% - 32px);
    margin-left: $size-m;
    margin-bottom: $size-m;

    @include breakpoint($desktop) {
      text-align: left;
      position: relative;
    }

    &::after {
      
      @include breakpoint($desktop) {
        @include icon-svg-reset;
        width: 20px;
        height: 20px;
        background: url(../images/icons/icon-plus-white.svg) 50% 50% no-repeat;
        background-size: 16px;
        position: absolute;
        right: 16px;
        top: 10px;
      }
    }

    &--open::after {

      @include breakpoint($desktop) {
        background: url(../images/icons/icon-minus-white.svg) 50% 50% no-repeat;
      }
    }
  }
}

.no-touchevents .full-kit-list {

  &--show-desktop {
    max-height: 5000px; // need a number that is going to be larger than what will realistically be needed
    //display: table;
  }
}

.full-kit-list {

  .key-value-pair {
    display: flex;
    flex-flow: row nowrap;

    &:nth-last-child(2) {

      @include breakpoint($desktop) {
        margin-bottom: $size-m;
      }
    }

    &.additional-header {
      color: $black;
      padding: 7px 0;
      margin: 0;
      background: transparent;
      border-bottom: 1px solid $black;

      span {
        padding: 0;
        background: transparent !important;
        font-size: 18px;
        font-weight: $fontBolder;
        line-height: 30px;
        text-transform: none;
        color: $black;
      }
    }

    span {
      @include fontSize(14px);
      line-height: $size-l;
      color: $black;
      flex-grow: 1;
    }
  }

  .value {
    text-align: right;
    padding: 7px 10px 7px 0;
    min-width: 52px;
  }

  .key {
    font-weight: $fontBold;
    padding: 7px 0;
  }

  .additional-header .key {
    font-weight: $fontReg;
  }

  .common-params--modal {

    @include breakpoint($desktop) {
      display: none;
    }
  }
}

[class*="modal-kit-list-"] {

  .modal-header {
    background: transparent;
    padding: 22px 40px;
    height: auto;
  
    .modal-title {
      height: auto;
      line-height: 22px;
      text-transform: none;
      font-size: 24px;
      font-weight: $fontBolder;

      &::before {
        display: none;
      }
    }

    button.action-close {
      margin: 0;
      height: 22px;
      width: 22px;
    }
  }

  .modal-content {


    .additional-params.full-kit-list {
      .kit-wrapper {
        background: #EDEDED;
        margin: 0 22px;
        padding: 22px 18px 53px;
        position: relative;

        .title {
          margin: 0 0 12px;
          font-size: 20px;
          line-height: 22px;
          color: $black;
        }

        .actions.book-training.fixed {
          position: absolute;
          bottom: 0;
          margin-top: 16px;
          left: -22px;
          width: calc(100% + 26px + 18px);

          button {
            width: 100%;
            border: 1px solid $border-light;
          }
        }
      }

      .info-block {
        padding: 0 40px;

        p {
          font-size: 16px;
          line-height: 22px;
          &.price {
            font-size: 22px;
            line-height: 30.8px;
            margin-top: 0;
            margin-bottom: 16px;
            &.special {
              color: #CB0000;
            }
          }
        }
      }

      .description {
        margin: 0;
      }

      .price {
        margin: 16px 0;
      }
    }

  }

  .common-params--modal {
    display: none;
  }
}

.ct-view-hd_kit_section {
  .ct-field-repeater_fields.flex__holder {
    flex-wrap: wrap;
    .ct-view-hd_brows_kit_details {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .open-modal {
    cursor: pointer;
  }
}

#training-content.main-content .modal__window {
  top: 100px;
  max-height: 630px;
  .modal__top::after {
    top: 10px;
  }
}