//
// PDP Media - Fotorama
//

.catalog-product-view {
  .columns .main {

    .product.media {
      @include breakpoint($pdp-desktop) {
        position: sticky!important; // amasty labels overrides this
        top: 154px;
      }

      .gallery-placeholder._block-content-loading {
        .gallery-placeholder__image {
          width: 100%;
        }

        + .media--bottom {
          flex-basis: span(12);
          margin-left: 0;
          margin-top: 0;
        }
      }
    }

    .product-media__wrapper {
      position: relative;

      @include breakpoint($pdp-desktop) {
        flex-basis: span(8.5);
        display: inline-block;

        .fotorama__thumb-border {
          border-left: 0;
          border-right: 0;
          border-top: 0;
          border-bottom: 2px solid $brand-primary;
        }
      }

      .fotorama-item {
        position: relative;
      }

      .fotorama__nav--thumbs {
        @include breakpoint($pdp-desktop) {
          display: block !important;
          max-height: 414px;
          overflow: scroll;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
        }

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .gallery--zoom {
        background: $white;
        position: absolute;
        bottom: 18px;
        right: 6px;
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint($pdp-desktop) {
          height: $size-3xl;
          width: $size-3xl;
          right: 28px;
        }

        &:hover {
          cursor: pointer;
        }

        .icon {
          background: url("../images/icons/icon-zoom.svg");
          background-size: $size-xl $size-xl;
          height: $size-xl;
          width: $size-xl;
        }
      }

      // Amasty overrides

      .amlabel-position-wrapper {
        top: 12px!important;
        left: 12px!important;

        @include breakpoint($pdp-desktop) {
          left: 20px!important;
        }
      }

      .amasty-label-container {
        padding: 0 6px !important;
        @include breakpoint($pdp-desktop) {
          padding: 8px 12px !important;
        }
      }

      .amlabel-position-wrapper,
      .amasty-label-container {
        width: auto!important;
        height: auto!important;

        @include fontSize(11px);
        background: $white;
        color: $black;
        font-weight: $fontBolder;
        width: unset !important;
        @include breakpoint($desktop) {
          @include fontSize(16px);
        }

        .amasty-label-text {
          position: static!important;
          font-size: 16px;

          @include breakpoint($pdp-desktop) {
            font-size: 22px;
          }
        }
      }
    }
  }

  .fotorama__nav__frame.video-thumb-icon {
    img {
      min-height: 160px;
    }
  }

  .fotorama__stage__frame .fotorama__img {
    transform: translate3d(-50%, 0%, 0);
    top: 0;
  }

  .media--bottom {
    display: flex;
    align-items: center;
    border: 1px solid #ededed;

    &__cta {
      flex: 1;
      padding: 12px;
      text-align: center;
      position: relative;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 22px;
        margin-right: 8px;

        @include breakpoint($pdp-desktop) {
          width: 28px;
        }
      }

      a,
      span,
      p {
        color: $black;
        font-size: $size-s;
        font-weight: $fontBolder;
        line-height: 20px;
        letter-spacing: 0;
        display: flex;
        align-items: center;
        border: none;
        text-transform: none;

        @include breakpoint($pdp-desktop) {
          font-size: 20px;
          line-height: 22px;
        }
        &:hover {
          background: none;
        }
      }

      &:first-of-type {
        border-right: 1px solid #ededed;
      }

      .towishlist--logged-out,
      .action.delete.offwishlist,
      .media-video {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        padding: 0;
        text-transform: none;
      }
      .action.delete.offwishlist {
        display: none;
        &:hover {
          background: none;
        }
      }
    }

    @include breakpoint($desktop) {
      flex-basis: span(6);
      margin-left: 140px;
      max-width: 461px;
    }
  }
}

.fotorama__nav-wrap.fotorama__nav-wrap--horizontal {
  display: none !important;
}

.fotorama__nav--dots {
  bottom: 100px;
  overflow: visible;
  height: auto;
  margin-bottom: -20px;

  @include breakpoint($desktop) {
    width: 60%;
    margin-bottom: 0;
    position: absolute;
    left: 30%;
  }

  .fotorama__nav__frame {
    margin-right: 12px;
    height: auto;
    width: auto;

    &:last-of-type {
      margin-right: 0;
    }

    &.fotorama__active {
      .fotorama__dot {
        background: $usp-background;
      }
    }

    .fotorama__dot {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background: #FFF;
      border: 1px solid #000;
      position: static;
    }
  }
}

.fotorama-video-container::after {
  background: url("../images/icons/icon-play-circle.svg") bottom right;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: $size-xs;
  width: 100px;
}

.product-video {
  bottom: 0;
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 9999;
  }
}

div[class*="amlabel-position-top-right"],
div[class*="amlabel-position-bottom-right"] {
  right: $size-m !important;

  @include breakpoint($pdp-desktop) {
    right: 78px !important;
  }
}
