// This is a component as same styling appears outside the PLP e.g. bottom of PDP, bottom of Basket, whishlist, etc.

.products-grid {
  padding: 0 $size-m;

  @include breakpoint(1232px) {
    padding: 0;
  }
}

.product-items,
[class^="product-items--new"] {
  list-style: outside none;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  position: relative; // for abs pos infiniate scroll elements
  width: 100%; // to keep it open when there is only one product in the block

  .product-item {
    width: span(8);
    flex-basis: span(8);
    margin-bottom: $size-6xl;

    &:nth-of-type(odd) {
      margin-right: gutter();

      @include breakpoint($tablet 1024px) {
        margin-right: 0;
      }
    }

    &:nth-of-type(4n + 2) {

      @include breakpoint($desktop) {
        margin-right: gutter();
      }
    }

    &:nth-of-type(3n + 1) {

      @include breakpoint($tablet 1024px) {
        margin-right: gutter();
      }
    }

    &:nth-of-type(3n + 2) {

      @include breakpoint($tablet 1024px) {
        margin-right: gutter();
      }
    }

    @include breakpoint($tablet) {
      width: calc(((1 / 3) * 100%) - ((64 / 3) * 1px));
      flex-basis: calc(((1 / 3) * 100%) - ((64 / 3) * 1px));
    }

    @include breakpoint($desktop) {
      width: span(4);
      flex-basis: span(4);
    }
  }

  .product-item-photo {
    display: block;
    margin-bottom: $size-xs;

    .product-image-container {
      position: unset !important;
    }

    .amlabel-position-wrapper {
      top: 12px !important;
      left: 12px !important;
      width: unset !important;
      height: unset !important;
    }

    .amasty-label-container {
      width: unset !important;
      height: unset !important;
    }

    .amasty-label-container {
      @include fontSize(11px);
      background: $white;
      color: $black;
      padding: 0 6px !important;
      font-weight: $fontBolder;
      width: unset !important;
      @include breakpoint($desktop) {
        @include fontSize(16px);
        padding: 8px 12px !important;
      }
      .amasty-label-text {
        position: static!important;
      }
    }

    img {
      width: 100%;

      &.amasty-label-image {
        @include breakpoint(max-width $desktop) {
          padding-right: 40px;
        }
      }
    }
  }

  // .product-item-details {
  //   color: $brand-primary;
  //   text-align: center;
  // }

  .product-item-info {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    color: $brand-primary;
    text-align: center;
    position: relative;
    z-index: 10 !important; // needed to override unnecessary 2000 Amasty Label adds inline
  }

  .product-item-name {
    @include fontSize(18px);
    font-weight: $fontBolder;
    display: block;
    text-align: left;
    margin-bottom: $size-2xs;
    letter-spacing: 0;
    @include breakpoint($desktop) {
      @include fontSize(30px);
    }
    a {
      color: $black;
    }
  }
  .product-item-description {
    @include fontSize(14px);
    color: $black;
    text-align: left;
    @include breakpoint($desktop) {
      @include fontSize(22px);
    }
  }

  .price-box {
    @include fontSize(14px);
    font-weight: $fontBolder;
    text-align: left;
    letter-spacing: 0;
    color: $black;
    margin-top: 10px;
    @include breakpoint($desktop) {
      @include fontSize(19px);
    }
    .price-from .price-label {
      position: relative;
      left: unset;
      font-weight: $fontReg;
    }
    .price-to {
      display: none;
    }
  }

  .product__variation {
    @include fontSize(14px);
    line-height: $size-l;
    margin-bottom: $size-2xs;
    margin-top: auto;
  }

  .product__no-variation {
    margin-top: auto;
  }

  .towishlist {
    @include wishlist;
    background: url("../images/icons/icon-wishlist-outline.svg") 0 0 / 18px 18px no-repeat;

    &:hover {
      background: url("../images/icons/icon-wishlist-fill.svg")  0 0 / 18px 18px no-repeat;
    }

    span {
      @extend %visually-hidden;
    }
  }

  .towishlist--logged-out {
    @include wishlist;
    background: url("../images/icons/icon-wishlist-outline.svg") 0 0 / 18px 18px no-repeat;

    &:hover {
      background: url("../images/icons/icon-wishlist-fill.svg")  0 0 / 18px 18px no-repeat;
    }

    span {
      @extend %visually-hidden;
    }
  }

  .offwishlist  {
    @include wishlist;
    background: url("../images/icons/icon-wishlist-fill.svg")  0 0 / 18px 18px no-repeat;
    display: none;

    span {
      @extend %visually-hidden;
    }
  }

  .price-box {
    // margin-top: auto; // not entirely clear where I should put this to pin certain things to the bottom. Too much variation between products and customer groups

    .configurable-price {

      .price-label {
        @extend %visually-hidden;
      }
    }

    .plp-from-label {
      @extend %visually-unhidden;
    }
  }

  .product-item-actions {
    margin-top: $size-m;

    form {
      padding: 0;
    }

    .tocart,
    .action--logged-out,
    .action--configurable {
      width: 100%;
      font-weight: $fontBolder;
    }

    .actions-primary--isTrained,
    .actions-primary--notTrained,
    .actions-primary--proLoggedOut {
      display: none;
    }
    .actions-secondary {
      .action, .btn, button {
        border: 0;
      }
    }
  }
}

// moving this outside the any containing "scope" as it will be needed on search results page too
.ias-spinner {
  width: 100%;
  text-align: center;

  img {
    width: $size-6xl;
    height: $size-6xl;
    display: block;
    margin: 0 auto $size-2xl;
  }

  span {
    @include fontSize(18px);
    line-height: $size-xl;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.ias-load-prev {
  // position: absolute;
  // left: 0;
  // top: -48px;
  width: 100%;
  margin-bottom: $size-m;
}

.ias-no-more {
  display: none !important;
}


.wishlist-index-index .product-items {
  .product-item:nth-child(3n) {
    @include breakpoint($desktop) {
      margin-right: 0;
    }
  }
}

.page-layout-catalog_category_view_pro {

  .price-wrapper.price-including-tax {
    display: none;
  }
}

.modal-sort-by .toolbar-for-modal li {
  color: black !important;
}
