//
// Components : table, sidebar, discount code box
// (find them within styles/components)
//

.checkout-cart-index {

    .page-wrapper {

    .page-title-wrapper {
      margin: 0 0 $size-2xl;

      @include breakpoint($desktop) {
        max-width: $innerMaxWidth;
        margin: $size-s auto $size-7xl;
      }

      .page-title {
        margin: 0;
        text-align: left;
        padding-left: $size-m;
        
        @include breakpoint($desktop) {
          padding-left: 0;
        }
      }

      h1 {
        @include fancyHeading;
        text-align: center;
      }
    }
  }

  .page-main {
    @include breakpoint($desktop) {
      padding: 0 $size-2xl;
    }

    .columns {
      @include breakpoint($desktop) {
        max-width: 1376px;
        margin: 0 auto;
      }

      .main {
        @include breakpoint($desktop) {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          > input,
          .cart-wrapper {
            display: none;
          }

          .cart-container {
            flex-basis: span(10);
          }

          .cart-sidebar {
            flex-basis: span(6);
            max-width: 418px;
          }
        }

        .cart-container .shopping-basket-items .price-including-tax::before {
            content: attr(data-label) ': ';
            font-size: 0.8rem;
        }
      }
    }
  }

  .ampromo-items-add {
    display: none;
    width: 100%;
    margin-bottom: $size-m;

    & > div {
      width: 100%;
      background-color: $grey-lighter;
      text-align: center;
      padding: $size-m;
    }

    h2 {

      &::before {
        display: block;
        margin: auto;
        width: 48px;
        height: 48px;
        content: "";
        background-image: url("../images/icons/icon-raising-hands.png");
        background-position: center;
        background-size: contain;
      }

      a {
        color: $black;
        font-size: 40px;

        &::after {
          display: block;
          margin: auto;
          margin-top: $size-m;
          content: "Choose it here";
          font-weight: bold;
          font-size: $size-m;
          line-height: $size-l;
          color: $black;
          padding: 11px $size-m;
          border: 2px solid $black;
          text-align: center;
          cursor: pointer;
          text-transform: uppercase;
          transition: all .4s ease-in-out;
          max-width: fit-content;
        }
          
        &:hover:after {
          background-color: $black;
          color: $white;
        }
      }
    }

    .btn {
      color: $black;
      &:hover {
        color: #fff;
      }
    }
  }

  .ampromo-overlay {
    position: fixed;
    top: -9999px;
    left: 0;
    z-index: 10000;
    height: 100%;
    width: 100%;
    overflow: auto;
    text-align: center;
    opacity: 0;
    transition: opacity 0.4s linear;
    background-color: #aaaa;
    
    &.-show {
      top: 0;
      opacity: 1;
    }
  }

  .ampromo-items-content {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    overflow: auto;
    margin: $size-m;
    padding: $size-m;
    width: 90vw;
    max-width: 1120px;
    text-align: left;
    background-color: #fff;
    @include breakpoint($desktop) {
      margin-top: 10vh;
    }

    & > h2 {
      font-size: 20px;
      @include breakpoint($desktop) {
        margin-bottom: $size-2xl;
      }
    }

    & > .btn {
      background-color: unset;
      color: $black;
    }
  }

  .ampromo-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0;
    padding: 0;
    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWwxMCAxMG0wLTEwTDEgMTEiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPjwvc3ZnPg==) no-repeat;
    background-size: contain;
    font-size: 0;
    cursor: pointer;
    opacity: .8;

    &:hover {
        opacity: 1;
        transition: opacity .2s ease;
    }
  }

  .ampromo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: $size-m;

    &::after {
      content: "";
      flex: 1 0 136px;
    }
  }

  .ampromo-item {
    flex: 1 0 136px;
    margin-bottom: $size-l;
    text-align: center;
  }

  .ampromo-items-form {
    padding: 0;
  }

  .ampromo-title {
    margin: $size-s 0;

    a {
      color: $black;
    }
  }

  .action.tocart {
    width: 100%;
    max-width: 160px;
  }

  .block.crosssell {

    ol.products.list.items.product-items {

      .product-item-info {
        justify-content: flex-start;
      }

    }

    .action.tocart {
      max-width: 100%;
    }

  }

}

.actions {
  .continue {
    background: none;
    color: $brand-primary;
    padding: $size-2xl $size-m 0;
    text-align: center;
    width: 100%;
    font-size: $size-s;
    letter-spacing: 1px;
    text-decoration: underline;
    font-weight: 100;

    &:visited {
      color: $brand-primary;
    }
  }
}


.shopping-basket-items {
  list-style: none;
  padding-left: 0;

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $size-m;
    border-top: 1px solid $border-light;
    position: relative;

    &:last-of-type {
      border-bottom: 1px solid $border-light;
    }
  }

  &__img {
    max-width: 64px;
  }

  &__details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-left: $size-m;
    @include breakpoint($desktop) {
      justify-content: space-between;
      flex-direction: row;
    }

    .product-item-name {
      color: $black;
      font-weight: bold;

      @include breakpoint($desktop) {
        font-size: 20px;
      }

      a {
        color: $black;
      }
    }

    .item-options {
      &__heading {
        font-size: $size-l;
        cursor: pointer;
        &.open {
          margin-bottom: 15px;
        }
        &.open .item-options__toggle-icon {
          transform: rotate(180deg);
        }
      }

      &__toggle-icon {
        content: "";
        width: 12px;
        height: 12px;
        display: inline-block;
        margin-left: 6px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../images/icons/icon-chevron-down-dark.svg");
      }

      &__content {
        display: none;
        strong {
          font-weight: $fontBolder;
        }
        > div {
          margin-bottom: 10px;
        }
      }
    }

    .price {
      font-weight: bold;
    }

    .old-price {
      text-decoration: line-through;
    }

    .special-price {
      color: red;
    }
  }

  &__price {
    display: flex;
    align-items: center;
  }

  &__qty {
    display: flex;
    align-items: center;
    margin-left: $size-m;


    span {
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 38px;
      border: 1px solid $black;
      cursor: pointer;
      font-weight: bold;
    }

    input {
      width: 48px;
      border-left: none;
      border-right: none;
      border-radius: 0;
      font-weight: bold;
      height: 40px;
    }

    label,
    input {
      margin: 0;
    }

    .qty {
      text-align: center;
    }
  }

  &__actions {
    .action-edit,
    .action-towishlist {
      display: none;
    }

    .action-delete {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 5px 5px 10px 5px;
      border: none;
      text-transform: none;
      font-size: $size-xl;
      font-weight: normal;
      line-height: 10px;

      &:hover {
        background: unset;
        color: $black !important;
      }
    }
  }
}

.cart-empty {
  padding: 0 $size-xl;
  margin-bottom: $size-xl;
}

.cart-sidebar {
  display: flex;
  flex-direction: column;

  .block.discount {
    order: 1;
  }

  .cart-summary {
    order: 2;
    margin-bottom: $size-m;

    .cart-totals .totals {

      .totals.sub,
      .totals-tax {
        display: none;
      }

      .grand.totals {

        .mark,
        .amount {
          border: unset;
          padding-top: 0;
        }
      }
    }
  }

  &__actions {
    order: 3;
    display: flex;
    justify-content: center;
    padding: 1rem;

    span {
      color: $black;
      font-size: $size-s;
      font-weight: bold;
      text-transform: uppercase;
      border-bottom: 3px solid $brand-primary;
    }
  }

  .block.discount .title strong {
    font-size: 20px;
  }

  .block.discount .actions-toolbar .action.apply.primary {
    padding-left: $size-2xl;
    padding-right: $size-2xl;
  }

  .cart-summary .checkout.methods .item .action.primary.checkout {
    background: unset;

    &:hover {
      background: $black;
    }
  }
}