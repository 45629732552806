.page-header {
  background-color: $header-background;
  color: $white;
  position: fixed;
  top: 0;
  z-index: 40; // to trump 30 on PLP Header Sign In button
  max-width: 100vw; // to prevent modals expanding and contracting the header during animated slide in and out

  @include breakpoint($desktop) {
    position: fixed;
  }

  .skip {
    @extend %visually-hidden;
  }
}

.nav-toggle {
  margin-right: 16px;
  cursor: pointer;

  @include breakpoint($desktop) {
    margin-right: 0;
  }

  &::before {
    @include icon-svg-reset;
    background: url(../images/icons/icon-menu.svg) 50% 50% no-repeat;
    background-size: cover;
    transition: 300ms background ease;

    .main-menu & {
      background: none;
    }
  }

  span {
    @extend %visually-hidden;
  }

  &.open {

    &::before {
      @include icon-svg-reset;
      content: "x";
      line-height: 0;
      font-size: 30px;
      font-weight: $fontBold;

      .header-mobile-toggles & {
        content: "";
      }
    }
  }
}

.header {

  &.content {
    display: flex;
    flex-flow: row wrap;
    padding: $size-m 0;
    align-items: center;

    @include breakpoint($desktop) {
      padding: 24px $size-m;
    }
    >div {
      flex: 1;
    }
  }

  .header-mobile-toggles {
    width: span(4);
    flex-basis: span(4);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @include breakpoint($desktop) {
      width: 0;
      flex-basis: 0;
    }
  }

  .search-toggle {
    transition: all 600ms ease;
    overflow: hidden;
    vertical-align: bottom;
    display: inline-block;

    &::before {
      @include icon-svg-reset;
      background: url(../images/icons/icon-search.svg) 50% 50% no-repeat;
      background-size: cover;
    }

    &:hover {
      cursor: pointer;
    }

    @include breakpoint($tablet) {
      margin-left: gutter();
    }

    span {
      @extend %visually-hidden;
    }
  }

  .header__logo-container {
    // width: span(8);
    // flex-basis: span(8);
    // margin: 0 gutter();
    text-align: center;
    height: $size-2xl;

    @include breakpoint($desktop) {
      // width: span(4 wider);
      // flex-basis: span(4 wider);
      // margin: 0 0 0 span(5);
      height: $size-6xl;
    }
  }

  .header__logo {
    max-width: 135px; // magic number from design

    @include breakpoint($desktop) {
      max-width: 260px;
    }
  }

  .country-swapper {
    @extend %u-desktop-only;

    @include breakpoint($desktop) {
      width: span(2 wide);
      flex-basis: span(2 wide);
      margin-left: auto;
      text-align: right;
    }

    &__icon {

      &:hover {
        cursor: pointer;
      }

      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-globe.svg) 50% 50% no-repeat;
        background-size: cover;
      }
  
      [aria-hidden="true"] {
        @extend %visually-hidden;
      }
    }

    &__current {
      @extend %visually-hidden;
      // text-transform: uppercase;
      // display: inline-block;
      // vertical-align: middle;
      // font-size: $size-s;
      // line-height: $size-l;
    }
  }

  .my-wishlist-link {

    @include breakpoint($desktop) {
      margin-left: 28px;
    }

    a {
      @include fontSize(14px);
      line-height: 18px;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 1px;

      &:hover {
        cursor: pointer;
        color: $white;
      }

      .my-wishlist-link__icon {
        @include icon-svg-reset;
        background: url(../images/icons/icon-wish-list.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      .label {
        @extend %visually-hidden;
      } 
    }
  }

  .header-account-bag-container {
    width: span(4);
    flex-basis: span(4);
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;

    @include breakpoint($desktop) {
      width: auto;
      flex-basis: auto;
      //margin-left: $size-2xl;
    }

    @include breakpoint($desktop-mid) {
      // width: calc(72px + (100% - 480px)/16*3); // having to use the compiled susy output so I can tweak it by a non-standard amount to match the break from the grid in the designs
      // flex-basis: calc(72px + (100% - 480px)/16*3);
      // margin-left: gutter();
      justify-content: flex-end;
    }

    >div {
      margin-left: 10px;

      @include breakpoint($tablet) {
        margin-left: 28px;
      }
    }
  }

  .my-account-link {

    @include breakpoint($desktop) {
      margin-left: 28px;
    }

    &:hover {
      cursor: pointer;
    }

    &__clickzone {
      display: none;

      .label {
        font-weight: $fontReg;
      }
      
    }

    &__icon {

      &::before {
        @include icon-svg-reset;
        background: url(../images/icons/icon-account.svg) 50% 50% no-repeat;
        background-size: cover;
      }
    }

    .label {
      @extend %visually-hidden;
    }
  }

  .minicart-wrapper {

    @include breakpoint($tablet) {
      margin-left: gutter();
    }

    @include breakpoint($desktop) {
      margin-left: 28px; // need to remove 4px of space to avoid wrapping
    }

    .showcart {
      text-decoration: none;
      color: $white;
      display: block;

      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: $white;
      }
      
      .basket-icon {
        @include icon-svg-reset;
        background: url(../images/icons/icon-basket-fill.svg) 50% 50% no-repeat;
        background-size: cover;

        &.empty {
          background: url(../images/icons/icon-basket.svg) 50% 50% no-repeat;
        }
      }
  
      .text {
        @extend %visually-hidden;
      }
    }
  }

  .block-search {
    display: none;
    background-color: $white;
    z-index: 9;
    position: absolute;
    top: 64px;
    left: 0;
    @include breakpoint($desktop) {
      top: 96px;
      width: 100%;
    }

    .block-title,
    .minisearch .label {
      @extend %visually-hidden;
    }

    .nested {
      display: none;
    }
  }
  &-usp {
		&__container {
			background: $usp-background;
			max-height: 40px;
			overflow: hidden;
      margin-left: -16px;
      margin-right: -16px;

      @include breakpoint($desktop) {
        margin: 0;
      }

      .main-menu & {
        position: fixed;
        bottom: 0;
        width: 100%;
        margin: 0;
        li {
          box-shadow: none;
        }
      }
		}

		div {
			padding: 0 !important; // overwrite inline style (parallax)
		}
		
		ul {
      color: $usp-color;
      font-weight: bold;
			list-style: none;
			text-align: center;
			padding: 8px 10px 10px 10px;
			font-size: 14px;
			max-width: 435px;
			margin: 0 auto;

      .header-usp-two {
        display: none;
      }

		}
    
		.slick {
			 &-prev,
			 &-next {
				 position: absolute;
				 top: calc(50% - 20px);
				 transform: none;
				 width: 40px;
				 height: 40px;
				 border-radius: 50%;
				 z-index: 9;
				 margin: 0 20px;
         display: none !important;
				 &::before {
					 content: '';
					 height: 16px;
					 width: 16px;
					 display: inline-block;
				 }
				 &:hover,
				 &:active,
				 &:focus {
					 cursor: pointer;
					 box-shadow: none;
					 border: none;
				 }
			 }
			 &-prev {
				 left: 0;
				 margin-right: 6px;
				 transform: rotate(90deg);
				//  &:before {
				// 	 background: url(../images/icons/icon-arrow-down.svg) no-repeat 50% 50% / contain;
				//  }
			 }
			 &-next {
				 right: 0;
				 margin-left: 6px;
				 transform: rotate(-90deg);
				//  &:before {
				// 	 background: url(../images/icons/icon-arrow-down.svg) no-repeat 50% 50% / contain;
				//  }
			 }
		}
	}
}
