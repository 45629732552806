.page-footer {
  margin-top: $size-7xl;
}

.footer {

  &.content {
    background-color: $black;
  }
  
  &__wrapper {
    background: $black;
    padding: $size-xl $size-m;
    color: $white;
    display: flex;
    flex-direction: column;

    @include breakpoint($desktop) {
      flex-direction: row;
      padding: $size-xl;
    }

    .footer--menu {
      padding-left: 0;
      padding-right: 0;

      @include breakpoint($desktop) {
        padding-left: $size-m;
        padding-right: $size-m;
      }

      @include breakpoint(1232px) { // the max width of the container plus two times 16px outer gutters
        padding-left: 0;
        padding-right: 0;
      }
      
      @include breakpoint($desktop) {
        display: flex;
        flex-flow: row nowrap;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 1px;
          background-color: $grey-lighter;
          position: absolute;
          left: 0;
        }
      }
    }
  }

  &__container--menu {
    flex-basis: span(7);
    @include breakpoint($desktop) {
      padding: 0 $size-m;
    }
  }

  &__awards {
    flex-basis: span(5);
    text-align: right;
    display: none;
    img {
      max-width: 137px;
    }
    
    @include breakpoint($desktop) {
      display: block;
    }
  }

  &__copyright {
    background-color: $white;
    color: $black;
    padding-top: $size-xs;
    padding-bottom: $size-xs;

    @include breakpoint($desktop) {
      padding-left: 0;
      padding-right: 0;
    }

    .social-icons {
      list-style: none outside;
      padding: 0;
      width: span(2 wider of 4);
      margin: 0 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;

      @include breakpoint($tablet) {
        width: span(6);
      }

      @include breakpoint($desktop) {
        width: span(4);
      }
    }

    .social-icons__icon {
      width: $size-2xl;
      height: $size-2xl;
      display: block;

      &--facebook {
        background: url(../images/icons/icon-facebook.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--twitter {
        background: url(../images/icons/icon-twitter.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--youtube {
        background: url(../images/icons/icon-youtube.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--instagram {
        background: url(../images/icons/icon-instagram.svg) 50% 50% no-repeat;
        background-size: contain;
      }

      &--pinterest {
        background: url(../images/icons/icon-pinterest.svg) 50% 50% no-repeat;
        background-size: contain;
      }
    }
  }

  &__award {

    @include breakpoint($desktop) {
      width: 66px; // magic number from the design
      flex-basis: 66px;
      margin-left: auto;
      height: 50%;
      margin-top: $size-2xl;
    }

  }

  .payment--methods {
    width: span(2 of 4);
    justify-content: space-around;
    padding-bottom: $size-xl;

    @include breakpoint($tablet) {
      width: span(6);
    }

    @include breakpoint($desktop) {
      width: span(3 wider);
    }
  }
}

.copyright-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $size-xl $size-m;

  @include breakpoint($desktop) {
    flex-direction: row;
    padding: $size-xl;
  }

  .payment-methods__container,
  .copyright {
    flex: 1;
  }

  .trustpilot-widget {
    max-width: 375px;
    margin-left: auto;
    margin-top: 20px;
    @include breakpoint(425px) {
      max-width: unset;
    }
    @include breakpoint($tablet) {
      margin-right: -13px;
      width: 420px;
    }
  }

  .copyright {
    font-size: $size-s;
    letter-spacing: 1px;
    line-height: $size-l;
    margin-bottom: $size-m;
    @include breakpoint($desktop) {
      margin-bottom: 0;
    }
  }
  .payment-methods {
    display: flex;
    justify-content: center;
    @include breakpoint($desktop) {
      justify-content: flex-end;
    }
    img {
      width: 46px;
      margin-left: 10px;
    }
  }
}

.footer-bottom-menu {
  padding: $size-xl $size-m;
  @include breakpoint($desktop) {
    padding: $size-xl;
  }
  &__container {
    background: $black;
    padding: 16px 0;
  }
  &__list {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    @include breakpoint($desktop) {
      justify-content: flex-start;
    }
    li {
      margin-right: 10px;
      a {
        color: $white;
      }
    }
  }
}