.showcart {
  padding: 0;
  position: relative;
  background-color: transparent;
  border: 0;

  &:hover {
    background: transparent;
  }

  .counter {
    position: absolute;
    right: 3px;
    bottom: 3px;
    color: $header-background;
    width: $size-l;
    height: $size-l;
    border-radius: 50%;
    padding-top: 6px;

    &.empty {
      display: none; //for now
    }

    .counter-number {
      @include fontSize(12px);
      line-height: $size-s;
      font-weight: $fontBold;
      vertical-align: top;
      margin-left: 1px;
    }

    .counter-label {
      display: none;
    }
  }
}

.modal-minicart {
  overflow: unset !important;

  .modal-header {
    background: transparent;

    .action-close {
      height: $size-s;
      background: unset;

      &:hover {
        color: $black;
      }
    }
  }

  .modal-title {
    font-size: 30px;
    font-weight: $fontBolder;
    text-transform: none;
    letter-spacing: unset;

    &:before {
      display: none;
    }
  }

  .modal-inner-wrap {
    padding: 0;
    height: calc(100% - 64px);
  }

  .modal-content,
  .block-minicart,
  #minicart-content-wrapper,
  .block-content {
    height: 100%;
  }

  .block-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .minicart-items-wrapper {
    overflow-y: scroll;
    height: unset !important;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.block-minicart {

  .block-title {
    display: none;

  }

  .block-content {

    #btn-minicart-close {
      display: none;
    }

    .subtitle.empty {
      @include fontSize(16px);
      line-height: 21px;
      color: $black;
      padding: $size-m;
    }

    .minicart__totals-and-ctas {
      background-color: $grey-lighter;
      padding: $size-xl;
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .count,
      .item {
        font-weight: normal;
      }

      .action.primary.checkout {
        font-weight: bold;

        &:hover {
        color: $white;
        background-color: $black;
        }
      }
    }

    .items-total {
      @include fontSize(16px);
      line-height: 20px;
      font-weight: $fontBolder;
      width: 50%;
      flex-basis: 50%;
      height: 20px;

      span {
        height: 20px;
      }
    }

    .subtotal {
      width: 50%;
      flex-basis: 50%;
      text-align: right;
      height: 20px;

      .label {
        display: inline-block;
        margin-bottom: 0;
        display: none
      }

      .amount {
        @include fontSize(14px);
        line-height: $size-l;
        font-weight: $fontBolder;
        display: inline-block;
        margin-left: $size-3xs;
      }
    }

    .actions {
      width: 100%;
      flex-basis: 100%;
      margin-top: $size-m;

      .action.checkout {
        font-size: $size-l;
        width: 100%;
        margin-bottom: $size-m;
        background-color: transparent;
      }

      .action.viewcart {
        @extend %link-style-button;
        background-color: transparent;
        padding: 0;
        width: 100%;
        font-weight: $fontReg;
      }
    }

    .update-cart-item {
      display: none !important; // neeed to override inline style that gets added after you interact with the qty input field
    }

    .minicart-items {
      list-style: outside none;
      padding: 0;

      .item {
        padding: $size-l $size-m;
        box-shadow: inset 0 1px 0 0 $grey-lighter;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        .product-item-photo {
          width: $size-7xl;
          flex-basis: $size-7xl;
          margin-right: $size-m;
          flex-shrink: 0;
        }

        .product-item-details {
          flex-grow: 1;
        }

        .product-item-name {
          @include fontSize(14px);
          line-height: $size-l;
          font-weight: $fontBolder;
          display: block;
          a {
            color: $black;
          }
        }

        .item-options__toggle-icon {
          content: "";
          width: 12px;
          height: 12px;
          display: inline-block;
          margin-left: 6px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url("../images/icons/icon-chevron-down-dark.svg");
        }

        .item-options__content {
          display: none;
          > span {
            display: block;
          }
        }

        .product.options {
          @include fontSize(14px);
          line-height: $size-l;
          margin-bottom: $size-2xs;
          .price {
            display: none;
          }
        }

        .price-container {
          @include fontSize(12px);
          line-height: $size-l;
          font-weight: $fontBolder;
          color: $black;

          .price-including-tax {
            font-weight: $fontBolder;
          }
        }

        .old-price {

          .price-container {

            .price-wrapper {
              text-decoration: line-through;
            }
          }

          &+ .price-container {
            color: red;
          }
        }

        .product.actions {
          width: 120px;
          flex-basis: 120px;
          margin-left: $size-m;
          margin-top: 0;
          display: flex;
          flex-flow: column nowrap;
          justify-content: space-between;
        }

        .qty {
          display: flex;
          flex-flow: row nowrap;
          border: 1px solid $grey-dark;

          .qty-click-event {
            width: $size-4xl;
            height: $size-4xl;
            text-align: center;
            line-height: 38px;

            &:hover {
              cursor: pointer;
            }

            &[direction="subtract"] {

              &::before {
                @include icon-svg-reset;
                width: 20px;
                height: 20px;
                background: url(../images/icons/icon-minus.svg) 50% 50% no-repeat;
                background-size: 20px 20px;
              }

              span {
                @extend %visually-hidden;
              }
            }

            &[direction="add"] {

              &::before {
                @include icon-svg-reset;
                width: 20px;
                height: 20px;
                background: url(../images/icons/icon-plus.svg) 50% 50% no-repeat;
                background-size: 20px 20px;
              }

              span {
                @extend %visually-hidden;
              }
            }
          }

          .qty-box {
            border: 0;
            outline: none;
            padding: 0;
            margin: 0;
            width: $size-4xl;
            height: $size-4xl;
            font-weight: bold;
            text-align: center;
            border-radius: 0;
            border-left: 1px solid $grey-dark;
            border-right: 1px solid $grey-dark;
          }
        }

        .action.edit,
        .action.delete {
          background-color: transparent;
          padding: 0;
          border: 0;
          font-weight: $fontBold;
          color: $black;
          font-size: 23px;
          text-transform: none;
          margin-left: 0;
          &:hover {
            background: transparent;
            color: lighten($black, 20%);
          }
          span {
            display: inline-block;
          }
        }

        .edit-and-remove {
          text-align: right;
          position: relative;
          top: -10px;
          right: 0;
        }
      }
    }
  }

  .minicart-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .3) url(../images/loader-1.gif) 50% 50% no-repeat;
    background-size: 30px;
    width: 100%;
    height: 100%;
  }
}
