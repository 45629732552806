#password-strength-meter-container {
  margin-bottom: $size-m;
  display: none;
  
  span {
    display: inline-block;
    padding: 2px 6px;
    border-radius: 2px;
    margin-left: $size-3xs;
    background-color: $error;
    color: $white;
  }
  
  &.password-weak {
    display: block;
  }
  
  &.password-medium {
    display: block;
    
    span {
      color: $black;
      background-color: $warning;
    }
  }
  
  &.password-strong {
    display: block;
    
    span {
      background-color: $brand-secondary;
    }
  }
  
  &.password-very-strong {
    display: block;
    
    span {
      background-color: #008000;
    }
  }
}

#password-error {
  @include fontSize(14px);
  line-height: $size-l;
  margin-bottom: $size-2xs;
  color: $black;
}

#password.mage-error {
  color: $black;
}
