.blog {
    background: #f7f7f7;
  }
  
  .masonry-container {
    width: 100%;
    margin-bottom: 3%;
  
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  
  .masonry {
    margin-bottom: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  
  .masonry__inner {
    box-sizing: border-box;
    break-inside: avoid;
    padding: 10px;
    margin-bottom: 30px;
    width: 33.333%;
  
    a:hover {
      text-decoration: none;
    }
  }
  
  .masonry__inner__content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  // - blog home and category page styling
  
  .amblog-index-index .column.main, .amblog-index-category .column.main {
    padding: 0 15px 50px 15px;
  }
  
  .block.widget.block-post-list.amblog-post-list {
    position: relative;
    margin-top: 0;
    margin-bottom: 60px;
  }
  
  .amblog-index-index .column.main .page-title, .amblog-index-category .column.main .page-title {
    margin-bottom: 20px;
  }
  
  .block.widget.block-post-list.amblog-post-list .page-summary {
    margin-bottom: 30px;
  }
  
  .amblog-index-index .breadcrumbs, .amblog-index-category .breadcrumbs, .amblog-index-index .block-title, .amblog-index-category .block-title {
    display: none;
  }
  
  .amblog-post-list .post-items {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .block.widget.block-post-list.amblog-post-list .block-content .post-item-info {
    position: relative;
  
    &:before {
      content: 'featured';
      position: absolute;
      left: 2px;
      color: #fff;
      background-color: #000;
      padding: 5px 16px 8px 16px;
    }
  
    img {
      margin-bottom: 10px;
    }
  }
  
  .amblog-post-list .post-item-details .post-item-name {
    display: block;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  
  .amblog-container-list .amblog-post-container .amblog-footer {
    padding-left: 0;
    padding-right: 0;
  }
  
  .amblog-post-list .post-item-details .amblog-more {
    text-align: center;
    height: auto;
    background-color: #000;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    border: 1px solid #000;
    padding: 13px 16px;
  
    a.amblog-read {
      display: flex;
      color: #fff !important;
      font-weight: 600;
  
      span {
        margin-left: 5px;
        margin-top: -1.5px;
      }
    }
  
    &:hover {
      text-decoration: none;
      background: #fff;
      border: 1px solid #000;
  
      a {
        color: #000 !important;
      }
    }
  }
  
  .column.main .amblog-container-list {
    max-width: none;
    margin-left: -1%;
  }
  
  .amblog-container-list .amblog-post-container {
    width: auto;
    float: none;
    padding: 0;
  
    .amblog-features {
      display: none;
    }
  
    .amblog-headline:hover {
      color: #e9cabd;
    }
  
    .amblog-more {
      text-align: center;
      height: auto;
      background-color: #000;
      text-transform: uppercase;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      border: 1px solid #000;
      padding: 13px 16px;
  
      .amblog-read {
        display: flex;
        color: #fff !important;
        font-weight: 600;
        background: transparent;
        padding: 0;
        margin: 0;
  
        span {
          margin-left: 5px;
          margin-top: -1.5px;
        }
      }
  
      &:hover {
        text-decoration: none;
        background: #fff;
        border: 1px solid #000;
  
        a {
          color: #000 !important;
        }
      }
    }
  }
  
  // Blog Post Page Styling
  
  .amblog-index-post {
    .page-wrapper {
      padding-top: 100px;
    }
  
    #maincontent {
      width: unset !important;
    }
  
    .amblog-post-container {
      float: none !important;
      display: flex;
      flex-direction: column;
      border: none;
      padding: 0 !important;
  
      .amblog-post-head-wrapper {
        display: flex;
        flex-direction: column;
      }
    }
  
    .amblog-wrapper {
      display: block !important;
      order: 1;
    }
  
    .amblog-post-container .amblog-post-head-wrapper {
      .col-right {
        order: 2;
        display: flex;
        flex-direction: column;
      }
  
      .page-title-wrapper {
        order: 3;
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  
  .amblog-index-post {
    .page-title-wrapper .page-title {
      padding-right: 0;
    }
  
    .amblog-post-container .amblog-content {
      h2, h3, p, div, span, blockquote, address, ul, ol, a, button {
        padding-left: 15px;
        padding-right: 15px;
      }
  
      p {
        a, span, &:has(img) {
          padding: 0;
        }
      }
    }
  }
  
  .amblog-post-container .amblog-content {
    p {
      margin-bottom: 20px;
    }
  
    font-weight: 400;
    padding: 0 !important;
  
    table td {
      position: relative;
      display: table-row;
    }
  }
  
  //stack table on mobile
  
  //@at-root position number images
  
  td:has(img.blog-number) {
    height: unset !important;
  }
  
  .amblog-post-container .amblog-content table td img.blog-number {
    position: absolute;
    top: 0;
    left: -70px;
    max-width: unset;
  }
  
  // Pagination
  
  .amblog-pager-container .amblog-link {
    background: #000 !important;
  
    &:hover {
      background: #e9cabd !important;
    }
  
    &.-disabled {
      background: #c4c4c4 !important;
    }
  }
  
  @media screen and (min-width: 768px) {
    .amblog-container-list {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-left: -1%;
  
      .amblog-post-container {
        width: 32.333%;
        margin-left: 1%;
      }
    }
  
    .amblog-post-container .amblog-title {
      min-height: 85px;
    }
  
    .amblog-grid-list {
      > .amblog-item .amblog-title, .slick-slide .amblog-item .amblog-title {
        min-height: 85px;
      }
    }
  
    .amblog-container-list .amblog-post-container .amblog-shot {
      display: none;
    }
  
    .block.widget.block-post-list.amblog-post-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 120px;
  
      .page-title-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 55%;
        padding-left: 4%;
        padding-right: 4%;
      }
    }
  
    .amblog-index-index .column.main .page-title, .amblog-index-category .column.main .page-title {
      width: 100%;
      max-width: 100%;
      font-size: 60px;
      text-align: left;
    }
  
    .amblog-post-container, .amblog-main-content .amblog-search {
      padding-bottom: 15px;
      margin-bottom: 40px;
    }
  
    .amblog-grid-list {
      > .amblog-item, .slick-slide .amblog-item {
        padding-bottom: 15px;
        margin-bottom: 40px;
      }
    }
  
    .amblog-container-list .amblog-post-container .amblog-footer {
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .amblog-post-list .post-item-details .amblog-more {
      width: fit-content;
    }
  
    .amblog-container-list .amblog-post-container .amblog-more {
      width: auto;
    }
  
    .amblog-index-post .amblog-post-container .amblog-content {
      h2, h3, p, div, span, blockquote, address, ul, ol, a, button {
        padding-left: 0;
        padding-right: 0;
      }
    }
  
    // unstack table for desktop
  
    .amblog-post-container .amblog-content table td {
      display: table-cell;
    }
  
    td:has(img.blog-number) {
      height: 235px !important;
    }
  
    .amblog-post-container .amblog-content table td img.blog-number {
      left: unset;
      right: -105px;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .amblog-index-post {
      .amblog-post-container .amblog-post-head-wrapper {
        flex-direction: row;
        margin-bottom: 85px;
  
        .amblog-wrapper {
          flex-basis: 40%;
        }
  
        .col-right {
          flex-basis: 50%;
          padding: 2% 5% 2% 5%;
        }
      }
  
      .page-title-wrapper .page-title {
        text-align: left;
      }
  
      .amblog-post-container .amblog-content {
        max-width: 768px;
        margin: 15px auto 0 auto;
      }
    }
  }
  
  .amblog-index-category {
    .amblog-category-view {
      display: none;
    }
  
    .page-title-wrapper {
      display: block;
    }
  
    h1.page-title {
      font-size: 40px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .amblog-index-category h1.page-title {
      font-size: 60px;
    }
  }
  
  // - blog category navigation
  
  .amblog-index-index {
    .sidebar.sidebar-main {
      display: block;
    }
  
    .column.main .amblog-aside-left {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      top: unset;
      z-index: unset;
      box-shadow: none;
      padding-top: 0;
      margin-bottom: 30px;
    }
  }
  
  .amblog-index-category .column.main .amblog-aside-left {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    top: unset;
    z-index: unset;
    box-shadow: none;
    padding-top: 0;
    margin-bottom: 30px;
  }
  
  .amblog-widget-container.-categories {
    border-bottom: 1px solid #e9e9e9;
  }
  
  .amblog-index-index .column.main .amblog-aside-left .amblog-close, .amblog-index-category .column.main .amblog-aside-left .amblog-close, .amblog-index-index .column.main .amblog-widget-container.-categories .amblog-title, .amblog-index-category .column.main .amblog-widget-container.-categories .amblog-title {
    display: none;
  }
  
  .amblog-index-index .column.main .amblog-widget-container.-categories .amblog-list, .amblog-index-category .column.main .amblog-widget-container.-categories .amblog-list {
    display: block !important;
  }
  
  .amblog-index-index .column.main .amblog-widget-container.-categories .amblog-list ul.amblog-categories, .amblog-index-category .column.main .amblog-widget-container.-categories .amblog-list ul.amblog-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 5px;
  }
  
  .amblog-index-index .column.main .amblog-widget-container.-categories .amblog-list ul.amblog-categories li, .amblog-index-category .column.main .amblog-widget-container.-categories .amblog-list ul.amblog-categories li {
    border: none;
    padding: 0 15px 15px 15px;
  }
  
  .amblog-index-index .column.main .amblog-widget-container.-categories .amblog-list ul.amblog-categories li a.active, .amblog-index-category .column.main .amblog-widget-container.-categories .amblog-list ul.amblog-categories li a.active {
    font-size: 18px;
    font-weight: 400;
    text-decoration: underline;
  }
  
  .amblog-element-block .amblog-categories.-level1 > .amblog-category > .amblog-link, .amblog-post-container .amblog-widget-container .amblog-categories.-level1 > .amblog-category > .amblog-link {
    font-size: 18px;
    font-weight: 400 !important;
  }
  
  .amblog-grid-list {
    > .amblog-item .amblog-widget-container .amblog-categories.-level1 > .amblog-category > .amblog-link, .slick-slide .amblog-item .amblog-widget-container .amblog-categories.-level1 > .amblog-category > .amblog-link {
      font-size: 18px;
      font-weight: 400 !important;
    }
  }
  
  .amblog-element-block {
    .amblog-headline:hover, .amblog-categories .amblog-link:hover {
      color: #000 !important;
      text-decoration: underline;
    }
  }
  
  .amblog-post-container .amblog-widget-container .amblog-headline:hover {
    color: #000 !important;
    text-decoration: underline;
  }
  
  .amblog-grid-list {
    > .amblog-item .amblog-widget-container .amblog-headline:hover, .slick-slide .amblog-item .amblog-widget-container .amblog-headline:hover {
      color: #000 !important;
      text-decoration: underline;
    }
  }
  
  .amblog-post-container .amblog-widget-container .amblog-categories .amblog-link:hover {
    color: #000 !important;
    text-decoration: underline;
  }
  
  .amblog-grid-list {
    > .amblog-item .amblog-widget-container .amblog-categories .amblog-link:hover, .slick-slide .amblog-item .amblog-widget-container .amblog-categories .amblog-link:hover {
      color: #000 !important;
      text-decoration: underline;
    }
  }
  
  .desktop-block.amblog-element-block {
    margin-bottom: 30px;
  }
  
  @media screen and (min-width: 768px) {
    .amblog-index-index .column.main .amblog-aside-left, .amblog-index-category .column.main .amblog-aside-left, .desktop-block.amblog-element-block {
      margin-bottom: 50px;
    }
  }
  
  @media screen and (max-width: 575px) {
    .masonry__inner {
      width: 100%;
    }
  }
  
  .masonry-container {
    .item-sizer {
      display: none;
      width: 33.333%;
      float: left;
      padding: 1%;
      height: 100%;
      height: auto;
    }
  
    .item {
      width: 33.333%;
      float: left;
      padding: 1%;
      height: 100%;
      height: auto;
    }
  }
  
  .item--25, .item--50 {
    width: 20%;
    padding: 0;
  }
  
  .item--75 {
    width: 20% !important;
    padding: 0;
  }
  
  .item__wrap {
    position: relative;
    -webkit-box-shadow: 10px 10px 0 0 #967d70;
    box-shadow: 10px 10px 0 0 #967d70;
    overflow: hidden;
    background: #2f3238;
  }
  
  .item__wrap__overlay {
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    padding: 2em;
  }
  
  .item__wrap:hover img {
    opacity: 0.4;
    -webkit-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1);
    transition: all 0.35s;
  }
  
  .item__wrap__overlay {
    p {
      display: inline-block;
      margin: 0 0 0.25em;
      padding: 0.4em 1em;
      background: rgba(255, 255, 255, 0.9);
      color: #2f3238;
      text-transform: none;
      font-weight: 500;
      font-size: 14px;
      -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
      transition: opacity 0.35s, transform 0.35s;
      -webkit-transform: translate3d(-500px, 0, 0);
      transform: translate3d(-500px, 0, 0);
    }
  
    h3 {
      color: #ffffff;
      text-transform: uppercase;
      font-weight: bold;
      font-family: "brandon-grotesque", sans-serif;
      letter-spacing: -.5px;
      font-weight: 600;
    }
  }
  
  .masonry-container .item img {
    display: block;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }
  
  .post__wrap {
    position: relative;
    padding: 2%;
    margin-bottom: 10px;
  }
  
  .post__wrap--beforeafter {
    position: relative;
    padding: 2%;
    margin-bottom: 10px;
    padding: 0;
    margin-bottom: 0;
  
    .post__content {
      p, a {
        color: #ffffff;
        margin-bottom: 0;
      }
    }
  }
  
  .post__wrap--beforeafter__category {
    position: absolute;
    z-index: 1;
    background: black;
    padding: 10px;
    color: #fff;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .post__wrap--beforeafter .post__content {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 0;
    background: #000;
    color: #fff;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .beforeafterInsta {
    font-size: 25px;
    padding: 6px;
  
    &:hover {
      text-decoration: none;
    }
  }
  
  .stylistName {
    font-weight: bold;
    text-transform: uppercase;
    font-family: "brandon-grotesque",sans-serif;
    color: #000;
    letter-spacing: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
  
  .post__wrap a:hover {
    text-decoration: none;
  }
  
  .post__content, .blog__post__alsoLike__post .post__content {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    position: relative;
    margin-top: -70px;
    background: #fff;
    padding: 20px;
  }
  
  .post__content--uncat {
    background: #20c596;
    position: absolute;
    top: 0;
    left: 0;
    text-transform: uppercase;
    padding: 10px;
    font-size: 14px;
    line-height: 29px;
    font-weight: bold;
  }
  
  .post__content--pro {
    background: #bb2f1c;
    position: absolute;
    top: 0;
    left: 0;
    text-transform: uppercase;
    padding: 10px;
    font-size: 14px;
    line-height: 29px;
    font-weight: bold;
    color: #fff;
  
    a {
      color: #fff;
    }
  }
  
  .post__content p, .blog__sticky__content p {
    font-size: 13px;
    letter-spacing: 1.5px;
    line-height: 16px;
  }
  
  .post__content .post__content__dateAuthor .post__content__dateAuthor__author p {
    text-transform: uppercase;
  }
  
  .post__content__cats {
    text-transform: uppercase !important;
    color: #3a3a3a;
    font-size: 10px;
    margin-bottom: 10px;
    letter-spacing: 1px;
  
    a {
      color: #3a3a3a;
    }
  }
  
  .post__content__social {
    padding: 10px;
    width: auto;
    display: inline-block;
  }
  
  .social__share--featured {
    width: 120px;
    margin-left: -15px;
  }
  
  .blog__flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .brow__fix__logo {
    width: 150px;
    float: left;
  }
  
  .blog__sticky__content h2 {
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 30px;
    position: relative;
    text-align: left;
    color: #000;
    line-height: 35px;
  }
  
  .post__content h3 {
    font-family: "brandon-grotesque",sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    text-align: center;
    color: #000;
    line-height: 22px;
  }
  
  .blog__sticky__content h3 {
    font-family: "brandon-grotesque",sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    position: relative;
    text-align: center;
    color: #000;
    line-height: 22px;
    text-align: left;
  }
  
  .post__content h3:after {
    width: 50%;
    height: 2px;
    background: #000;
    bottom: 0px;
    margin-bottom: 20px;
    content: "";
  }
  
  .post-categories {
    padding: 0;
    margin: 0;
    font-size: 12px;
    text-transform: uppercase;
  }
  
  .post__content__categories {
    position: relative;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 100;
    letter-spacing: -0.5px;
  
    &:after {
      content: "";
      clear: both;
      display: block;
    }
  
    a {
      display: block;
      padding: 0px 10px 10px;
      text-transform: uppercase;
    }
  }
  
  // Sticky Post Styling
  
  .blog__sticky {
    position: relative;
    background: #ffffff;
    -webkit-box-shadow: 20px 20px 0 0 #967d70;
    box-shadow: 20px 24px 0 0 #967d70;
    width: 97%;
    margin: 2.5% auto;
    margin-left: 13px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
  
  .blog__sticky__img {
    width: 50%;
    float: left;
  }
  
  .blog__sticky__content {
    width: 50%;
    float: left;
    padding: 3%;
  
    a {
      text-decoration: none;
  
      &:hover {
        text-decoration: none;
      }
    }
  }
  
  .post__content__avatar {
    width: 75px;
    height: 75px;
    margin: 0 auto;
    margin-top: -54px;
    margin-bottom: 20px;
  
    img {
      border-radius: 50px;
    }
  }
  
  // Meet Section
  
  .blog__meet {
    width: 100%;
    background: #ffffff;
    padding: 3%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
  
    h3 {
      font-size: 26px;
      line-height: 74px;
      padding: 0;
      margin-bottom: 0px;
      letter-spacing: 1px;
      z-index: 1;
      position: relative;
      font-family: "brandon-grotesque",sans-serif;
      font-weight: 600;
      color: #000;
      text-transform: uppercase;
      width: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column wrap;
      flex-flow: column wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
  
      &:after {
        width: 75px;
        height: 2px;
        background: #000;
        bottom: 0px;
        margin-bottom: 20px;
        content: "";
      }
    }
  }
  
  .blog__meet__blogger {
    width: 25%;
    font-size: 14px;
    text-align: center;
    padding: 60px;
    font-family: "brandon-grotesque",sans-serif;
    letter-spacing: 1.5px;
  
    img {
      border-radius: 50%;
    }
  }
  
  .blog__meet__blogger__name {
    width: 100%;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 20px;
    color: #000;
    text-align: center;
    padding: 20px 0 10px;
  }
  
  .blog__meet__blogger__name__jobTitle {
    font-weight: 100;
    font-size: 14px;
  }
  
  .blog__topics {
    width: 100%;
    background: #fff;
    color: #000;
  }
  
  #header-nav.blog__navigation {
    background: #ffffff !important;
  }
  
  #menu-blog-menu, #menu-blog-menu-1 {
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  #menu-blog-menu:after, #menu-blog-menu-1:after {
    content: "";
    clear: both;
    display: block;
  }
  
  #menu-blog-menu li, #menu-blog-menu-1 li {
    float: left;
  }
  
  #menu-blog-menu li a, #menu-blog-menu-1 li a {
    display: block;
    padding: 15px 12px;
    font-size: 14px;
    color: #000000 !important;
  
    // Options not showing in black
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
  }
  
  #menu-blog-menu li a:hover, #menu-blog-menu-1 li a:hover {
    text-decoration: underline;
  }
  
  #menu-blog-menu li.current-menu-item a, #menu-blog-menu-1 li.current-menu-item a {
    background: #000000;
    color: #ffffff !important;
    transition: all 1s;
  }
  
  .list-item__img {
    overflow: hidden;
    position: relative;
    background: #7e2222;
  
    img {
      transition: all 200ms;
      opacity: 1;
    }
  }
  
  .post__wrap:hover .list-item__img img {
    transform: scale(1.2);
    opacity: 0.5;
    transition: all 200ms;
  }
  
  .blog__pagination {
    width: 100%;
    color: #000000;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-align: center;
    padding: 20px;
    display: block;
    float: left;
    font-size: 25px;
    line-height: 30px;
  
    .page-numbers {
      padding: 20px;
  
      &.current {
        display: inline-block;
        background: #000000;
        color: #ffffff;
      }
    }
  }
  
  .page__header h1 {
    display: none;
  }
  
  // Single Blog Post (Standard) Styling
  
  .blog__post__alsoLike__post .post__content {
    margin-top: -40px;
    box-shadow: none;
  }
  
  .blog__post {
    width: 1000px;
    margin: 0 auto;
    background: #ffffff;
  }
  
  .blog__post__wrap {
    padding: 30px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    background: white;
    margin-top: -7%;
    padding-bottom: 0;
  
    ul li {
      list-style-type: disc;
    }
  }
  
  .blog__post__header {
    padding: 60px 0 40px;
  }
  
  .blog__post__header__author {
    border-top: 1px solid #000000;
    position: relative;
    margin-bottom: 20px;
  }
  
  .date-bar-white-bg {
    background: #fff;
    padding: 0 10px;
    position: relative;
    top: -0px;
    text-transform: uppercase !important;
    color: #3a3a3a;
    font-size: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 1.5px;
    display: block;
  }
  
  .blog__post__header h1 {
    text-align: center;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
  }
  
  .blog__header__picture {
    height: 400px;
    background-size: cover !important;
    background-position: center;
  
    img {
      opacity: 0.7;
    }
  }
  
  .blog__post__content {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    margin-top: 40px;
  }
  
  .blog__post__alsoLike {
    width: 100%;
    margin-top: 40px;
  }
  
  .blog__post__alsoLike__post {
    float: left;
    width: 33.333%;
    padding: 20px;
  }
  
  .social__share {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    text-transform: uppercase;
    width: 100%;
  
    p {
      margin: 0;
    }
  
    .post__content__social {
      font-size: 20px;
    }
  
    img {
      align-self: center;
    }
  }
  
  .sbi_bio, .sbi_header_img, .sbi_load {
    display: none;
  }
  
  #sb_instagram {
    .sb_instagram_header, #sbi_load {
      display: none;
    }
  
    #sbi_images {
      margin-bottom: 40px;
    }
  }
  
  .blog__post__alsoLike {
    .topTitle {
      width: auto;
      z-index: 1;
      position: relative;
      margin-bottom: 6px;
      color: #000;
      width: 100%;
      text-align: left;
      text-align: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column wrap;
      flex-flow: column wrap;
      font-size: 24px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      font-family: "brandon-grotesque",sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      color: #000000;
    }
  
    h3:after {
      content: "";
      border-bottom: 3px solid #000;
      width: 10%;
      margin: 0 auto;
      margin-top: 15px;
      margin-bottom: 29px;
    }
  }
  
  .blog__prevNext {
    width: 100%;
    margin: 20px auto;
  }
  
  .blog__prevNext__previous, .blog__prevNext__next {
    width: 50%;
    float: left;
    padding: 0 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 20px 0 40px;
  }
  
  .blog__prevNext__previous div {
    background: #000;
    padding: 20px;
    color: #fff;
    width: 100%;
  }
  
  .blog__prevNext__next {
    div {
      background: #000;
      padding: 20px;
      color: #fff;
      width: 100%;
    }
  
    text-align: right;
    justify-content: flex-end;
  }
  
  .blog__prevNext__previous p, .blog__prevNext__next p {
    font-family: "brandon-grotesque",sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 10px;
    text-transform: uppercase;
  }
  
  .blog__prevNext__previous .marginRight {
    margin-right: 20px;
  }
  
  .blog__prevNext__next .marginLeft {
    margin-left: 20px;
  }
  
  .blog__prevNext__previous .postTitle, .blog__prevNext__next .postTitle {
    font-size: 20px;
    text-align: center;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #ffffff;
  }
  
  .blog__prevNext__previous .postTitle {
    text-align: left;
  }
  
  .blog__prevNext__next .postTitle {
    text-align: right;
  }
  
  .blog__post__content h2 {
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    color: #000000;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
    margin-top: 50px;
  }
  
  .blog .blog__comments {
    font-size: 14px;
    font-family: "brandon-grotesque",sans-serif;
    letter-spacing: 1px;
  
    h3.comment-reply-title {
      font-family: "brandon-grotesque",sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      color: #000000;
    }
  
    .form-submit input {
      width: auto;
      background: #000;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      padding: 10px 20px;
      height: auto;
    }
  }
  
  .comment-reply-link {
    width: auto;
    background: #000;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 10px 20px;
    height: auto;
  }
  
  .blog .blog__comments {
    .comment-reply-link {
      display: inline-block;
    }
  
    .comment-form-comment {
      textarea, .comment-form-author, .comment-form-email, .comment-form-url {
        border: 1px solid #000000;
      }
    }
  
    .avatar {
      display: none;
    }
  }
  
  .page-header__desktop img {
    width: 100%;
    display: block;
  }
  
  .blog .blog__comments {
    .comment-list {
      padding: 0;
  
      li {
        padding: 20px 0;
        border-top: 1px solid #c8c8c8;
        border-bottom: 1px solid #c8c8c8;
  
        .children {
          margin-left: 0;
        }
      }
  
      .comment-metadata {
        margin-bottom: 20px;
      }
  
      .comment-meta .comment-author .says {
        display: none;
      }
    }
  
    .comments-area .comments-title {
      font-family: "brandon-grotesque",sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      color: #000;
      font-size: 20px;
      letter-spacing: -0.5px;
    }
  }
  
  a.blog__featured___post:hover {
    text-decoration: none;
  }
  
  // CATEGORY STYLING
  
  .blog__header, .proFix__header, .beautyInsider__header, .behindTheScenes__header, .everythingEyebrows__header, .browsBeforeAfters__header, .loveYourJob__header, .proFix__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .blog__header h1, .proFix__header h1, .beautyInsider__header h1, .behindTheScenes__header h1, .everythingEyebrows__header h1, .browsBeforeAfters__header h1, .loveYourJob__header h1, .proFix__header h1 {
    font-size: 31px;
    line-height: 40px;
    padding: 0;
    width: auto;
    letter-spacing: 1.5px;
    z-index: 1;
    position: relative;
    margin-bottom: 6px;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-grid-row-align: center;
    align-items: center;
  }
  
  .blog__hero {
    display: block;
  
    h1 {
      margin: 0;
      font-size: 45px;
      line-height: 70px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
  
  .blog__header {
    background: #000000;
    color: #ffffff;
  }
  
  .blog__hero__img {
    width: 100%;
  }
  
  .header__breadcrumbs {
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    letter-spacing: 0px;
    margin-bottom: 40px;
  
    a {
      text-decoration: underline;
    }
  }
  
  .proFix_header {
    background: #f6f6f6;
    color: #000000;
  }
  
  .follow_us_box {
    display: block;
    position: relative;
    float: right;
    padding: 24px 0;
    color: #fff;
    text-transform: uppercase;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    font-size: 17px;
  
    .sharebox {
      background-color: #000;
      display: none;
      right: 0;
      position: absolute;
      top: 100%;
      margin-top: 0px;
      text-align: center;
      white-space: nowrap;
      border-top: 1px solid #000;
      color: #ffffff;
      z-index: 9999;
    }
  
    &:hover .sharebox {
      display: block;
    }
  
    a {
      color: #ffffff;
    }
  
    .sharebox {
      padding: 10px;
  
      .sharebox__list {
        display: inline-block;
        position: relative;
        margin: 0 5px;
  
        .link {
          border-bottom: none;
          display: inline-block;
          text-decoration: none;
          border-radius: 50%;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          height: 40px;
          width: 40px;
          text-align: center;
        }
      }
    }
  }
  
  .blog__navigation {
    background: #ffffff;
    color: #000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
  }
  
  .facebook__icon, .twitter__icon, .instagram__icon, .youtube__icon {
    width: 40px;
    height: 40px;
    display: flex !important;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #000000;
    color: #ffffff;
    text-decoration: none;
    transition: all 500ms;
  }
  
  .facebook__icon, .twitter__icon, .instagram__icon, .youtube__icon {
    text-decoration: none;
  }
  
  .sharebox__list--newsletter {
    display: block !important;
    width: 100%;
    margin: 0 !important;
  
    .btn--white {
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 10px;
    }
  }
  
  .blog__menu__dark__mobile {
    background: #000000 !important;
  }
  
  #menu-blog-menu-mobile {
    margin: 0;
    padding: 0;
    margin-top: 50px;
  
    li a {
      text-transform: uppercase;
      color: #fff;
      width: 100%;
      padding: 12px 15px;
      display: block;
      text-decoration: none;
      border-bottom: 1px solid #fff;
      font-size: 12px;
      letter-spacing: 0;
      position: relative;
    }
  }
  
  // NEWSLETTER PAGE
  
  .newsletter__hero {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #000000;
    background-size: cover;
  }
  
  .return__link {
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    color: #ffffff !important;
  
    &:hover {
      text-decoration: underline;
    }
  }
  
  .blog__post__content__sections__img {
    float: left;
    width: 50%;
    padding: 20px;
  }
  
  .blog__post__content__sections__img--rightFloat {
    float: right;
  }
  
  .blog__post__content__sections__content {
    width: 50%;
    float: left;
    padding: 20px;
  }
  
  .blog__post__content__sections__content--100 {
    width: 100%;
  }
  
  .blog__post__content__sections__content--flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .instagram-media--wrapper {
    margin: 0 auto;
  }
  
  .blog__post__content__sections__content h2 {
    margin-top: 20px;
  }
  
  .blog__post__alsoLike__post__img img {
    width: 100%;
    height: auto;
  }
  
  .grid--marginBottom {
    margin-bottom: 50px;
  }
  
  .guest__author {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    font-size: 24px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: bold;
    text-align: center;
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    color: #000000;
  
    .guest__author__name, .guest__author__facebook, .guest__author__instagram {
      padding: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
  }
  
  @media all and (max-width: 1000px) {
    .blog__post {
      width: 100%;
      padding: 20px 5px 0;
    }
  }
  
  // NEWSLETTER STYLING
  
  .gdproptions__newsletter {
    .emailgdpr {
      display: block;
      position: relative;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 50%;
      float: left;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 20px 0;
    }
  
    input.email_gdpr_checkbox {
      position: absolute;
      opacity: 0;
      width: 10px;
    }
  
    .emailgdpr input.email_gdpr_checkbox {
      + label:before {
        display: inline-block;
        vertical-align: text-top;
        color: #000;
        font-size: 22px;
        opacity: 0;
        transition: all .3s ease-in;
        transform: scale(3) rotate(-20deg);
      }
  
      &:checked + label:before {
        opacity: 1;
        color: #000;
        -webkit-transform: scale(1) rotate(0deg);
        transform: scale(1) rotate(0deg);
        transition: all .3s ease-in;
      }
  
      + label {
        position: relative;
        cursor: pointer;
        padding: 0;
        background: #fff;
        border: 2px solid #000;
        width: 30px !important;
        height: 30px !important;
        margin-top: 0 !important;
        margin-right: 20px;
      }
    }
  }
  
  checkbox + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    background: #fff;
    border: 2px solid #000;
    width: 30px !important;
    height: 30px !important;
    margin-top: 0 !important;
    margin-right: 20px;
  }
  
  .gdproptions__newsletter .emailgdpr p {
    margin: 0;
  }
  
  .category__description {
    padding: 20px;
    width: 70%;
    padding-top: 10px;
    margin: 0 auto;
    text-align: center;
  }
  
  .category__description--beforeAfter {
    padding: 30px 0 50px;
    text-align: center;
  }
  
  .template--webform h1 {
    font-family: "brandon-grotesque",sans-serif;
    font-weight: 600;
    letter-spacing: 0.5px;
  }
  
  //WORDPRESS CORE ALIGNMENT CSS FOR IMAGE STRUCTURE
  // WordPress Core
  
  .alignnone {
    margin: 5px 20px 20px 0;
  }
  
  .aligncenter, div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
  }
  
  .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }
  
  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }
  
  a img {
    &.alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }
  
    &.alignnone {
      margin: 5px 20px 20px 0;
    }
  
    &.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }
  
    &.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
  
    // Image does not overflow the content area
    padding: 5px 3px 10px;
    text-align: center;
  
    &.alignnone, &.alignleft {
      margin: 5px 20px 20px 0;
    }
  
    &.alignright {
      margin: 5px 0 20px 20px;
    }
  
    img {
      border: 0 none;
      height: auto;
      margin: 0;
      max-width: 98.5%;
      padding: 0;
      width: auto;
    }
  
    p.wp-caption-text {
      font-size: 11px;
      line-height: 17px;
      margin: 0;
      padding: 0 4px 5px;
    }
  }
  
  // Text meant only for screen readers
  
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  
    // Many screen reader and browser combinations announce broken words as they would appear visually.
  
    &:focus {
      background-color: #eee;
      clip: auto !important;
      clip-path: none;
      color: #444;
      display: block;
      font-size: 1em;
      height: auto;
      left: 5px;
      line-height: normal;
      padding: 15px 23px 14px;
      text-decoration: none;
      top: 5px;
      width: auto;
      z-index: 100000;
  
      // Above WP toolbar
    }
  }
  
  .faqFullWidth .item-sizer {
    width: 100% !important;
  }
  
  .freqaq {
    padding: 2%;
  }
  
  @media all and (max-width: 580px) {
    .page-header__desktop h1, .masonry__inner {
      margin-bottom: 0;
    }
  
    .blog__post__header {
      padding: 0 0 10px;
    }
  
    .blog__post__wrap {
      padding: 20px;
      width: 95%;
      margin: 0 auto;
      position: relative;
      background: white;
      margin-top: -14%;
    }
  
    .blog__post__content__sections__content, .blog__post__content__sections__img {
      width: 100%;
    }
  
    .blog__post__content__sections__img--rightFloat {
      float: left;
    }
  
    .grid-sizer, .list-item {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
    }
  
    .blog__navigation {
      display: none;
    }
  
    .post__content, .blog__post__alsoLike__post .post__content {
      text-align: center;
      width: 95%;
      margin: 0 auto;
      position: relative;
      margin-top: -70px;
      background: #fff;
      padding: 10px;
    }
  
    .post__content h3 {
      font-size: 18px;
      font-weight: 600;
    }
  
    .blog__header p, .beautyInsider__header p, .behindTheScenes__header p, .everythingEyebrows__header p, .browsBeforeAfters__header p, .loveYourJob__header p, .proFix__header p {
      font-size: 14px;
      letter-spacing: 1.5px;
    }
  
    .blog__header h1, .proFix__header h1, .beautyInsider__header h1, .behindTheScenes__header h1, .everythingEyebrows__header h1, .browsBeforeAfters__header h1, .loveYourJob__header h1, .proFix__header h1 {
      font-size: 25px;
      line-height: 30px;
    }
  
    .blog__header h1:after, .proFix__header h1:after, .beautyInsider__header h1:after, .behindTheScenes__header h1:after, .everythingEyebrows__header h1:after, .browsBeforeAfters__header h1:after, .loveYourJob__header h1:after, .proFix__header h1:after {
      width: 20%;
    }
  
    .blog__pagination {
      padding: 10px;
  
      .page-numbers {
        padding: 14px;
      }
  
      font-size: 15px;
      line-height: 20px;
    }
  
    .blog__header__picture {
      height: 200px;
    }
  
    .date-bar-white-bg {
      width: 90%;
    }
  
    .blog__post {
      width: 100%;
      margin: 0 auto;
      padding: 40px 20px 0;
      background: #ffffff;
      margin-top: 0;
    }
  
    .blog__post__left {
      width: 100%;
    }
  
    .blog__hero h1 {
      margin: 0;
      font-size: 35px;
      line-height: 50px;
      margin-bottom: 20px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
    }
  
    .blog__sticky {
      margin: 0;
      padding: 2%;
      width: 100%;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  
    .blog__sticky__img {
      width: 100%;
    }
  
    .blog__sticky__content {
      width: 95%;
      float: left;
      padding: 3%;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: center;
      margin-top: -20px;
      position: relative;
      z-index: 2;
      background: #ffffff;
  
      h2 {
        text-align: center;
        font-weight: 800;
      }
  
      h3 {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }
  
    .blog__featured___post .btn {
      display: block;
    }
  
    .blog__sticky__content p {
      text-align: center;
    }
  
    .blog__post__content {
      margin-top: 10px;
    }
  
    .blog__post__right {
      width: 100%;
      padding: 0;
    }
  
    .blog__prevNext__previous, .blog__prevNext__next {
      width: 100%;
      margin: 10px 0;
    }
  
    .blog__prevNext__previous .postTitle, .blog__prevNext__next .postTitle {
      font-size: 17px;
    }
  
    .blog__post__alsoLike .topTitle {
      font-size: 25px;
      line-height: 30px;
    }
  
    .blog__post__alsoLike__post {
      width: 100%;
      padding: 10px;
    }
  
    .blog .blog__comments {
      margin-top: 20px;
    }
  
    .blog__meet__blogger {
      width: 100%;
      padding: 15px;
  
      img {
        width: 50%;
      }
    }
  
    .masonry-container {
      .item-sizer, .item, .item-sizer--50, .item--50 {
        width: 100% !important;
        float: left;
      }
    }
  
    .category__description {
      padding: 20px;
      width: 100%;
      padding-top: 20px;
  
      p {
        margin-bottom: 0;
      }
    }
  }
  
  .blog__post__content {
    .grid-item, .grid-sizer {
      display: initial;
      width: 50%;
    }
  
    .grid-item {
      padding: 40px;
    }
  }
  
  .grid-item__content {
    padding: 20px;
    position: relative;
  
    h3 {
      position: relative;
      z-index: 500;
    }
  
    h4 {
      position: relative;
    }
  }
  
  .point-1 .grid-item__content p, .point-2 .grid-item__content p, .point-3 .grid-item__content p, .point-4 .grid-item__content p, .point-5 .grid-item__content p, .point-6 .grid-item__content p, .point-7 .grid-item__content p, .point-8 .grid-item__content p, .point-9 .grid-item__content p, .point-10 .grid-item__content p {
    position: relative;
    z-index: 1;
  }
  
  .masonry-container {
    .grid-item__content {
      .number-1, .number-2, .number-3, .number-4, .number-5 {
        position: absolute;
        z-index: 0;
        width: 200px;
        top: -8px;
        left: -96px;
      }
    }
  
    .faqNum0 .number-1, .faqNum1 .number-2, .faqNum2 .number-3, .faqNum3 .number-4, .faqNum4 .number-5 {
      position: absolute;
      z-index: 0;
      width: 200px;
      top: -8px;
      left: -96px;
    }
  }
  
  .faqContent {
    position: relative;
    z-index: 9999;
  }
  
  @media all and (max-width: 575px) {
    .blog__post__content {
      .grid-item, .grid-sizer {
        width: 100%;
        padding: 10px;
      }
    }
  
    .blog__prevNext__previous, .blog__prevNext__next {
      padding-right: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-flow: column wrap;
      flex-flow: column wrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      background: #000000;
      color: #ffffff;
      padding: 20px;
      margin: 0;
      margin-bottom: 10px;
    }
  
    .blog__prevNext__previous .postTitle, .blog__prevNext__next .postTitle {
      color: #ffffff;
      text-align: center;
    }
  
    .blog__prevNext__previous p, .blog__prevNext__next p {
      text-align: center;
    }
  
    .blog__prevNext {
      border-bottom: 0px solid #000000;
    }
  
    .blog__prevNext__next {
      margin-bottom: 30px;
    }
  
    .blog__post__content__sections__img {
      padding: 0;
    }
  
    .blog__post__wrap {
      width: 100%;
      padding: 20px 10px;
    }
  
    .blog__post__content__sections__content {
      padding: 0;
    }
  
    .blog__post__alsoLike__post {
      margin-bottom: 30px;
    }
  
    .grid-item__content {
      .number-10 {
        left: -57px;
      }
  
      padding: 20px 0px 10px;
    }
  
    .grid--marginBottom {
      margin-bottom: 0;
    }
  }
  
  @media screen and (min-width: 575px) {
    .blog .blog__comments .comment-list li .children {
      margin-top: 20px;
    }
  }
  