//
// Found on Cart, Checkout
//

.cart-summary,
.opc-sidebar {
  background: $grey-lighter;
  padding: $size-2xl;

  .title {
    color: $black;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    position: relative;
    width: 100%;
    display: block;
    transition: all .6s;
    padding-bottom: $size-m;
    border-bottom: 1px solid $grey-dark;
  }

  #subtotal {
    display: inline-block;
    float: right;
    height: $size-l;
    color: $brand-primary;
    font-size: $size-s;
    font-weight: $fontBold;
    line-height: $size-l;
    padding-right: $size-2xl;

    &:last-of-type {
      display: none;
    }

    .price {
      font-weight: $fontReg;
      margin-left: $size-2xs;
    }
  }

  .cart-totals,
  .opc-block-summary {

    table {
      width: 100%;
      border-spacing: 0;

      tr th,
      tr td {
        padding-bottom: $size-2xs;
        margin-bottom: 0;
      }
    }

    .mark,
    .amount {
      color: #141418;
      font-size: 15px;
      letter-spacing: unset;
      font-weight: 400;
    }

    .mark {
      font-weight: $fontBold;
      text-transform: none;
      text-align: left;

      @include breakpoint($desktop) {
        font-weight: $fontReg;
      }

      .label {
        font-size: 16px;
        letter-spacing: unset;
        text-transform: unset;
        color: #141418;
        line-height: 1.3;
      }
    }

    .amount {
      text-align: right;

      strong {
        font-weight: $fontBold;
      }
    }

    .totals-tax {
      line-height: $size-xl;
      padding-bottom: $size-m;
    }

    .grand.totals {
      .total-items-count{
        display: none;
      }

      .mark,
      .amount {
        border-top: 1px solid #000;
        padding-top: $size-m;
        font-size: 20px;
        line-height: 20px;
        color: $black;

        strong {
          font-size: 16px;
          font-weight: 550;
          text-transform: uppercase;
        }
      }
    }
  }

  .checkout.methods {
    margin: 0;
    padding: 0;

    .item {
      list-style: none;

      .primary {
        width: 100%;
        margin-top: $size-xl;
      }
    }
  }
}

//
// accordion summary / seen on cart
//

.cart-container {
  .cart-summary {

    .title {
      &::after {
        content: "";
        background: url("../images/icons/icon-chevron-down-dark.svg");
        position: absolute;
        right: 0;
        top: -3px;
        height: $size-xl;
        width: $size-xl;
        transition: all .6s;
      }

      &.active {
        &::after {
          transform: rotate(180deg);
        }
      }
    }

    .cart-totals {
      display: none;
    }

    &.open {
      .title {
        &::after {
          display: none;
        }
      }

      .cart-totals {
        display: block;
      }
    }
  }
}

.cart-summary,
.opc-block-summary {
  
  .block.shipping {
    display: none;
  }

  .totals {

    th {
      padding-bottom: 8px;
    }

    .title {
      border-bottom: unset;
      font-weight: unset;
      padding-bottom: unset;
    }
  }

  .totals.shipping {

    .label {
      margin-bottom: 0;
      font-weight: 550;
    }

    .value {
      display: none;
    }
  }

  .totals-tax {

    .amount {
      vertical-align: top;
    }
  }
}

.opc-block-summary {
  display: flex;
  flex-direction: column;

  .title {
    order: 0;
  }

  .items-in-cart {
    order: 1;

    &:focus {
      outline: none;
    }

    ol {
      padding: 0;
    }

    li {
      list-style: none;
      letter-spacing: unset;
      // padding: $size-m 0;
      // border-bottom: 1px solid $border-light;
    }

    .product {
      .product-image-container {
        display: none;
      }

      .product-item-details {
        .details-qty {
          display: none;
        }
      }
    }
  }

  .table-totals {
    order: 2;
    margin-top: $size-m;

    .total-rules {
      display: none;
    }
    
    tr th,
    tr td {
      padding-bottom:9px;
    }

    tr {
      &:last-of-type {
        th,
        td {
          padding-bottom: 0;
        }
      }
    }

    .totals.discount {      
      .title.-enabled:after{
        width: 10px;
        height: 10px;
        background: none;
      }
      .price{
        white-space: pre;
      }
    }    
  }

  .subtotal-incvat-title{
    font-size: 15px;
    font-weight: 550;
  }
  
  .subtotal-exvat-title::after{
    content: 'ex VAT';
    font-size: 15px;
    font-weight: 550;
  }

  .total-incvat-title::after{
    content: '\AInc. VAT';
    font-size: 14px;
    font-weight: normal;
    white-space: pre;
    text-transform: none;
    color: #5B5A5D;
  }
}

.cart-summary .cart-totals .grand.totals .mark strong {
  font-size: 20px;
  line-height: 20px;
}

.payment--methods {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: $size-xl 0 0; //small mobiles

  //larger mobiles
  @include breakpoint($smallMobile) {
    padding: $size-xl $size-xl 0;
  }

  //tablet
  @include breakpoint($mobile) {
    width: 45%;
    padding: $size-xl 0 0;
  }

  @include breakpoint($desktop) {
    width: 100%;
    padding: $size-xl 25px 0;
  }
}
