.price-box,
.training-product-custom-price {
  @include fontSize(20px);
  line-height: 22px;
  letter-spacing: 0;
  display: flex;

  .price-label {
    @extend %visually-hidden;
  }

  .old-price {
    text-decoration: line-through;
    margin-right: $size-xs;
    order: 1;
    color: $black;
  }

  .price-final_price {
    order: 2;
  }

  .special-price,
  .special-price-configurable,
  .minimal-price-link {
    color: #CB0000;
    order: 2;
  }

  .special-bundle {
    display: flex;
    >.price-final_price {
      color: #CB0000;
    }
  }

  .plp-from-label {
    @extend %visually-hidden;
  }
}

.pro-price {
  &--isTrained {
    display: none;
  }
}
