.footer-menu {
  box-shadow: inset 0 1px 0 0 $grey-lighter;
  padding-top: $size-2xl;

  @include breakpoint($desktop) {
    box-shadow: none;
  }

  &__list {
    list-style: none outside;
    padding-left: 0;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;


    a {
      @include fontSize(16px);
      color: $white;
      line-height: $size-l;
      display: block;
      position: relative;
      margin-bottom: $size-m;
      &:hover {
        color: $brand-primary;
        text-decoration: none;
      }
    }
  }

  &__item {
    flex-basis: span(6 of 12);
    margin-right: gutter();
  }

  &__inner-list--level1 {
    list-style: none outside;
    padding-left: 0;
  }

  &__inner-item {
    a {
      font-weight: $fontReg;
      margin-bottom: $size-xs;
    }
  }

  .inner-list-header {
    display: none;
  }

  &__icon {
    display: block;
    position: absolute;
    right: 20px;
    top: $size-xs;
    height: $size-2xl;
    width: $size-2xl;
    background: url(../images/icons/icon-chevron-right.svg) 50% 50% no-repeat;
    background-size: 20px;

    @include breakpoint($desktop) {
      display: none;
    }
  }

  &__item--parent.show-footer-menu {

    .footer-menu__icon {
      background: url(../images/icons/icon-chevron-down-dark.svg) 50% 50% no-repeat;
      background-size: 20px;
    }
  }
}


.block-minicart strong.subtitle {
    display: none !important;
}

.amlabel-text {
    position: static !important;
}

.fotorama__zoom-in.zoom-in-loaded {
    display: none;
}
.fotorama__zoom-out.zoom-out-loaded {
    display: none;
}
