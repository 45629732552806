@font-face {
  font-family: "brandon-grotesque";
  src: url("../fonts/Brandon_reg.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "brandon-grotesque";
  src: url("../fonts/Brandon_bld.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "brandon-grotesque";
  src: url("../fonts/Brandon_blk.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "brandon-grotesque";
  src: url("../fonts/Brandon_light.woff") format("woff");
  font-weight: 300;
}

:root {
  font-size: 16px;
}

body {
  @include fontSize(16px);
  font-family: $fontBody;
  font-weight: $fontReg;
  font-style: normal;
  color: $black;
}

a,
a:visited,
a:focus,
a:active {
  color: $brand-primary;
  text-decoration: none;
  outline: none;
  
  &:hover {
    color: $brand-primary;
    text-decoration: none;
  }
  
  &.action {
    color: $black;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  
}

p,
ul,
ol {
  margin: 0 0 $size-xl;
  
  &:last-child {
    margin-bottom: 0;
  }
}

p {
  line-height: 20px;
  
  a,
  a:visited,
  a:focus,
  a:active {
    color: $black;
    text-decoration: underline;
    
    &:hover {
      color: $black;
      opacity: .8;
    }
  }
}

[data-content-type="text"] {
  
  p {
    margin-bottom: $size-xl;
  }
  
  &:last-child {
    
    p:last-child {
      margin-bottom: 0;
    }
  }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
//   color: $brand-primary;
//   font-weight: $fontBold;
//   text-transform: uppercase;
//   letter-spacing: 1px;
// }

// %fancyHeading {
//   font-family: $fontHeading;
//   font-weight: $fontReg;
//   text-transform: none;
//   letter-spacing: normal;
// }

small {
  @include fontSize(14px);
  line-height: 20px;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  line-height: 0;
}

[data-content-type="divider"] {
  padding: 0 !important;
}

hr {
  border: 0 !important; // PB override
  height: 1px;
  line-height: 1px;
  background-color: $grey-lighter;
  margin: 0 0 $size-xl 0 !important; // PB override
}

address {
  color: $black;
  font-size: $size-m;	
  font-weight: 300;
  line-height: $size-xl;
  font-style: normal;
}