.catalog-product-view {
  .product--wrapper__content {

    .product--tab {
      border-bottom: 1px solid #ededed;

      @include breakpoint($desktop) {
        border-top: 2px solid #ededed;
        border-bottom: 0;
      }

      &:first-of-type {
        border-top: 1px solid #ededed;
        margin-top: 32px;

        @include breakpoint($desktop) {
          border-top: 2px solid #ededed;
          margin-top: 131.5px;
        }
      }

      @include breakpoint($desktop) {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
      }

      @include breakpoint($desktop) {
        margin: 0 -312px;
        padding: 132px 312px;
      }

      .title {
        font-size: 18px;
        line-height: 22px;
        color: $black;
        margin: 0;
        padding: 16px 24px;
        position: relative;
        transition: background .6s ease;
        display: block;
        font-weight: $fontBolder;

        &.mobile-only {
          @include breakpoint($desktop) {
            display: none;
          }
        }

        @include breakpoint($desktop) {
          font-size: 38px;
          line-height: 48px;
          margin: 0 0 58px 0;
          padding: 0;
        }

        .accordion {
          position: absolute;
          top: 30px;
          right: 24px;

          @include breakpoint($desktop) {
            display: none;
          }

          .vertical {
            position: absolute;
            background-color: black;
            width: 3px;
            height: 15px;
            margin-left: -9.5px;
            margin-top: -9px;
            transition: all .5s ease-in-out;
            transform: rotate(-90deg);
          }

          .horizontal {
            position: absolute;
            background-color: black;
            width: 15px;
            height: 3px;
            margin-left: -15px;
            margin-top: -3.5px;
            transition: all .5s ease-in-out;
            transform: rotate(-90deg);
            opacity: 1;
          }
        }

        &.opened {
          background: $highlightMsg;

          .accordion {
            .vertical {
              transition: all 0.5s ease-in-out;
              transform: rotate(90deg);
            }
            .horizontal {
              transition: all 0.5s ease-in-out;
              transform: rotate(90deg);
              opacity: 0;
            }
          }

          + .product--tab__video {

            @include breakpoint(max-width $desktop) {
              display: block;
            }

            .content.reverse {
              @include breakpoint(max-width $desktop) {
                display: flex;
                flex-direction: column-reverse;
              }
            }
          }

          + div[class*="product--tab__"] .content {
            @include breakpoint(max-width $desktop) {
              display: block;
            }
          }
        }
      }

      .content {
        @include breakpoint(max-width $desktop) {
          display: none;
          padding: 32px 24px;
        }

        @include breakpoint($desktop) {
          display: flex;
          flex-wrap: wrap;
        }

        &.reverse {
          @include breakpoint($desktop) {
            justify-content: space-between;
          }
        }
      }

      .left-column {
        @include breakpoint($desktop) {
          &:not(:only-child) {
            flex-basis: span(10);
          }
        }
      }

      .right-column {
        @include breakpoint($desktop) {
          flex-basis: span(6);
        }
      }

      p {
        font-size: 16px;
        line-height: 22px;
        color: $black;
      }

      .instructions {

        .entry {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 24px;

          @include breakpoint($desktop) {
            flex-direction: row;
            align-items: center;
            margin-bottom: 46px;
          }

          &:last-of-type {
            margin-bottom: 0;
          }

          p {
            display: inline-block;
            padding-left: 16px;

            @include breakpoint($desktop) {
              padding-left: 32px;
            }
          }
        }

        .number {
          height: 46px;
          width: auto;
        }
      }

      &__video {
        width: 100%;

        .left-column {

          @include breakpoint(max-width $desktop) {
            margin-top: 24px;
          }

          .title {
            @include breakpoint(max-width $desktop) {
              display: none;
            }
          }

          .product--highlight {
            margin-top: 24px;
            padding: 24px 14px;

            h3 {
              margin-top: 0;
              margin-bottom: 16px;
              color: $black;
            }

            p {
              margin-bottom: 8px;

              &:last-of-type {
                margin-bottom: 0;
                font-size: 14px;
              }
            }
          }
        }
      }

      &__book {
        width: 100%;

        .book {
          padding-bottom: 80px;
          @include breakpoint($desktop) {
            display: flex;
            flex-wrap: wrap;
            padding-bottom: 0;
          }
        }

        .lookbook-img {
          width: 281px;
          height: 281px;
          margin: 0 auto;

          @include breakpoint($desktop) {
            width: 289px;
            height: 289px;
          }
        }

        .title {
          width: 100%;
        }

        h3 {
          font-size: 18px;
          color: $black;

          @include breakpoint($desktop) {
            font-size: 16px;
          }
        }

        .left-column {
          .title {
            @include breakpoint(max-width $desktop) {
              display: none;
            }
          }

          p {
            margin: 0;
          }
        }

        .entry {
          @include breakpoint($desktop) {
            flex-basis: span(4);
            margin-right: span(2);

            &:last-of-type {
              margin-right: 0;
            }
          }

          h3 {
            margin-top: 25px;
            margin-bottom: 24px;
            line-height: 22px;

            @include breakpoint($desktop) {
              margin-top: 44px;
              font-size: 18px;
            }
          }

          p {
            margin: 0;
            font-weight: 400;
          }
        }

        .slick-track {
          overflow: hidden;
        }

        .slick-dots {
          bottom: 20px;
          left: 0;

          li button {
            height: 16px;
            width: 16px;
            border-radius: 50%;
            background: #fff;
            border: 1px solid #000;
            position: static;
            font-size: 14px;
            line-height: 18px;

            &::before {
              display: none;
            }
          }

          li.slick-active button {
            background: #e9cabd;
          }
        }

        .slick-dotted.slick-slider {
          margin-bottom: 0;
        }
        ul {
          padding-left: 12px;
          margin-left: 8px;
          @include breakpoint($desktop) {
            margin-left: 0;
          }
        }
        li::marker {
          font-size: 12px;
        }
      }

      .video {
        iframe {
          @include breakpoint(max-width $desktop) {
            width: 100%;
          }
        }
      }

      &__ingredients {

        .content {

          .entry {
            margin-bottom: 32px;

            @include breakpoint($desktop) {
              margin-bottom: 0;
              flex-basis: span(6);
              margin-right: span(2);

              &:last-of-type {
                margin-right: 0;
              }
            }
          }

          img {
            height: 32px;
            width: 32px;

            @include breakpoint($desktop) {
              height: 64px;
              width: 64px;
            }
          }

          h3 {
            font-size: 16px;
            line-height: 22px;
            color: $black;
            margin: 8px 0 12px;

            @include breakpoint($desktop) {
              margin: 28px 0 6px;
            }
          }

          p {
            font-size: 16px;
            color: $black;
            display: block;
            width: 100%;
          }
        }

        .modal-trigger {
          margin-top: 28px;

          span {
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      &__reviews {
        width: 100%;
        .content {
          @include breakpoint($pdp-desktop) {
            display: block;
          }
        }
      }

      ul {
        padding: 0 0 0 16px;

        @include breakpoint($desktop) {
          padding: 0 0 0 24px;
        }

        li {
          color: $black;
          font-size: 16px;
          line-height: 22px;
        }
      }

      &__kits {
        width: 100%;

        .title {
          @include breakpoint(max-width $desktop) {
            display: none;
          }

          @include breakpoint($desktop) {
            width: 100%;
          }
        }

        .kit-product {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          @include breakpoint(max-width $desktop) {
            margin-left: -24px;
            margin-right: -24px;
            padding-left: 24px;
            padding-right: 24px;
          }

          @include breakpoint($desktop) {
            flex-direction: column;
            width: 100%;
            max-width: 290px;
            margin-right: 126px;
          }

          &:nth-child(3n - 2) {
            margin-right: 0;
          }

          &:nth-child(n+5) {
            @include breakpoint($desktop) {
              margin-top: 32px;
            }
          }

          &:not(:first-of-type) {
            @include breakpoint(max-width $desktop) {
              margin-top: 30px;
              padding-top: 30px;
              border-top: 1px solid #ededed;
            }
          }

          &-media {
            width: 100%;
            max-width: 128px;

            @include breakpoint($desktop) {
              max-width: 100%;
            }
          }

          &-details {
            padding: 16px 0;
            width: 57%;

            @include breakpoint($desktop) {
              width: 100%;
              padding: 22px 0 0 0;
            }
          }

          &-name {
            color: $black;
            font-size: 18px;
            font-weight: $fontBolder;
            line-height: 20px;
            margin: 0;

            @include breakpoint($desktop) {
              font-size: 30px;
              line-height: 30.8px;
            }
          }

          p {
            font-size: 14px;
            line-height: 20px;
            margin: 0;
            width: 100%;

            @include breakpoint($desktop) {
              font-size: 22px;
              line-height: 30.8px;
            }

            &.price {
              margin-bottom: 16px;
              &.special {
                color: #CB0000;
                font-weight: 400;
              }
            }
          }

          .price-box {
            @include fontSize(14px);
            margin-top: 16px;
            font-weight: 400;
            @include breakpoint($desktop) {
              @include fontSize(22px);
            }
            .old-price {
              @include breakpoint(max-width 480px) {
                margin-top: -16px;
              }
            }
          }

          div.view-kit-list {
            color: $black;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
            border-bottom: 3px solid $brand-primary;
            text-transform: uppercase;
            display: inline-block;
            cursor: pointer;

            @include breakpoint($desktop) {
              font-size: 18px;
              line-height: 30.8px;
            }
          }
        }

        .to-booking {
          width: 100%;
          display: block;
          margin-top: 30px;
          font-weight: $fontBolder;

          @include breakpoint($desktop) {
            margin-top: 22px;
            font-size: 16px;
          }
        }
      }

      &__locations {
        width: 100%;

        .content > .title {
          width: 100%;

          @include breakpoint(max-width $desktop) {
              display: none;
          }
        }

        .locations {

          @include breakpoint($desktop) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
          }

          &-entry {
            display: flex;
            align-items: center;
            margin-bottom: 28px;

            @include breakpoint($desktop) {
              width: 25%;
            }

            &:last-of-type {
              margin-bottom: 0;
            }

            img {
              height: 37px;
              width: 37px;
              margin-right: 12px;
            }

            p {
              @include fontSize(16px);
              line-height: 20px;
              color: $black;

              @include breakpoint($desktop) {
                margin-bottom: 32px;
              }
            }

            a {
              @include fontSize(16px);
              line-height: 18px;
              font-weight: $fontBolder;
              color: $black;
              text-transform: uppercase;
              border-bottom: 3px solid $brand-primary;
              display: inline-block;
              width: auto;
            }
          }
        }
      }
    }

    .product--tab.review {
      @include breakpoint ($tablet) {
        padding-bottom: 82px;
      }
    }

  }

  .modal-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: -3000px;
    bottom: 0;
    background: rgba(0,0,0,.6);
    height: 100%;
    width: 100%;
    z-index: 999;
    opacity: 0;
    transition: opacity .6s ease-in-out;
  }

  .modal-ingredients {
    position: fixed;
    left: -1000px;
    top: 0;
    background: $white;
    height: 100vh;
    z-index: 999;
    padding: 32px;
    width: 90%;
    transition: left .6s ease-in-out;
    color: $black;
    line-height: 22px;

    @include breakpoint($desktop) {
      width: 25%;
    }

    .modal-header {
      background: transparent;
  
      .action-close {
        height: $size-3xl;
        background: unset;
  
        &:hover {
          color: $black;
        }
        &::before {
          padding: 0;
          margin-top: -12px;
          display: block;
        }
      }
    }

    .title {
      color: $black;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 32px;
    }
  }

  .modal-wrapper {

    &.opened {

      .modal-overlay {
        top: 0;
        opacity: 1;
      }

      .modal-ingredients {
        left: 0;
      }
    }
  }
}