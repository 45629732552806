.checkout-onepage-success,
.checkout-klarna-success {

  .action.print {
    display: none;
  }

  .page-title-wrapper {
    @extend %constrainer;
    padding: 0 16px;

    .page-title {
      @include fancyHeading;
      margin-bottom: $size-2xl;
      margin-top: 0;
      text-align: center;

      @include breakpoint($desktop) {
        margin-top: $size-xs;
        margin-bottom: $size-7xl;
      }
    }
  }

  .column.main {
    @extend %constrainer;
    padding: 0 16px;

    @include breakpoint($desktop) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding: 0;
    }
  }

  .block-order-details-view {
    padding: 24px;

    @include breakpoint($desktop) {
      flex-basis: span(10);
    }

    .box-order-shipping-address {
      margin-bottom: 0;
    }

    h2 {
      @include fontSize(24px);
      line-height: $size-2xl;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: $brand-primary;
      font-weight: $fontReg;
      margin: 0 0 $size-m;
    }

    .block-content {
      border-top: 1px solid $grey-lighter;
      padding: $size-m;

      .box-title {
        line-height: 18px;
        color: $black;
      }
    }
  }

  .checkout-success-sidebar {

    @include breakpoint($desktop) {
      flex-basis: span(6);
    }
  }

  .checkout-success {
    width: 100%;

    &__header {
      background: $grey-lighter;
      text-align: center;
      padding: 34px;
    }

    &__heading {
      font-size: 30px;
      margin: 0 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint($desktop) {
        font-size: 40px;
        flex-direction: row;
        justify-content: center;
      }

      &::after {
        display: inline-block;
        width: 48px;
        height: 48px;
        content: "";
        background-image: url("../images/icons/icon-raising-hands.png");
        background-position: center;
        background-size: contain;
        order: -1;
        @include breakpoint($desktop) {
          order: 2;
          margin-left: 20px;
        }
      }
    }

    &__copy {
      font-size: 20px;
    }

    &__blocks {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
      margin-top: 20px;
      @include breakpoint($desktop) {
        flex-direction: row;
        margin-top: 80px;
      }
    }

    &__block {

      &--left {
        flex: 1 0 50%;
        h1 {
          @include fontSize(30px);
          @include breakpoint($desktop) {
            @include fontSize(40px);
          }
        }
        ul {
          padding-left: 18px;
          li {
            margin-bottom: 6px;
          }
        }
        .action {
          width: 100%;
          @include breakpoint($desktop) {
            width: unset;
          }
        }
      }

      &--right {
        flex: 1 0 50%;
        display: none;
        @include breakpoint($desktop) {
          display: block;
        }
      }

      .actions-toolbar {
        display: none;
      }
      .returns-policy {
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }

    &__details {
      h1 {
        @include fontSize(30px);
        @include breakpoint($desktop) {
          @include fontSize(40px);
        }
      }
      strong {
        font-weight: $fontBolder;
      }
    }
  }

  .checkout-success-ctas {
    padding: 0 $size-xl;

    .action.primary {
      background-color: $brand-senary;
      width: 100%;
      margin-bottom: $size-2xl;
      margin-top: 0;
    }

    .action.continue {
      @include fontSize(14px);
      line-height: 18px;
      color: $brand-primary;
      background-color: transparent;
      padding: 0;
      text-decoration: underline;
      width: 100%;
      text-align: center;
      font-weight: $fontReg;
    }
  }

  #klarna_checkout_success {
    display: none; // we might need to work this block into the design somehow
  }

  .block.crosssell {
    width: 100%; // for when there is only one product. Stops it collapsing
    .block-title {
      letter-spacing: 0 !important;
      padding-left: 0 !important;
    }
  }
}

@media (max-width: 776px) {

div#mmModalReferrerStage1 {
    top: 59% !important;
    max-width: 90% !important;
    left: 55% !important;
}
}
