// Default form styles
// Specific form styles to go in components folder

input,
textarea,
select, div.form-input {
  font-size: $size-s; // set to at least 16px to stop iOS zooming on focus
  border: 1px solid $black;
  margin-bottom: $size-m;
  width: 100%;
  padding: 8px $size-m;
  -webkit-appearance: none;
  background-color: $white;
  color: $black;
  font-weight: $fontReg;
  outline: none;
  border-radius: 0; // override iOS default radius
}

input {

  &::placeholder {
    font-size: $size-s;
    color: $black;
    font-weight: $fontReg;
  }
}

input[type="radio"] {
  width: $size-s;
  height: $size-s;
  border-radius: $size-s;
  border-bottom-width: 1px;
  outline: none;
  margin-top: 2px;
  
  &:checked {
    border-width: $size-3xs;
    border-color: $grey-dark;
  }
}

input[type="checkbox"] {
  width: $size-l;
  height: $size-l;
  outline: none;
  border-radius: 2px;
  border-bottom-width: 1px;
  margin-right: $size-2xs;
  
  &:checked {
    background: url(../images/icons/icon-check.svg) 50% 50% no-repeat;
    background-size: 9px;
  }

  & + .label {
    display: inline-block;
    text-transform: none;
    margin-bottom: 0;
    font-weight: $fontBook;
    vertical-align: top;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance: textfield;
}

form {
  border: 0;
  padding: $size-xl $size-xl 0;
  background-color: $white;
  width: 100%;
}

fieldset {
  border: 0;
  padding: 0;
  margin: 0;
}

label,
.label {
  @include fontSize(14px);
  line-height: $size-l;
  color: $black;
  margin-bottom: $size-2xs;
  display: block;
  font-weight: $fontBold;
}

.field.required,
.field._required {
  
  label,
  .label {
    
    &::after {
      content: "*";
    }
  }
}

.actions-toolbar {

  > .primary {
    margin-top: $size-xl;
  }
}

.g-recaptcha {

  .grecaptcha-badge {
    margin: auto; // 17px added to 15px to make standard distancing of 32px
  }
}
