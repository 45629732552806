.catalog-product-view,
.checkout-cart-index {

  .products-upsell,
  .products-crosssell {
    flex-basis: span(16);
  }

  .block.related {
    @include breakpoint($desktop) {
      margin: 64px -312px 0;
      padding: 120px 312px;
      border-top: 1px solid $grey-lighter;
    }

    .action.towishlist {
      display: none;
    }
  }

  .block.upsell {
    padding: 0;
    margin: $size-2xl 0 $size-4xl;

    @include breakpoint($desktop) {
      padding: 0;
      flex-basis: span(16);
    }
  }

  .block.crosssell {

    .action.towishlist {
      display: none;
    }
  }

  .block-title.title {
    text-transform: capitalize;
    margin-bottom: $size-2xl;
    padding-top: $size-xl;
    border-top: 1px solid $grey-lighter;

    @include breakpoint($desktop) {
      border-top: 0;
      padding: 0;
      margin-bottom: $size-6xl;
    }

    strong {
      @include mainHeading;
      text-transform: none;
      font-weight: $fontBolder;
      font-size: $size-xl;
      line-height: $size-3xl;

      @include breakpoint($desktop) {
        font-size: 38px;
        line-height: $size-6xl;
        margin-bottom: $size-2xl;
      }
    }
  }

  .products-upsell,
  .products-crosssell {
    padding: 0;

    .pro-price {

      .price-including-tax {
        display: none;
      }
    }
  }

  .product-items {
    justify-content: space-between;

    @include breakpoint($desktop) {
      justify-content: flex-start;
    }

    .product-item-name {
      padding: 0;
      margin-bottom: 6px;
      font-size: $size-l;
      line-height: $size-xl;
      color: $black;
      font-weight: $fontBolder;
      text-align: left;
      text-transform: capitalize;

      @include breakpoint($desktop) {
        font-size: 30px;
        line-height: 30px;
      }

      a {
        color: $black;
        text-transform: capitalize;
      }
    }

    .price-box {
      text-align: left;
      font-size: $size-s;
      color: $black;
      font-weight: $fontBolder;

      @include breakpoint($desktop) {
        font-size: 22px;
        line-height: 30px;
      }
    }

    .product-item {
      flex-basis: span(8);
      margin: 0;

      @include breakpoint($desktop) {
        flex-basis: span(4);
        max-width: 290px;
        margin-right: 28px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      &:focus {
        outline: 0;
      }
    }

    li:nth-child(n+3) {
      @include breakpoint(max-width $desktop) {
        display: none!important;
      }
    }

    .product-item-photo {
      outline: 0;
      margin-bottom: 0;
    }

    .product-item-details {
      margin-top: $size-xs;

      @include breakpoint($desktop) {
        margin-top: $size-xl;
      }
    }

    .product-item-actions {
      padding: 0;

      .tocart {
        width: 100%;
        font-weight: $fontBoldest;
      }
    }
  }
}

.checkout-cart-index {
  .block.crosssell {
    width: 100%;
    padding: $size-l;

    @include breakpoint($desktop) {
      margin-top: $size-2xl;
    }
  }
}

.catalog-product-view .block .block-title.title {
  padding: $size-xl $size-xl 0;
}

.catalog-product-view .block.upsell {
  display: none; // temporary
}
